import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import BusinessActions from "../../Redux/actions/business-actions";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import "../../Styles/pages/businessLink.scss";
import { useNavigate } from "react-router-dom";
import { OverlayPanel } from 'primereact/overlaypanel';
import PDF from "../../assets/images/Reveknew Business QR Poster - Preview.png";
import { Toast } from 'primereact/toast';
import { Buffer } from "buffer"; 

function ShareBusiness() {

      const navigate = useNavigate();
      const { t } = useTranslation("common");
      const overlayPanelPDF = useRef(null);
      const qrToast = useRef(null);

      const [state, setState] = useState({
            copied: false,
            qrCode: false,
            qrCodeImage: null
      });

      const [qrCodeUrl, setQrCodeUrl] = useState(" ")

      // Redux dispatch actions
      const dispatch = useDispatch()
      const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
      const getBusinessQr = useCallback((businessId) => { dispatch(BusinessActions.businessQrCodeRequest(businessId)) },
            [dispatch]);

      //redux state and dispatch to props
      const defMembership = useSelector((state) => state.defMembership.defMembership);
      const fetchingQrCode = useSelector((state) => state.businesses.fetchingQrCode);
      const qrCodeSuccess = useSelector((state) => state.businesses.qrCode);
      const qrCodeFailure = useSelector((state) => state.businesses.errorQrCode);

      const businessId = defMembership.business.id;

      useEffect(() => {
            if (!defMembership) {
                  getMembership();

            }
            if (defMembership) {
                  getBusinessQr(businessId)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [defMembership, getMembership, businessId]);

      const businessHeader = (
            <div className="businessHeader"> <h3 className="main_header">{t("business.share_business_link")}</h3></div>
      );

      //Toast function for successful download of QR code
      const showSuccess = useCallback(() => {
            qrToast.current.show({ severity: 'success', summary: t("business.success_download_summary"), detail: t("business.qr_success_message"), life: 3000 })
      }, [qrToast, t])

      //Toast function for error for download of QR code
      const showError = useCallback(() => {
            qrToast.current.show({ severity: 'error', summary: t("business.error_download_summary"), detail: t("business.qr_error_message"), life: 4000 });
      }, [qrToast, t])

      useEffect(() => {
            if (!fetchingQrCode && state.qrCode && qrCodeSuccess) {
                  showSuccess();
                  setState(state => { return { ...state, qrCode: false } })
            }
            if (!fetchingQrCode && qrCodeFailure && state.qrCode) {
                  showError()
                  setState(state => { return { ...state, qrCode: false } })
            }
            if (qrCodeSuccess) {
                  setState(state => { return { ...state, qrCodeImage: qrCodeSuccess } })
            }
      }, [state.qrCode, fetchingQrCode, qrCodeSuccess, showSuccess, qrCodeFailure, showError]);

      // Function to handle QR code download
      const handleDownload = () => {
            const blob = new Blob([state.qrCodeImage], { type: 'image/png' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
      };

      useEffect(() => {
            if (qrCodeSuccess instanceof ArrayBuffer) {
                  const buf = Buffer.from(qrCodeSuccess).toString("base64");
                  setQrCodeUrl(buf);
            }
      }, [qrCodeSuccess])

      return (
            <>
                  <Toast ref={qrToast} onRemove={(message) => {
                        if (message.severity === "success") {
                              getBusinessQr(businessId)
                        }
                  }}
                        position="top-center" />
                  <Card header={businessHeader} className="main_card_style">
                        <div className="card_div_style">
                              <span className="copy_business_text">{t("business.copy_and_share")}</span>
                              <p className="copy_business_text_hr" />

                              <div className="grid">
                                    <div className="col-5">
                                          <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span className="business_label">{t("business.business_URL")}</span>
                                                <span className='url'>{defMembership.business.shortUrl}</span>
                                          </div>

                                          <div style={{ marginTop: "4rem" }}>
                                                <span className="download_QR_heading">{t("business.download_QR_heading")}</span>
                                                <p className="download_QR_heading_hr" />

                                                <img src={`data:image/png;base64,${qrCodeUrl}`}
                                                      alt="png" className="qrCode" />

                                                <div style={{ marginLeft: "1rem" }}>
                                                      <Button
                                                            disabled={state.qrCode ? true : false}
                                                            label={state.qrCode ? t("business.downloaded") : t("business.download_QR_code")}
                                                            id="download_QR"
                                                            onClick={() => {
                                                                  getBusinessQr(businessId)
                                                                  setState((state) => ({ ...state, qrCode: true }));
                                                                  handleDownload()
                                                            }}
                                                            loading={fetchingQrCode}
                                                      />
                                                </div>
                                          </div>
                                    </div>
                                    <div className="col-7">
                                          <Button
                                                label={state.copied ? t("share.copied") : t("business.copy_link")}
                                                className="copy_business_btn"
                                                onClick={() => {
                                                      const textToCopy = `${defMembership.business.shortUrl}`;
                                                      navigator.clipboard.writeText(textToCopy);
                                                      setState((state) => ({ ...state, copied: true }));
                                                }}
                                          />
                                          <div style={{ margin: "3.3rem 0rem 0rem 4rem" }}>
                                                <span className="download_QR_heading">{t("business.export_poster")}</span>
                                                <p className="export_poster_hr" />

                                                <div className="grid div_right_corner">
                                                      <div className="grid business_card">
                                                            <div className="index_for_card" >1</div>
                                                            <div className="card_inner_texts">
                                                                  <span className="portrait_type">{t("business.portrait_type")}</span>
                                                                  <span className="portrait_type_size">{t("business.portrait_type_size")}</span>
                                                            </div>
                                                      </div>
                                                      <div className="main_div_linkTxts">
                                                            <span className="preview_txt"
                                                                  onClick={(e) => overlayPanelPDF.current.toggle(e)}  >
                                                                  {t("business.preview")}</span>
                                                            <OverlayPanel showCloseIcon className="subsOverlay" ref={overlayPanelPDF} >
                                                                  <img src={PDF} alt="" className="pdf_img" />
                                                            </OverlayPanel>
                                                            <span className="generate_pdf"
                                                                  onClick={() => {
                                                                        navigate("/business/qrCode/posterV1")
                                                                  }}>{t("business.generate_pdf")}
                                                            </span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </Card>
            </>
      )
}

export default ShareBusiness;