import React, { useEffect, useState, useCallback, useRef } from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CountryActions from "../../Redux/actions/country-actions";
import validator from "validator";
import { getLogin } from "../../Redux/reducers/account-reducer";
import SignupActions from "../../Redux/actions/signup-actions";
import { setAuthToken, removeAuthToken } from "../../Service/api";
import { useAuth0 } from "@auth0/auth0-react";
import { isValidPhoneNumber } from "react-phone-number-input";
import "../../Styles/pages/configure.scss";
import reveknew_logo from "../../assets/images/Reveknew_Logo_Colour_and_Black.png"
import Loading from "../../Components/Loading";
import "../../Styles/pages/sendMoney.scss";
import { Toast } from 'primereact/toast';
import { useNavigate} from 'react-router-dom';
import BusinessActions from '../../Redux/actions/business-actions';

const ConfigureBusiness = ({ location }) => {

	const navigate = useNavigate();
	const { t } = useTranslation("common");
	const toast = useRef(null);

	const [state, setState] = useState({
		activeTab: "register",
		activeCard: "",
		countriesData: [],
		country: null,
		code: null,
		regions: [],
		regionStates: [],
		regionState: null,
		purpose: "",
		name: "",
		city: "",
		phoneNo: "",
		email: "",
		addressLine1: "",
		addressLine2: "",
		nameError: false,
		regionStateError: false,
		addressLine1Error: false,
		emailError: false,
		phoneNoError: false,
		purposeError: false,
		cityError: false,
		industryTypeError: false,
		requesting: false,
		firstSubmit: false,
		newList: [],
		update: false,
		industryType: false,
		postcode: "",
		type: "ORGANISATION",
		industries: [],
	});

	const types = [
		{ label: t("business.title"), value: "ORGANISATION" },
		//might be in use later
		// { label: t("Individual"), value: "INDIVIDUAL" },
		// { label: t("Organisation"), value: "ORGANISATION" },
	];

	//Redux State from Store
	const countries = useSelector((state) => state.countries.countries);
	const signupBusiness = useSelector((state) => state.signup.business);
	const updatingSign = useSelector((state) => state.signup.updating);
	const errorUpdatingSign = useSelector((state) => state.signup.errorUpdating);
	const login = useSelector((state) => getLogin(state.account));
	const updatingSuccess = useSelector((state) => state.signup.business);
	const industries = useSelector((state) => state.businesses.industries);

	//Redux Dispatch Actions
	const dispatch = useDispatch();
	const getAllCountries = useCallback((options) => { dispatch(CountryActions.countryAllRequest(options)) }, [dispatch]);
	const configureBusiness = (business) => { dispatch(SignupActions.signupUpdateRequest(business)) };
	const fetchCountries = useCallback(() => { getAllCountries() }, [getAllCountries]);
	const getIndustries = useCallback((industry) => { dispatch(BusinessActions.businessIndustriesRequest(industry)) }, [dispatch])

	const { getAccessTokenSilently, logout } = useAuth0();
	const logoutFn = async () => {
		removeAuthToken()
		logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH_LOGOUTURL } })
		sessionStorage.setItem('crumbs', [])
	};

	const getUserMetadata = useCallback(async () => {
		try {
			await getAccessTokenSilently()
				.then((data) => {
					setAuthToken(data);
				})
				.then(() => {
					fetchCountries();
				})
		} catch (e) {
		}
	}, [getAccessTokenSilently, fetchCountries]);

	useEffect(() => {
		getUserMetadata()
	}, [getUserMetadata,]);

	//Handle side effects related to country list
	useEffect(() => {
		if (countries) {
			let countryList = [];
			countries.forEach((element) => {
				const countryObject = {};
				countryObject.label = element.name;
				countryObject.value = element.id;
				countryObject.code = element.phoneCode;
				countryObject.regions = element.regions;
				countryList.push(countryObject);
			});
			setState((prev) => { return { ...prev, countriesData: countryList }; });
		}
	}, [countries])

	//Handle side effects for regions
	useEffect(() => {
		if (state.regions) {
			let regions = []
			state.regions.forEach((ele) => {
				const regionObject = {}
				regionObject.label = ele.name;
				regionObject.value = ele.name;
				regions.push(regionObject)
			})
			setState((state) => ({ ...state, newList: regions }))
		}
	}, [state.regions])

	useEffect(() => {
		if (countries) {
			getIndustries("all")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries])

	//Handle side effects for submission of uploaded IDs for review
	useEffect(() => {
		if (!updatingSign && state.update && updatingSuccess) {
			showSuccess();
			setState(state => { return { ...state, update: false } })
		}
		if (!updatingSign && errorUpdatingSign && state.update) {
			setState(state => { return { ...state, update: false } })
			showError();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatingSign, errorUpdatingSign, state.update, updatingSuccess]);

	if (!countries) {
		return <Loading />
	}

	const {
		nameError, emailError, regionStateError, phoneNoError, addressLine1Error, cityError, industryTypeError, countryError, type, code,
		name, addressLine1, purpose, regionState, email, phoneNo, city, purposeError, firstSubmit, country, countriesData, industryType
	} = state;

	const showSuccess = () => {
		toast.current.show({ severity: 'success', summary: t("business.success_summary"), detail: t("business.success_message"), life: 3000 });
	};

	const showError = () => {
		toast.current.show({ severity: 'error', summary: t("business.error_summary"), detail: t("business.error_message"), life: 4000 });
	};

	const phone = `${code} ${phoneNo}`;
	const isValid = () => {
		const validEmail = validator.isEmail(email);
		setState((prev) => {
			return {
				...prev,
				nameError: !name || name.trim() === "",
				addressLine1Error: !addressLine1 || addressLine1.trim() === "",
				cityError: !city || city.trim() === "",
				purposeError: !purpose,
				typeError: !state.type,
				regionStateError: !regionState,
				emailError: !email || email.trim() === "" || !validEmail,
				phoneNoError:
					!phoneNo ||
					phoneNo.trim() === "" ||
					!isValidPhoneNumber(phone),
				countryError: !country,
				industryTypeError: !industryType
			};
		});

		if (!name || name.trim() === "" || !addressLine1 || addressLine1.trim() === "" || !city || city.trim() === "" ||
			!purpose || !regionState || !email || !type || email.trim() === "" || !phoneNo || phoneNo.trim() === "" ||
			!validEmail || !isValidPhoneNumber(phone) || !industryType
		) {
			return false;
		}
		return true;
	};

	const onSubmit = () => {
		setState((prev) => {
			return { ...prev, firstSubmit: true };
		});
		if (!isValid()) {
			return;
		}
		const business = {
			name: name,
			country: { id: state.country },
			addressLine1: addressLine1,
			addressLine2: state.addressLine2,
			city: city,
			type: types[0].value,
			email: email,
			phoneNo: phoneNo,
			purpose: purpose,
			login: login,
			regionState: regionState,
			industryId: industryType.id,
			postcode: state.postcode,
		};
		if (business) {
			setState((prev) => {
				return { ...prev, requesting: true, loading: true, update: true };
			});
			configureBusiness(business);
		}
	};
	if (
		!updatingSign &&
		!errorUpdatingSign &&
		!!signupBusiness &&
		state.loading &&
		state.requesting
	) {
	}

	const goToDashboard = () => {
		navigate("/home", {state:window.location.href})
	};

	return (
		<div className="wizard-body back" >
			<Toast ref={toast} onRemove={(message) => {
				if (message.severity === "success") {
					localStorage.setItem("onboardingDialog", true);
					goToDashboard();
				}
			}}
			></Toast>
			<div className="wizard-wrapper">
				<div className="wizard-header">
					<div className="wizard-logo">
						<img style={{ height: "30px", width: "200px" }} id="logo" src={reveknew_logo} alt="babylon-layout" />
					</div>
				</div>
				<div className="wizard-content">
					<div className="wizard-card" style={{ width: "70%" }}>
						<div className={classNames("wizard-card-content register active-content main-business-card")}>
							<div className="wizard-card-header">
								<div className="wizard-card-header-banner">
									<div className='business-profile-content'>
										<span className="create_business_account">{t("business.create_business")}</span>
									</div>
								</div>
								{<div className="wizard-forms-wrapper p-grid p-nogutter">
									<div className="business_profile_info">
										<span className="business_header_text">{t("business.create")}</span>
										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="name"
													className="form-label"
													style={{ color: nameError ? "red" : "#000000" }}>
													{nameError ? t("business.error_name_required")
														: state.type === "ORGANISATION" ? t("business.organisation_name") : t("business.organisation_name")}
													<span style={{ color: "red" }}>*</span>
												</label>
												<InputText
													id="business_name"
													className="p-error business_inputs"
													placeholder={t("business.name_placeholder")}
													onChange={({ target }) => {
														if (firstSubmit) {
															isValid();
														}
														setState((prev) => { return { ...prev, name: target.value } });
													}}
													maxLength={50}
												/>
												<div className="doc_tag_container"> <span className="doc_tag">  {t("business.provide_id_text1")} </span>
													<span className="doc_tag_bold"> {t("business.provide_id_text2")} </span>
													<span className="doc_tag"> {t("business.provide_id_text3")} </span>
												</div>
											</div>
										</div>
										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms back">
												<label htmlFor="type"
													className="form-label back"
													style={{ color: "#000000" }}>
													{t("business.merchant_type")}
												</label>
												<InputText
													disabled
													id="merchant_type"
													value={types.label}
													placeholder={t("business.title")}
													className="drop_down business_inputs"
													onChange={(event) => { setState((prev) => { return { ...prev, type: event.value } }) }}
												/>
											</div>
											{industries && <div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="name"
													className="form-label"
													style={{ color: industryTypeError ? "red" : "#000000" }}>{t("business.industry_type")}
													<span style={{ color: "red" }}>*</span>
												</label>

												<Dropdown
													id="business_type"
													options={[...industries].sort((a, b) => a.nameEn?.localeCompare(b.nameEn))}
													optionLabel="nameEn"
													value={state.industryType}
													placeholder={t("business.select_industry")}
													required
													className="drop_down business_inputs"
													onChange={(event) => { setState((prev) => { return { ...prev, industryType: event.value } }) }}
												/>

											</div>}
										</div>
										<hr className="first_hr_business" />

										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="country"
													className="form-label"
													style={{ color: countryError ? "red" : "#000000" }}>{t("business.country")}
													<span style={{ color: "red" }}>*</span>
												</label>
												<Dropdown
													id="country_business"
													name="country"
													options={countriesData.sort((a, b) => (a.label < b.label ? -1 : 1))}
													value={country}
													placeholder={t("business.choose_country")}
													required
													onChange={(event) =>
														setState((prev) => {
															let code;
															let regions;
															countriesData.map((ele) => {
																if (ele.value === event.value) {
																	code = ele.code;
																	regions = ele.regions
																}
																return null;
															});
															return { ...prev, country: event.value, code, regions, };
														})
													}
												/>
											</div>
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="business_address1-required"
													className="form-label"
													style={{ color: addressLine1Error ? "red" : "#000000" }}>
													{addressLine1Error ? t("business.error_address1_required") : t("business.address_1")}<span style={{ color: "red" }}>*</span>
												</label>
												<InputText
													id="business_address1-required"
													value={addressLine1}
													placeholder={t("business.address1")}
													onChange={({ target }) => {
														if (firstSubmit) {
															isValid();
														}
														setState((prev) => { return { ...prev, addressLine1: target.value } });
													}}
												/>
											</div>
										</div>
										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="business_address2-required"
													className="form-label"
													style={{ color: "#000000" }}
												>{t("business.address_2")}
												</label>
												<InputText
													id="business_address2-required"
													className="business_inputs"
													placeholder={t("business.address2")}
													value={state.addressLine2}
													onChange={({ target }) =>
														setState((prev) => { return { ...prev, addressLine2: target.value, } })}
												/>
											</div>

											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="postcode"
													className="form-label"
													style={{ color: "#000000" }}
												>{t("business.postcode")}
												</label>
												<InputText
													id="business_postcode"
													placeholder={t("business.postcode_placeholder")}
													value={state.postcode}
													onChange={({ target }) =>
														setState((prev) => { return { ...prev, postcode: target.value, } })}
												/>
											</div>
										</div>
										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="regionState"
													className="form-label"
													style={{ color: regionStateError ? "red" : "#000000" }}>
													{t("business.choose_region")}<span style={{ color: "red" }}>*</span>
												</label>
												<Dropdown
													id="regionState"
													options={[...state.newList].sort((a, b) => (a.label < b.label ? -1 : 1))}
													value={regionState}
													placeholder={t("business.region_placeholder")}
													required
													className="drop_down business_inputs"
													onChange={(event) =>
														setState((prev) => { return { ...prev, regionState: event.value } })}
												/>
											</div>
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms">
												<label
													htmlFor="business_city-required"
													className="form-label"
													style={{ color: cityError ? "red" : "#000000" }}>
													{cityError ? t("business.error_city_required") : t("business.city")}<span style={{ color: "red" }}>*</span>
												</label>
												<InputText
													id="business_city"
													value={city}
													placeholder={t("business.city_placeholder")}
													onChange={({ target }) => {
														if (firstSubmit) {
															isValid();
														}
														setState((prev) => { return { ...prev, city: target.value } });
													}}
												/>
											</div>
										</div>
										<div className="grid">
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms ">
												<label
													htmlFor="business_phoneNo-required"
													className="form-label"
													style={{ color: phoneNoError ? "red" : "#000000" }}>
													{phoneNoError ? <span className="error_phone_required">{t("business.error_phone_required")}</span> :
														<span className="phone_required"> {t("business.support_number")}</span>}
													<span style={{ color: "red" }}>*</span>
												</label>
												<div className="phoneNumber_div">
													{state.country && <span className="country_code">{state.code}</span>}
													<InputText
														id="business_phoneNo"
														value={phoneNo}
														placeholder={t("business.phone_placeholder")}
														disabled={!country}
														maxLength={10}
														onChange={({ target }) => {
															if (firstSubmit) {
																isValid();
															}
															setState((prev) => { return { ...prev, phoneNo: target.value } })
														}}
													/>
												</div>
												<div className="doc_tag_container">
													<span className="doc_tag_bold">{t("business.phone_tag_text1")}</span>
													<span className="doc_tag">{t("business.phone_tag_text2")}</span>
													<span className="doc_tag_bold">{t("business.phone_tag_text3")}</span>
												</div>
											</div>
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" >
												<label
													htmlFor="email"
													className="form-label"
													style={{ color: emailError ? "red" : "#000000", marginTop: "2rem" }}>
													{emailError ? !email || email.trim() === ""
														? t("business.error_email_required") : t("business.error_email_invalid") : t("business.email_label")}<span style={{ color: "red" }}>*</span>
												</label>
												<InputText
													id="business_profile_email"
													placeholder={t("business.email")}
													className={` p-error ${emailError ? "p-error" : ""}`}
													onChange={({ target }) => {
														if (firstSubmit) {
															isValid();
														}
														setState((prev) => { return { ...prev, email: target.value } });
													}}
													maxLength={50}
												/>
												<div className="doc_tag_container">
													<span className="doc_tag_bold">{t("business.email_tag_text1")}</span>
													<span className="doc_tag">{t("business.email_tag_text2")}</span>
													<span className="doc_tag_bold">{t("business.email_tag_text3")}</span>
												</div>
											</div>

											<hr className="business_last_hr" />
											<div clasname="grid">
												<div className="pcol-12 sm:col-12 md:col-12 lg:col-6 wizard-forms" style={{ width: "auto", marginTop: "0.1rem" }}>
													<span style={{ color: purposeError ? "red" : "#000000", fontWeight: "bold", marginBottom: "1rem" }}>
														{t("business.choose_purpose")}<span style={{ color: "red" }}>*</span>
													</span>
													<div style={{ display: "flex", flexDirection: "row" }}>
														<div className="p-col-12 " style={{ marginRight: "50px" }}>
															<RadioButton
																value="SERVICES"
																inputId="rb1"
																onChange={(event) => {
																	if (firstSubmit) {
																		isValid();
																	}
																	setState((prev) => { return { ...prev, purpose: event.value } });
																}}
																checked={purpose === "SERVICES"}
															/>
															<label
																htmlFor="rb1"
																className="p-radiobutton-label"
																style={{ color: "black" }}>
																{t("business.purpose_SERVICES")}
															</label>
														</div>
														<br></br>
														<div className="p-col-12 " style={{}}>
															<RadioButton
																value="DONATION"
																inputId="rb2"
																onChange={(event) => {
																	if (firstSubmit) {
																		isValid();
																	}
																	setState((prev) => { return { ...prev, purpose: event.value } });
																}}
																checked={purpose === "DONATION"}
															/>
															<label
																htmlFor="rb2"
																className="p-radiobutton-label"
																style={{ color: "black" }}>{t("business.purpose_DONATION")}
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="configure_buttons">
										<div style={{ width: "6.4rem" }}>
											<Button
												className="continue-button"
												label={t("Logout")}
												style={{ backgroundColor: "#D0021B" }}
												onClick={() => { logoutFn() }}
											/>
										</div>
										<div>
											<Button
												className="p-button-info create_business_btn"
												label={t("business.continue_business_button")}
												onClick={() => { onSubmit() }}
												loading={updatingSign}
											/>
										</div>
									</div>
								</div>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};
export default ConfigureBusiness;