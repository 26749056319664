import React, { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthToken } from "../Service/api"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountActions from "../Redux/actions/account-actions";
import DefMembershipActions from "../Redux/actions/defMembership-actions";
import ProfileActions from "../Redux/actions/profile-actions";
import Loading from "../Components/Loading";

let calledAccount = false,
    calledDef = false;

const ImplicitCallback = () => {
    const [state, setState] = useState({
        loading: true,
        isUserAuthenticated: false
    });
    const [emailStatus, setEmailStatus] = useState(false)
    const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    //Redux State from Store
    const account = useSelector((state) => state.account.account);
    //const errorAccount = useSelector((state) => state.account.error)
    const fetchingAccount = useSelector((state) => state.account.fetching);
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const errorDefMembership = useSelector((state) => state.defMembership.error);
    const fetchingDefMembership = useSelector((state) => state.defMembership.fetching);
    const profile = useSelector((state) => state.profile.profile);
    const fetchingProfile = useSelector((state) => state.profile.fetching);

    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getAccount = useCallback(() => { dispatch(AccountActions.accountRequest()); }, [dispatch]);
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const getProfile = useCallback(() => dispatch(ProfileActions.profileRequest()), [dispatch]);

    const getUserMetadata = useCallback(async () => {
        try {
            await getAccessTokenSilently()
                .then((data) => {
                    setAuthToken(data);
                    console.log('token');
                })
                .then(() => {
                    getMembership();
                    calledDef = true;
                    getAccount();
                    calledAccount = true;
                })
                .then(() => {
                    setState(state => { return { ...state, loading: false, isUserAuthenticated: true } });
                })
        } catch (e) {
            console.log('login error', e.message);
        }


    }, [getAccessTokenSilently, getAccount, getMembership]);

    useEffect(() => {
        getProfile()
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (isLoading ) {
            setState(state => { return { ...state, loading: true } })
        }
        else {
            getUserMetadata();
            console.log('metaData');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isLoading, user]);

    useEffect(() => {
        if(profile?.emailVerified){
            setEmailStatus(true)
        }
    },[profile, emailStatus])

    if (!state.loading) {
        if (state.isUserAuthenticated) {
            //if account data is in and def data is called and is done fetching the data run the redirect
            if (account && calledAccount && calledDef && !fetchingDefMembership && !fetchingAccount && !fetchingProfile) {
                if(!profile?.emailVerified){
                    navigate('/verification', { replace: true });
                }
               else if (defMembership && !errorDefMembership) {
                    console.log("HAS DEFAULT MEMBERSHIP");
                    navigate('/home', { replace: true });
                } else if (!defMembership && errorDefMembership.status === 404) {
                    console.log("HAS NO DEFAULT MEMBERSHIP");
                    navigate('/configure', { replace: true });
                } else if (!defMembership && errorDefMembership) {
                    navigate('/denied', { replace: true });
                }
            }
        } else {
            navigate('/', { replace: true });
        }
    }

    return <div style={{height: "100vh"}}><Loading/></div>
}

export default ImplicitCallback;