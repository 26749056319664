import React from 'react';
import { Chart } from 'primereact/chart';
import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';


const DashboardCharts = (props) => {
    const { t } = useTranslation('common');

    const tooltipDescriptionsBtm = [
        t("dashboard.daily_charts"),
        t("dashboard.weekly_charts"),
        t("dashboard.3_months_charts"),
        t("dashboard.6_months_charts"),
        t("dashboard.12_months_charts")
    ];

    const renderChart = (chartName, chartData, chartAmount, chartCurrency, tooltipTitle, tooltipDescription, tooltipTargetId) => {
        const activeIndex = props.activeIndex === 3 || props.activeIndex === 4 || props.activeIndex === 5;
        let chartType;
        if (activeIndex && props.monthlyChart === true) {
            chartType = 'bar';
        } else {
            chartType = 'line';
        }

        return (
            <div className="chart card">
                {!props.loadingChart && (
                    <div className="chart-info">
                        <div className='dashboard-charts'>
                            <div>
                                <span className="centerText">{chartName}</span>
                            </div>
                            <div>
                                <Button
                                    id={tooltipTargetId}
                                    type="text"
                                    style={{
                                        fontSize: '18px',
                                        color: 'black',
                                        border: 'none',
                                        background: 'none',
                                    }}
                                    icon="pi pi-question-circle"
                                    className={`p-button-rounded cardSectionsButton`}
                                />
                            </div>
                        </div>
                        <span id="chart-amount">
                            {chartCurrency}  {chartAmount} <span className="chart-duration">{props.duration}</span>
                        </span>
                        <Tooltip position="bottom" target={`#${tooltipTargetId}`} className="tooltip">
                            <div>
                                <h5 className="title">{tooltipTitle}</h5>
                                <span className="desc">{tooltipDescription}</span>
                                <hr className="hr" />
                                <span className="desc">{tooltipDescriptionsBtm[props.activeIndex - 1]}</span>
                            </div>
                        </Tooltip>
                    </div>
                )}
                <div>{props.loadingChart ? <ProgressSpinner style={{ display: 'flex', justifyContent: 'center' }} /> : <Chart className="p-col-6 p-col-6" type={chartType} data={chartData} />}</div>
            </div>
        );
    };

    const numFor = Intl.NumberFormat("en-US")

    const collectedAmount = numFor.format(props.paymentScheduleSummaryObject.paidSummary?.amount)
    const missedAmount = numFor.format(props.paymentScheduleSummaryObject.missedSummary?.amount)
    const upcomingAmount = numFor.format(props.actualPendingAmount)

    return (
        <div className="grid p-fluid">
            <div className="col-12 lg:col-6">
                {renderChart(
                    t('dashboard.collected_payments'),
                    props.collectedPaymentsData,
                    collectedAmount,
                    props.paymentScheduleSummaryObject.paidSummary?.currency,
                    t("dashboard.collected_payments"),
                    t("dashboard.collected_text"),
                    'collected-payments-tooltip'
                )}
                {renderChart(
                    t('dashboard.missed_payments'),
                    props.missedPaymentsData,
                    missedAmount,
                    props.paymentScheduleSummaryObject.missedSummary?.currency,
                    t("dashboard.missed_payments"),
                    t("dashboard.missed_text"),
                    'missed-payments-tooltip'
                )}
                {renderChart(
                    t('dashboard.pending_payments'),
                    props.pendingPaymentsData,
                    upcomingAmount,
                    props.paymentScheduleSummaryObject.missedSummary?.currency,
                    t("dashboard.pending_payments"),
                    t("dashboard.pending_text"),
                    'pending-payments-tooltip'
                )}
            </div>
            <div className="col-12 lg:col-6">
                {renderChart(
                    t('dashboard.new_subscriptions'),
                    props.newSubscriptionsData,
                    props.totalNewSubscriptions,
                    "",
                    t("dashboard.new_subscriptions"),
                    t("dashboard.new_subscriptions_text"),
                    'new-subscriptions-tooltip'
                )}
                {renderChart(
                    t('dashboard.active_subscriptions'),
                    props.activeSubscriptionsData,
                    props.totalActiveSubscriptions,
                    "",
                    t("dashboard.active_subscriptions"),
                    t("dashboard.active_subscriptions_text"),
                    'active-subscriptions-tooltip'
                )}
                {renderChart(
                    t('dashboard.cancelled_subscriptions'),
                    props.cancelledSubscriptionsData,
                    props.totalCancelledSubscriptions,
                    "",
                    t("dashboard.cancelled_subscriptions"),
                    t("dashboard.cancelled_subscriptions_text"),
                    'cancelled-subscriptions-tooltip'
                )}
            </div>
        </div>
    );
};

export default DashboardCharts;
