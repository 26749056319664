import { Button } from "primereact/button";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import { Card } from "primereact/card";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import "../../Styles/pages/planCreate.scss";
import { Checkbox } from 'primereact/checkbox';
import PlanCreateForm from "./PlanCreateForm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PlanCreate = (props) => {
  const defMembership = useSelector((state) => state.defMembership.defMembership);
  const creating = useSelector((state) => state.plans.creating);
  const errorPlan = useSelector((state) => state.plans.errorCreating);
  const successPlan = useSelector((state) => state.plans.plan);
  const businessCustomers = useSelector((state) => state.businessCustomers.businessCustomers);
  const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive);

  const dispatch = useDispatch()
  const createPlanTier = useCallback((plan) => { dispatch(PlanActions.planCreateRequest(plan)) }, [dispatch]);
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch]);

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [flex, setFlex] = useState(false);
  const [created, setCreated] = useState(0);
  const [tiersCreated, setTiersCreated] = useState(1);
  const [saved, setSaved] = useState('');
  const [checked, setChecked] = useState(false);

  const [state, setState] = useState({
    documentsSelection: null,
    creatingPlans: false,
    status: "ACTIVE",
    planGroups: [],
    tierName: null,
    description: null,
    billingPeriod: null,
    deductions: null,
    plan: null,
    policy: "",
    cost: '',
    flex: false,
    failure: false,
    setup: "",
    feeCheck: false,
  });

  const period = [
    { Period: "Once", code: "ONCE" },
    { Period: "Daily", code: "DAILY" },
    { Period: "Daily + Saturday", code: "DAILY_WITH_SATURDAYS" },
    { Period: "Daily + Weekend", code: "DAILY_WITH_WEEKENDS" },
    { Period: "Weekly", code: "WEEKLY" },
    { Period: "Bi-weekly", code: "BIWEEKLY" },
    { Period: "Monthly", code: "MONTHLY" },
    { Period: "Quarterly", code: "QUARTERLY" },
    { Period: "Half Yearly", code: "HALFYEARLY" },
    { Period: "Yearly", code: "YEARLY" },
  ];

  const groups = planGroupsGraphedActive?.groups.map(item => item.group);
  const planGroupExist = location.state?.plan;
  const planGroup = location.state?.planGroup;
  const toastPlan = useRef(null);
  const toastBL = useRef(null);
  const planGroups = groups;
  const planGroupName = planGroups?.map((planGroup) => planGroup.name);
  const duplicate = props.duplicate;
  const tierExist = planGroup

  const policyPlan = [
    { Policy: t("plan.flexible_billing"), code: "FLEXIBLE", isFlexible: "true" },
    { Policy: t("plan.fixed_billing"), code: "TIER", isFlexible: "false" },
    { Policy: t("plan.subscription_billing"), code: "SUBSCRIPTION" },
  ];

  const enterprisePolicyPlan = [
    { Policy: t("plan.flexible_billing"), code: "FLEXIBLE", isFlexible: "true" },
    { Policy: t("plan.fixed_billing"), code: "TIER", isFlexible: "false" },
    { Policy: t("plan.subscription_billing"), code: "SUBSCRIPTION" },
    { Policy: t("plan.schedule_billing"), code: "SCHEDULE" }
  ];

  const fetchActive = () => {
    getActivePlanGroups(defMembership.business.id);
  };

  useEffect(() => {
    const saved = localStorage.getItem("created");
    setSaved(saved)
  }, [saved])

  useEffect(() => {
    setState({ ...state, creatingPlans: creating })
    if (state.creatingPlans && state.failure && errorPlan) {
      showErrorPlan()
      setState({
        ...state,
        failure: false,
      });
    }
    if (state.creatingPlans && state.failure && successPlan) {
      showSuccessPlan()
      setState({
        ...state,
        failure: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creating, errorPlan, saved]);
  useEffect(() => {
    if (duplicate) {
      formik.setFieldValue("name", duplicate.name)
      if (duplicate.description) formik.setFieldValue("description", duplicate.description)
      formik.setFieldValue("billingPeriod", period.find(p => p.code === duplicate.billingPeriod))
      formik.setFieldValue("policy", policyPlan.find(p => p.code === duplicate.policy))
      formik.setFieldValue("graceDays", duplicate.graceDays)
      formik.setFieldValue("cost", duplicate.cost)
      formik.setFieldValue("flex", duplicate.flex)
      formik.setFieldValue("deductions", duplicate.deductions)
      formik.setFieldValue("reminderDays", duplicate.reminderDays)
      formik.setFieldValue("setup", duplicate.setup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicate])

  //Toast when plan is successfully created//
  const showSuccessPlan = () => {
    toastPlan.current.show({
      severity: "success",
      summary: t("plan.save_success"),
      detail: "",
      life: 3000,
    });
  };

  //Toast when plan is not created successfully//
  const showErrorPlan = () => {
    toastPlan.current.show({
      severity: "error",
      summary: t("products.generic_error"),
      life: 3000,
    });
  };

  //Validation for form//
  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      description: "",
      billingPeriod: null,
      deductions: "",
      groupName: null,
      graceDays: 0,
      reminderDays: 0,
      policy: null,
      cost: "",
      planId: null,
      setup: "",
      feeCheck: false,
      flex: false
    },
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors.name = t("products.validate_error");
      }
      if (!planGroupExist && planGroups?.length > 1 && !data.planId) {
        errors.planId = t("products.validate_plan_error");
      }
      if (!data.cost && formik.values.policy?.isFlexible === "false") {
        errors.cost = t("products.validate_cost_error");
      }
      if (data.cost < 0 && formik.values.cost) {
        errors.cost = t("products.negative_value_error");
      }
      if (formik.values.cost && !data.cost) {
        errors.cost = t("products.validate_cost_error")
      }
      if (data.setup < 0 && formik.values.feeCheck) {
        errors.setup = t("products.negative_value_error");
      }
      if (formik.values.feeCheck && !data.setup) {
        errors.setup = t("products.setup_validate_error");
      }
      if (!data.billingPeriod) {
        errors.billingPeriod = t("products.validate_billing_error");
      }
      if (!data.policy) {
        errors.policy = t("products.validate_policy_error");
      }
      if (data.deductions < 0) {
        errors.deductions = t("products.negative_value_error");
      }
      if (data.reminderDays < 0) {
        errors.reminderDays = t("products.negative_value_error");
      }
      if (data.graceDays < 0) {
        errors.graceDays = t("products.negative_value_error");
      }
      return errors;
    },

    onSubmit: (data) => {
      const groupData = () => {
        let result;
        if (planGroupExist) {
          result = { business: planGroup.business, id: planGroup.id }
        }
        else if (!planGroupExist) {
          if (planGroups?.length <= 1) {
            result = { business: planGroups?.[0].business, id: planGroups?.[0].id }
          }
          else {
            result = { business: data.planId?.business, id: data.planId?.id }
          }
        }
        return result;
      }

      const planTierData = {
        id: data.id || null,
        name: data.name || null,
        description: data.description || null,
        cost: data.cost || null,
        billingPeriod: data.billingPeriod.code || null,
        business: groupData().business,
        deductions: data.deductions || null,
        groupId: groupData().id,
        graceDays: data.graceDays || null,
        reminderDays: data.reminderDays || null,
        policy: data.policy.code || null,
        flex: flex,
        setup: data.setup || null
      };
      setTiersCreated(tiersCreated + 1)
      setState({
        ...state,
        failure: true,
      });
      createPlanTier(planTierData);
      localStorage.setItem("planNum", JSON.stringify(planGroup?.id));
      localStorage.setItem("created", JSON.stringify(created));
      localStorage.setItem("tiersCreated", JSON.stringify(tiersCreated));
      fetchActive()
    },
  });

  useEffect(() => {
    if (formik.values?.policy?.Policy === "Flexible billing") {
      setFlex(true)
    } else setFlex(false)
    // eslint-disable-next-line
  }, [formik.values?.policy])

  useEffect(() => {
    if (formik?.values.policy) {
      formik.setFieldValue("cost", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values.policy]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <div className="plancreate_name_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[name]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidCost = (cost) =>
    !!(formik.touched[cost] && formik.errors[cost]);
  const getFormErrorMessageCost = (cost) => {
    return (
      isFormFieldValidCost(cost) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[cost]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidSetupFee = (setup) =>
    (formik.values.policy?.isFlexible === "true" || "false") && !!(formik.touched[setup] && formik.errors[setup]);
  const getFormErrorMessageSetupFee = (setup) => {
    return (
      isFormFieldValidSetupFee(setup) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[setup]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidBillling = (billingPeriod) =>
    !!(formik.touched[billingPeriod] && formik.errors[billingPeriod]);
  const getFormErrorMessageBilling = (billingPeriod) => {
    return (
      isFormFieldValidBillling(billingPeriod) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[billingPeriod]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidPolicy = (policy) =>
    !!(formik.touched[policy] && formik.errors[policy]);
  const getFormErrorMessagePolicy = (policy) => {
    return (
      isFormFieldValidPolicy(policy) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[policy]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidPlanId = (planId) =>
    !!(formik.touched[planId] && formik.errors[planId]);
  const getFormErrorMessagePlanId = (planId) => {
    return (
      isFormFieldValidPlanId(planId) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[planId]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidDeductions = (deductions) =>
    !!(formik.touched[deductions] && formik.errors[deductions]);
  const getFormErrorMessageDeductions = (deductions) => {
    return (
      isFormFieldValidDeductions(deductions) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[deductions]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidReminderDays = (reminderDays) =>
    !!(formik.touched[reminderDays] && formik.errors[reminderDays]);
  const getFormErrorMessageReminderDays = (reminderDays) => {
    return (
      isFormFieldValidReminderDays(reminderDays) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[reminderDays]}</span>
        </div>
      )
    );
  };
  const isFormFieldValidGraceDays = (graceDays) =>
    !!(formik.touched[graceDays] && formik.errors[graceDays]);
  const getFormErrorMessageGraceDays = (graceDays) => {
    return (
      isFormFieldValidGraceDays(graceDays) && (
        <div className="plancreate_error_messages">
          <i className="pi pi-times-circle circle_times_icon"></i>
          <span>{formik.errors[graceDays]}</span>
        </div>
      )
    );
  };
  const policyHelperText = () => {
    if (formik.values.policy?.code === "TIER" && formik.values.policy.isFlexible === "true") {
      return <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="flexible_first_hint"> {t("plan.flexible_first_hint")}</span>
        <span className="flexible_second_hint"> {t("plan.flexible_second_hint")}</span>
      </div>
    } else if (formik.values.policy?.code === "TIER" && formik.values.policy.isFlexible === "false") {
      return <span className="fixed_hint"> {t("plan.fixed_hint")}</span>
    } else if (formik.values.policy?.code === "SUBSCRIPTION") {
      return <div style={{ display: "flex", flexDirection: "column" }}> <span className="subscription_first_hint"> {t("plan.subscription_first_hint")}</span>
        <span className="subscription_second_hint"> {t("plan.subscription_second_hint")}</span>
      </div>
    } else if (formik.values.policy?.code === "SCHEDULE") {
      return <div style={{ display: "flex", flexDirection: "column" }}><span className="schedule_first_hint"> {t("plan.schedule_first_hint")}</span>
        <span className="schedule_second_hint"> {t("plan.schedule_second_hint")}</span>
      </div>
    }
  }

  const onChangeBillingPeriod = (event) => {
    formik.setFieldValue("billingPeriod", event.value);
    if (event.value.code === "ONCE") {
      formik.setFieldValue("deductions", 1);
    }
    else {
      formik.setFieldValue("deductions", '')
    }
  };

  const onChangePlanGroup = (e) => {
    formik.setFieldValue("planId", e.value)
  }
  const onChangeFeeCheck = (e) => {
    formik.setFieldValue("feeCheck", e.checked)
  }

  const disableDays = (formik.values.billingPeriod &&
    formik.values.billingPeriod?.code === "ONCE") || (formik.values.billingPeriod?.code === "DAILY") || (!formik.values.billingPeriod?.code);

  const header = (
    <div className="planHeader"> <h3 className="headerText">{t("plan.create_plan")}</h3></div>
  );

  return (
    <Card header={header} style={{ width: "100%", marginLeft: "0rem" }}>
      <Toast ref={toastBL}
        className="toastBL"
        position="bottom-left"
        onRemove={(message) => {
          if (message.severity === 'warn') {
            setCreated('')
          }
        }}
      />
      <Toast
        className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title"
        ref={toastPlan}
        onRemove={(message) => {
          if (message.severity === 'success' && checked) {
            formik.resetForm()
            setCreated(created + 1)
          } else if (message.severity === 'success' && !checked) {
            navigate("/products")
            formik.resetForm()
            setCreated(0)
          }
        }}
      />{" "}

      <PlanCreateForm {...{
        formik, period, policyPlan, enterprisePolicyPlan, getFormErrorMessage, getFormErrorMessageCost, getFormErrorMessageBilling,
        getFormErrorMessageDeductions, getFormErrorMessageReminderDays, getFormErrorMessageGraceDays, policyHelperText,
        getFormErrorMessagePolicy, getFormErrorMessagePlanId, onChangeFeeCheck, flex, onChangeBillingPeriod, disableDays, t,
        isFormFieldValid, isFormFieldValidCost, defMembership, businessCustomers, planGroupExist, planGroups, planGroupName, onChangePlanGroup,
        tierExist, isFormFieldValidSetupFee, getFormErrorMessageSetupFee, setFlex
      }} />

      <p className="second_hr" />
      <div className="footer">
        <div style={{ display: "flex", flexDirection: "row", marginLeft: "0.5rem", }}>
          <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
          <p className="checkbox_label" >{t("plan.checkbox_label")}</p>
        </div>

        <div style={{ marginTop: "0.2rem" }}>
          <i className="pi pi-check check-icon" />
          <span className="tiers_created">{created} {created <= "1" ? t("plan.plan_created") : t("plan.plans_created")} </span>
        </div>

        <Button
          onClick={() => {
            localStorage.removeItem('toastShown');
            formik.handleSubmit()
          }}
          type="submit"
          disabled={creating}
          loading={creating}
          label={t("plan.save_plan")}
          id="tier_save" />
      </div>
    </Card>
  );
};
export default PlanCreate;