import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";

function ProductCreate(props) {

  const { visiblePlan, onHide } = props
  const toast = useRef(null);
  const { t } = useTranslation("common");

  const defMembership = useSelector((state) => state.defMembership.defMembership)
  const creatingGroups = useSelector((state) => state.planGroups.creating)
  const plans = useSelector((state) => state.plans.plans)
  const createSuccess = useSelector((state) => state.planGroups.planGroup)
  const errorArchiving = useSelector((state) => state.planGroups.errorArchiving)
  const planGroupsGraphed = useSelector((state) => state.planGroups.planGroupsGraphed)

  const dispatch = useDispatch()
  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
  const createPlanGroup = useCallback((planGroup) => { dispatch(PlanGroupActions.planGroupCreateRequest(planGroup)) }, [dispatch])
  const archivePlanGroup = useCallback((planGroupId, username) => { dispatch(PlanGroupActions.planGroupArchiveRequest(planGroupId, username)) }, [dispatch])
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
  const getArchivedPlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedArchivedRequest(businessId)) }, [dispatch])

  const [state, setState] = useState({
    success: false,
    setDisplayBasic: false,
    visible: false,
  })

  const fetchActive = () => {
    getActivePlanGroups(defMembership.business.id);
  }

  useEffect(() => {
    if (!defMembership) {
      getMembership();
    }
  }, [
    defMembership,
    getMembership,
    archivePlanGroup,
    errorArchiving,
    state.archiving,
    planGroupsGraphed,
    getActivePlanGroups,
    getArchivedPlanGroups,
    state.status,
  ]);

  useEffect(() => {
    if (!creatingGroups && state.success && !createSuccess) {
      showError();
      setState((state) => {
        return {
          ...state,
          success: false,
        }
      });
    }

    if (visiblePlan) {
      setState((state) => { return { ...state, visible: visiblePlan } })
    }

    if (!creatingGroups && state.success && createSuccess) {
      showSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingGroups, state.creatingPlanGroups, state.success, createSuccess, visiblePlan])

  const showSuccess = useCallback(() => {
    try {
      toast.current.show({
        severity: "success",
        code: "planSave",
        summary: t("products.save_success"),
        detail: "",
        life: 4000,
      });
    } catch (error) {
    }
  }, [toast, t]);

  const showError = useCallback(() => {
    toast.current.show({
      severity: "error",
      summary: t("products.generic_error"),
      detail: errorArchiving?.title,
      life: 5000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorArchiving, plans, t])

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      description: "",
      exclusive: true,
    },
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors.name = t("products.validate_error");
      }
      return errors;
    },

    onSubmit: (data) => {
      const planData = {
        id: data.id || null,
        name: data.name || null,
        description: data.description || null,
        exclusive: data.exclusive,
        business: { id: defMembership.business.id },
        status: "ACTIVE",
      };
      createPlanGroup(planData);
      setState({
        ...state,
        success: true,
      });
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <Dialog
        header={t("products.create_group")}
        visible={visiblePlan}
        style={{ width: "350px" }}
        className='tier_dialog'
        id='tier_dialog'
        onHide={() => { onHide() }}
      >
        <Toast
          className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
          ref={toast}
          onRemove={(message) => {
            if (message.code === 'planSave') {
              onHide()
              fetchActive();
              setState({
                ...state,
                success: false,
              });
              formik.resetForm();
            }
          }}
        />

        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div style={{ padding: "1rem" }}>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoFocus
                  className={classNames({
                    "p-invalid": isFormFieldValid("name"),
                  })}
                  style={{ width: "100%" }}
                  maxLength={50}
                />
                <label
                  htmlFor="name"
                  className={classNames({
                    "p-error": isFormFieldValid("name"),
                  })}
                >
                  {t("common.name")}
                </label>
              </span>
              {getFormErrorMessage("name")}
            </div>
            <br />

            <div className="p-field p-mb-2" >
              <span className="p-float-label">
                <InputText
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  maxLength={100}
                />
                <label
                  htmlFor="description"
                  className={classNames({
                    "p-error": isFormFieldValid("name"),
                  })}
                >
                  {t("products.description")} <span>{t("products.optional")}</span>
                </label>
              </span>
            </div>
            <div style={{ marginTop: "2rem" }}>
              <hr style={{ borderTop: "2px solid #e0e0e0" }} />
              <div className="car" style={{ marginTop: "2rem" }}>
                <span className='labels' >{t("products.exclusive")}</span> &nbsp;
                <InputSwitch
                  id="exclusive"
                  className={formik.values.exclusive ? "toggle" : "not-toggled"}
                  name="exclusive"
                  value={formik.values.exclusive}
                  checked={formik.values.exclusive}
                  onChange={formik.handleChange}
                />
                {formik.values.exclusive ? (
                  <div className='labels' style={{ marginTop: "1rem" }}>{t("products.exclusivity_hint_start")} <span className="exclusivity_hint_on_middle">{t("products.exclusivity_hint_on_middle")} </span> {t("products.exclusivity_hint_end")} </div>
                ) : (
                  <div className='labels' style={{ marginTop: "1rem" }}>{t("products.exclusivity_hint_start")} <span className="exclusivity_hint_off_middle">{t("products.exclusivity_hint_off_middle")} </span>{t("products.exclusivity_hint_end")} </div>
                )}{" "}
                <br />
              </div>
            </div>
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="submit" style={{ width: "auto", backgroundColor: "#d0021b" }} disabled={creatingGroups} loading={creatingGroups} label={t("products.save_product")} className="p-mt-2" />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}
export default ProductCreate;