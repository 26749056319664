import React from 'react'
import RoleAccess from '../../Components/RoleAccess';
import "../../Styles/pages/product.scss"
import { Card } from "primereact/card";
import moment from "moment";
import { ProductsSummarySection, SummarySubscriptionSection } from './ProductSummaryInsights';

const PlanDetailCards = (props) => {
    const { t, state, defMembership, setNameChangeVisible, setVisible, setVisibleInvite,
        planShare, togglePriceChange, toggleMoveSubscriber, setState } = props

    const moneyTemplate = (amount = 0) => (
        <div className='flex'>
            <span className="count-curren">{defMembership.business.currency}</span>
            <span className="amt-fig">{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount?.toFixed(2))}</span>
        </div>)

    const createdDate = state.plan.createdDate ? moment(state.plan.createdDate).format("MMMM DD YYYY") : "";
    const lastModifiedDate = state.plan.lastModifiedDate ? moment(state.plan.lastModifiedDate).format("MMMM DD YYYY") : "";

    const formatText = (text = "") => {
        if (text.toUpperCase() === "BIWEEKLY") {
            return <>{t("product.period_BIWEEKLY")} </>;
        }
        return text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase();
    }

    const sideMenu = [
        {
            label: t('products.share_plan'),
            icon: "pi-share-alt",
            onclick: () => { planShare(); }
        }, {
            label: t('products.edit_plan_info'),
            icon: "pi-pencil",
            onclick: () => { setNameChangeVisible(state.plan) }
        }, {
            label: t('products.archive_plan'),
            icon: "pi-lock",
            onclick: () => { setVisible(true) }
        }, {
            label: t('products.change_plan_price'),
            icon: "pi-money-bill",
            onclick: () => { togglePriceChange(state.plan) },
            show: state.plan.policy === "TIER" && !state.plan.flex
        }
    ]
    const subsActions = [
        {
            label: t('product.migrate'),
            icon: "pi-user-minus",
            onclick: () => {
                toggleMoveSubscriber(); setState((state) => {
                    return { ...state, sourceTier: { data: state.plan } }
                })
            }
        }, {
            label: t('subscriber.invite_subscriber'),
            icon: "pi-user-plus",
            onclick: () => {
                setVisibleInvite(true)
            }, allRoles: true
        },
    ]

    const details = [
        {
            type: "payments-7",
            title: `${t("products.payments")}: ${t("products.last-7")}`,
            cardInfo: t("products.payments_duration", { duration: t("products.last-7") }),

            collectedSevenDay: t("products.collected"),
            collectedSevenDayValue: moneyTemplate(state.performance.sevenDayCollected),

            revenueSevenDay: props.t("products.revenue"),
            revenueSevenDayValue: moneyTemplate(state.performance.sevenDayRevenue),

            missedSevenDay: t("products.missed"),
            missedSevenDayValue: moneyTemplate(state.performance.sevenDayMissed),

            pendingSevenDay: t("products.pending"),
            pendingSevenDayValue: moneyTemplate(state.performance.sevenDayPending)
        },
        {
            type: "payments-30",
            title: `${t("products.payments")}: ${t("products.last-30")}`,
            cardInfo: t("products.payments_duration", { duration: t("products.last-30") }),

            collectedthirtyDay: t("products.collected"),
            collectedthirtyDayValue: moneyTemplate(state.performance.thirtyDayCollected),

            revenuethirtyDay: props.t("products.revenue"),
            revenuethirtyDayValue: moneyTemplate(state.performance.thirtyDayRevenue),

            missedthirtyDay: t("products.missed"),
            missedthirtyDayValue: moneyTemplate(state.performance.thirtyDayMissed),

            pendingthirtyDay: t("products.pending"),
            pendingthirtyDayValue: moneyTemplate(state.performance.thirtyDayPending),
        },
        {
            type: "lifetime",
            title: `${t("products.payments")}: ${t("products.lifetime")}`,
            cardInfo: t("products.payments_lifetime"),

            collectedLifetime: t("products.collected"),
            collectedLifetimeValue: moneyTemplate(state.performance.thirtyDayCollected),

            revenueLifetime: props.t("products.revenue"),
            revenueLifetimeValue: moneyTemplate(state.performance.thirtyDayRevenue),

            missedLifetime: t("products.missed"),
            missedLifetimeValue: moneyTemplate(state.performance.thirtyDayMissed),

            pendingLifetime: t("products.pending"),
            pendingLifetimeValue: moneyTemplate(state.performance.thirtyDayPending)
        }
    ]

    const subscriptionContent = [
        {
            title: t("new"),
            subDesc: t(state.performance.newSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.newSubscriptionCount,
            cardInfo: t("products.tooltip_subs", { sub: t("new"), plan: state.plan.name }),
            className: "summary-new",
        },
        {
            title: t("products.Paused"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Paused"), plan: state.plan.name }),
            subDesc: t(state.performance.pausedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.pausedSubscriptionCount,
            className: 'summary-paused'
        },
        {
            title: t("products.Cancelled"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Cancelled"), plan: state.plan.name }),
            subDesc: t(state.performance.cancelledSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.cancelledSubscriptionCount,
            className: 'summary-cancelled'
        },
        {
            title: t("products.ACTIVE"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.ACTIVE"), plan: state.plan.name }),
            subDesc: t(state.performance.activeSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.activeSubscriptionCount,
            className: 'summary-active'
        },
        {
            title: t("products.Completed"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Completed"), plan: state.plan.name }),
            subDesc: t(state.performance.completedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.completedSubscriptionCount,
            className: 'summary-completed'
        },
        {
            title: t("products.Aborted"),
            cardInfo: t("products.tooltip_subs", { sub: t("products.Aborted"), plan: state.plan.name }),
            subDesc: t(state.performance.abortedSubscriptionCount === 1 ? "products.sub" : "products.subs"),
            value: state.performance.abortedSubscriptionCount,
            className: 'summary-aborted'
        },
    ]

    return (
        <div>
            <div className="grid nested-grid">
                <div className='col 9'>
                    <Card className='col-12 plan-detail-card'>
                        <div className='grid nested-grid main-card'>
                            <section className='col-6'>
                                <div className='col-12'>
                                    <div className='flex '>
                                        <span className={`planDetailCard-badge status-${state.plan.group?.status === "ARCHIVED" ?
                                            state.plan.group.status?.toLowerCase() : state.plan.status?.toLowerCase()} status-design`}>
                                            {state.plan.group?.status === "ARCHIVED" ? state.plan.group?.status : state.plan.status}
                                        </span>
                                        <div className='archive-info text-center' >
                                            {createdDate !== lastModifiedDate && <p>
                                                <span className='date-label'>{t("product.updated_on")}{":"}</span> <span className="planModifiedDate">{" "}{lastModifiedDate}</span>
                                            </p>}
                                            {createdDate === lastModifiedDate && <p>
                                                <span className='date-label'>{t("product.created_on")}{":"}</span> <span className="planModifiedDate">{" "}{createdDate}</span>
                                            </p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <p className='planDetailCard-name '>{state.plan.name}</p>
                                    <p className='planDetailCard-dec desc-info '>{state.plan.description}</p>
                                    <p className='planDetailCard-group-name '>{state.plan.group?.name?.toUpperCase()}</p>
                                </div>
                            </section>
                            <section className='col-6'>
                                <div className='col-12 plan-type'>
                                    {(state.plan.policy === "SCHEDULE" || state.plan.policy === "SUBSCRIPTION" || state.plan.flex) ?
                                        <span className="planDetailCard-side-amount">{t("products.flexible_amount")}</span> : <span className="planDetailCard-side-amount">{t("products.fixed-billing")}</span>
                                    }
                                </div>
                                <div className='col-12'>
                                    <div className='grid'>
                                        <div className='col-6'>
                                            <p className="planDetailCard-side-info">
                                                <>{t("price")}: <span style={{ color: "#d0021b", fontWeight: "bold" }}>{defMembership.business.currency} {state.plan.cost ? state.plan.cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : " 0.00"}</span></>
                                            </p>
                                            <p className="planDetailCard-side-info">
                                                {<>{t("billing_period")}:<span>{state.plan.billingPeriod ? formatText(state.plan.billingPeriod) : "--"}</span>
                                                </>}
                                            </p>
                                            <p className="planDetailCard-side-info">
                                                <>{t("grace_period")} : <span>{state.plan.graceDays ? state.plan.graceDays : "--"}</span></>
                                                <span style={{ margin: "0 0 0 2px" }}>{t("days")} </span>
                                            </p>
                                        </div>
                                        <div className='col-6'>
                                            {state.plan.setup && <div className="setup_fee_div" style={{ marginTop: "0.5rem" }}>
                                                <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
                                                <span className="setup_fee_value">{defMembership.business.currency}{" "}{state.plan.setup?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                            </div>}
                                            <p className="planDetailCard-side-info">
                                                {<>{t("product.billing_cycles")}:<span>{state.plan?.deductions ? state.plan.deductions : "--"}</span></>}
                                            </p>
                                            <p className="planDetailCard-side-info">
                                                <>{t("product.reminderDaysLabel")} : <span>{state.plan.reminderDays ? state.plan.reminderDays : "--"}</span></>
                                                <span style={{ margin: "0 0 0 2px" }}>{t("days")} </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Card>
                    <p className='insights'>{t("products.summary-insights")}</p>
                    <div className='grid' id='plandet-subscriptions'>
                        <SummarySubscriptionSection summary={subscriptionContent} />
                    </div>
                    <div className='grid' id='plan-cards-insights'>
                        <ProductsSummarySection summary={details} />
                    </div>

                </div>

                <div className="col-3">
                    <RoleAccess roles={["OWNER", "ADMIN"]}>
                        {(state.plan.status !== "ARCHIVED" && state.plan.group?.status !== "ARCHIVED") &&
                            <div id="product-detail-side-menu">
                                <p className="action-titles">{t("plan.quick_action")}</p>
                                {sideMenu.map(({ label, icon, onclick, show = true }) => (
                                    <> {
                                        show && <div className="side-menu-content" >
                                            <span onClick={onclick}>
                                                <i className={"pi " + icon} />
                                                <span> {label}</span>
                                            </span>
                                        </div>
                                    }
                                    </>
                                ))}
                                <hr className="side-menu-hr" />
                                <p className="action-titles">{t("plan.subs_action")}</p>
                                {subsActions.map(({ label, icon, onclick, show = true }) => (<div>
                                    <> {
                                        show && <div className="side-menu-content">
                                            <span onClick={onclick}>
                                                <i className={"pi " + icon} />
                                                <span> {label}</span>
                                            </span>
                                        </div>
                                    }
                                    </>
                                </div>
                                ))}
                            </div>}
                    </RoleAccess>
                </div>
            </div >
        </div>
    )
}
export default PlanDetailCards;