/* eslint-disable jsx-a11y/anchor-is-valid */
//imports from libraries
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { Card } from 'primereact/card';
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
// import { Checkbox } from "primereact/checkbox";
import moment from "moment";
// imports from redux
import SubscriberActions from "../../../Redux/transition/subscriber/subscriber.reducer";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
//imports from components and styles
import { initialState } from "../Constant";
import "../../../Styles/pages/activities.scss";

const Activity = () => {

	// ...redux state to props
	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const dateSearchSubscribers = useSelector((state) => state.subscribers.dateSearchSubscribers);
	const fetching = useSelector((state) => state.subscribers.fetchingAll);
	const fetchingDateSearch = useSelector((state) => state.subscribers.fetchingDateSearch);
	const searchingAll = useSelector((state) => state.subscribers.searchingAll);
	const searchSubscribers = useSelector((state) => state.subscribers.filteredSubscribers);
	const filteringAll = useSelector((state) => state.subscribers.filteringAll);
	const countSearchSubscribers = useSelector((state) => state.subscribers.countSearchSubscribers);
	const countingSearchSubscribers = useSelector((state) => state.subscribers.countingSearchSubscribers);
	const countDateSearchSubscribers = useSelector((state) => state.subscribers.countDateSearchSubscribers);
	const countingDateSearchSubscribers = useSelector((state) => state.subscribers.countingDateSearchSubscribers);

	const dispatch = useDispatch()
	const getAllSubscribers = useCallback((businessId, options) => { dispatch(SubscriberActions.subscriberAllRequest(businessId, options)) }, [dispatch]);
	const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
	const filterSubscribers = useCallback((query, businessId, options) => { dispatch(SubscriberActions.subscriberFilterRequest(query, businessId, options)) }, [dispatch])
	const getCountSearchSubscribers = useCallback((query, businessId, options) => { dispatch(SubscriberActions.countSearchSubscribersRequest(query, businessId, options)) }, [dispatch]);
	const getDateSearchSubscribers = useCallback((query, businessId, startDate, endDate, options) => { dispatch(SubscriberActions.subscriberDateSearchRequest(query, businessId, startDate, endDate, options)) }, [dispatch])
	const getCountDateSearchSubscribers = useCallback((query, businessId, startDate, endDate, options) => { dispatch(SubscriberActions.countDateSearchSubscribersRequest(query, businessId, startDate, endDate, options)) }, [dispatch]);

	const { t } = useTranslation("common");
	const [state, setState] = useState({
		...initialState,
		loading: true,
		count: null,
		size: 15,
		page: 0,
		first: 0,
		sort: 'joinedOn,desc',
		status: null,
		startDate: null,
		endDate: "now",
		query: "*",
		submitInput: false,
		bulk: "",
		bulkSuccess: false
	});
	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const navigate = useNavigate();
	const toast = useRef(null);

	const routeChange = (pathname, state) => {
		navigate({
			pathname,
			state,
		});
	};

	const businessId = defMembership.business.id;
	const { subscribersBulkCanceling, subscribersBulkPausing, errorSubscribersBulkCancel, errorSubscribersBulkPause, } = useSelector((state) => ({ ...state.subscribers }))
	const subscribersBulkCancelRequest = useCallback((businessId, membershipId, ids) => { dispatch(SubscriberActions.subscribersBulkCancelRequest(businessId, membershipId, ids)) }, [dispatch])
	const subscribersBulkPauseRequest = useCallback((businessId, membershipId, ids) => { dispatch(SubscriberActions.subscribersBulkPauseRequest(businessId, membershipId, ids)) }, [dispatch])

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			const options = { page: state.page, size: state.size, sort: state.sort }
			const searchQuery = state.input !== null || state.input !== "" ? state.input : state.query;
			if (state.input && !state.startDate && !state.status) {
				filterSubscribers("=" + searchQuery, businessId, options);
				getCountSearchSubscribers("" + searchQuery, businessId,);

			} else if (state.input && !state.startDate && state.status) {
				filterSubscribers(`=${searchQuery}&status=${state.status}`, businessId, options);
				getCountSearchSubscribers(`${searchQuery}&status=${state.status}`, businessId,);

			} else if (!state.input && !state.startDate && state.status) {
				filterSubscribers(`=&status=${state.status}`, businessId, options)
				getCountSearchSubscribers(`&status=${state.status}`, businessId);

			} else if (!state.input && state.startDate && !state.status) {
				getDateSearchSubscribers(`?query`, businessId, state.startDate, state.endDate, options)
				getCountDateSearchSubscribers(`?query`, businessId, state.startDate, state.endDate)

			} else if (state.input && state.startDate && !state.status) {
				let querySearch = `?query=${state.input.trim()}`;
				getDateSearchSubscribers(querySearch, businessId, state.startDate, state.endDate, options)
				getCountDateSearchSubscribers(querySearch, businessId, state.startDate, state.endDate)

			} else if (!state.input && state.startDate && state.status) {
				let querySearch = `?query=${state.input.trim()}`;
				getDateSearchSubscribers(`${querySearch}&status=${state.status}`, businessId, state.startDate, state.endDate, options)
				getCountDateSearchSubscribers(`${querySearch}&status=${state.status}`, businessId, state.startDate, state.endDate)

			} else if (state.input && state.startDate && state.status) {
				let querySearch = `?query=${state.input.trim()}`;
				getDateSearchSubscribers(`${querySearch}&status=${state.status}`, businessId, state.startDate, state.endDate, options)
				getCountDateSearchSubscribers(`${querySearch}&status=${state.status}`, businessId, state.startDate, state.endDate)

			} else if (!state.input && !state.startDate && !state.status) {
				filterSubscribers("", businessId, options);
				getCountSearchSubscribers("", businessId, options);
			}
			setState((s) => ({ ...s, submitInput: false, isSubmit: false }))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [businessId, defMembership, filterSubscribers, getMembership, state.startDate, state.page, state.size, state.sort,
		state.status, state.submitInput, state.query, state.endDate, getCountSearchSubscribers,
		getDateSearchSubscribers, getCountDateSearchSubscribers, state.bulkSuccess, state.isSubmit]);

	useEffect(() => {
		const loading = !!fetchingDateSearch || !!fetching || !!searchingAll
		if (loading !== null) {
			setState((state) => { return { ...state, loading } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defMembership, dateSearchSubscribers, fetching, fetchingDateSearch, getAllSubscribers, searchingAll]);

	//the loadingTable variable here controls the spinner in the table
	const loadingTable = !defMembership || filteringAll || countingSearchSubscribers || countingDateSearchSubscribers;

	const showBulkSuccess = useCallback(() => {
		toast.current.show({ severity: 'success', code: 'bulkSuccess', summary: t("invitation.success_header"), detail: t("invitation.success_bulk_detail"), life: 3000 });
	}, [t])
	const showBulkError = (message) => {
		toast.current.show({ severity: 'warn', summary: t("subscriber.error_header"), detail: message, life: 3000 });
	}

	useEffect(() => {
		if (state.bulk && (!subscribersBulkCanceling && !subscribersBulkPausing)) {
			if ((errorSubscribersBulkCancel && state.bulk === "cancel") || (errorSubscribersBulkPause && state.bulk === "pause")) {
				toast.current.show({ severity: 'error', summary: t("subscriber.error_header"), detail: t("subscriber.bulk_error_message"), life: 3000 });

			} else {
				setSelectedCustomers([])
				showBulkSuccess()
			}
			setState((s) => ({ ...s, bulk: "", bulkSuccess: !s.bulkSuccess }))

		}
	}, [errorSubscribersBulkCancel, errorSubscribersBulkPause, showBulkSuccess, state.bulk, subscribersBulkCanceling, subscribersBulkPausing, t])


	const onChange = (e) => {
		e.preventDefault();
		setState({ ...state, input: e.target.value });
	};

	//search for name or phone number
	const onClickSearch = () => {
		setState({ ...state, submitInput: true })
	}

	// search for date
	const onDateChange = (e) => {
		setState({
			...state, startDate: e.value
		})
	}

	// search for status
	const onStatusSearch = (e) => {
		setState({ ...state, status: e.value })
	}

	const handleKeyPress = e => {
		if (e.keyCode === 13) {
			onClickSearch(e);
		}
	}

	//cancel button
	const onClickCancelBtn = () => {
		setState({ ...state, input: "", isSubmit: true })
	}

	let data = state.startDate ? dateSearchSubscribers : searchSubscribers;

	const formatRenewOnTime = (time) => {
		if (moment(time).isValid()) {
			return moment(time).format("DD MMM YYYYTHH:mm A");
		}
	};

	const formatDate = date => {
		return moment(date).format('DD MMM YYYY')
	}

	const nextBillingDateBody = (rowData, e) => {
		if (rowData) {
			const new_date = rowData[e.field] !== null ? formatRenewOnTime(rowData[e.field]) : "--"
			const split_date = rowData[e.field] !== null ? new_date.split("T") : "--"
			return <div>
				<p>{new_date !== "--" ? split_date[0] : "--"}</p>
				<p>{new_date !== "--" ? split_date[1] : "--"}</p>
			</div>
		}
	}

	const amountBodyTemplate = (subscriber) => {
		if (subscriber.planPolicy === "SCHEDULE") {
			return "--"
		} else if (subscriber.planPolicy === "SUBSCRIPTION") {
			return <div>
				{
					subscriber.amount !== null ?
						<span>{`${defMembership.business.country.currency} ${subscriber.amount?.toFixed(2)}`}</span> : "--"
				}
			</div>
		} else if (subscriber.planPolicy === "TIER" && subscriber.flex === false) {
			return <div>
				{
					subscriber.amount !== null ?
						<span>{`${defMembership.business.country.currency} ${subscriber.amount?.toFixed(2)}`}</span> : "--"
				}
			</div>
		} else if (subscriber.flex === true) {
			return t("subscriber.flex_true")
		} else if (subscriber.amount === null) {
			return '--'
		} else {
			return "--"
		}
	}

	const statusBodyTemplate = (rowData) => {
		if (rowData.status) {
			return <div style={{ height: "110px" }}>
				<span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content`}>{rowData.status}</span>
			</div>
		}
	}

	const subscriptionBodyTemplate = (rowData) => {
		if (rowData) {
			return <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
				<div className="subscriptionBodyTemplate">
					{
						rowData.planName ?
							<p className="detail-navigation" id="planName"
								onClick={() => {
									if (rowData.id)
										routeChange(`/subscribers/subscriber/subscription/${rowData.id}`, rowData);
								}}
							>
								{rowData.planName}
							</p> : null
					}
					{
						rowData.planPeriod ?
							<p className="plan-group-text" >
								{t(`product.${rowData.planPeriod.toLowerCase()}`)}</p> : null
					}
					{
						rowData.entCustomerName !== null ?
							<p className="ref-text" >
								{rowData.reference}</p>
							: null
					}
					{
						rowData.planName ?
							<p className="ref-text hov"
								onClick={() => {
									navigate(`/products/detail/${rowData.groupId}`)
								}}
							>
								{rowData.groupName}
							</p> : null
					}
				</div >
			</div >
		}
	}


	const customerBodyTemplate = (rowData) => {
		if (rowData) {
			return <div className="customerBodyTemplate">
				{
					rowData.entCustomerName ?
						<span id="entCustomerName">{rowData.entCustomerName !== null ? rowData.entCustomerName : "--"}</span> : null
				}
				{rowData.customerPhoneNo ?
					<span id="entCustomerNumber">{rowData.customerPhoneNo !== null ? rowData.customerPhoneNo : "--"}</span> : null
				}
				{
					rowData.entCustomerEmail ?
						<span id="entCustomerEmail">{rowData.entCustomerEmail !== null ? rowData.entCustomerEmail : "--"}</span> : null
				}
				{
					rowData.entCustomerNumber ?
						<p id="entCustomerEmail" style={{ marginTop: "10px", color: "#D32F2F" }}>{rowData.entCustomerNumber !== null ? rowData.entCustomerNumber : "--"}</p> : null
				}
			</div>
		}
	}

	const columns = [
		{ field: "customer", body: customerBodyTemplate, header: t("business_customers.info") },
		{ field: "joinedOn", body: formatDate, header: t("started_on"), sort: true },
		{ field: "nextBillingDate", body: nextBillingDateBody, header: t("renews_on"), sort: true },
	]

	const defaultCellStyles = { border: "0px", borderBottom: "1px solid #e0e0e0", padding: "auto" }
	const defaultHeaderStyles = {
		fontFamily: "Roboto",
		fontSize: "12px",
		color: "#666678",
		textTransform: "capitalize",
		border: 0, borderBottom: "1px solid #e0e0e0",
		padding: "1rem 0.5rem 1rem 0rem"
	}

	const count = state.startDate ? countDateSearchSubscribers : countSearchSubscribers

	const onPageChange = (e) => {
		if (e.pageCount <= 1) {
			return;
		} setState((state) => ({ ...state, page: e.page, first: e.first }));
	}
	const isSelected = selectedCustomers.length > 0

	const onBulk = (bulkRequest, type = "pause") => {
		let filtered = selectedCustomers.filter(({ status }) => {
			status = status?.toLowerCase();
			let isCancelled = type === "pause" ? status === "cancelled" || status === "paused" : status === "cancelled"
			if (isCancelled) {
				showBulkError(t("subscriber.bulk_warn_" + type));
			}
			return !isCancelled;
		}).map((selected) => selected.id);
		if (filtered.length > 0) {
			setState({ ...state, bulk: type });
			bulkRequest(businessId, defMembership.id, filtered);
			toast.current.show({ severity: 'info', code: 'bulkInfo', summary: t("subscriber.info_header"), detail: t("subscriber.bulk_info_message"), life: 3000 });
		}
	};

	return (
		<>
			<Card id="filters-card" className="action_bar_cards">
				<div className="p-grid p-fluid main-div">
					<div className="activity-actions-count">
						<span className="activity-actions-count-text" >
							{isSelected ? selectedCustomers.length : count > 0 ? count : 0}
						</span>
						<span className="activity-actions-count-number">
							{isSelected ? selectedCustomers.length === 1 ? t("subscriber.selected_title_singular") :
								t("subscriber.selected_title") : t("subscriber.title")}
						</span>
					</div>
					{isSelected ?
						(
							<div className="activity-actions-btns" >
								<Button disabled={state.bulk === "pause"} className="activity-actions-count-btn p-button-outlined p-button-info ml-1 mr-3" onClick={() => {
									onBulk(subscribersBulkPauseRequest, "pause")
								}} >{state.bulk === "pause" ? t("subscriber.loading") : t("subscriber.pause_btn")} </Button>
								<Button disabled={state.bulk === "cancel"} className="activity-actions-count-btn p-button-outlined p-button-danger mr-3" onClick={() => {
									onBulk(subscribersBulkCancelRequest, "cancel")
								}}>{state.bulk === "cancel" ? t("subscriber.loading") : t("subscriber.cancel_btn")}</Button>
								<Button label={t("subscriber.cancel_selection_btn")} icon="pi pi-times" onClick={() => setSelectedCustomers([])} className=" activity-actions-count-btn p-button-info p-button-text cancel_selection_btn" />
							</div>
						) :
						(<>
							<div className="p-col-12 p-md-3 input-div">
								<div className="p-inputgroup">
									<InputText
										placeholder={t("subscriber.search_by")}
										className="search_input"
										value={state.input}
										onInput={onChange}
										onKeyDown={handleKeyPress}
									/>
									{state.input && <Button
										onClick={onClickCancelBtn}
										icon="pi pi-times"
										className="cancel_button"
									/>}
									<Button
										icon="pi pi-search"
										className='search_button'
										onClick={onClickSearch}
									/>
								</div>
							</div>

							<div className="p-col-12 p-md-3 filters" >
								<div className="p-inputgroup">
									<Dropdown
										className="activity-dropdown"
										value={state.startDate}
										id="time"
										options={state.filterItems.map((item) => {
											item.label = t(item.label);
											return item;
										})}
										placeholder={t("subscriber.filter_by_time")}
										onChange={onDateChange}
									/>
								</div>
							</div>

							<div className="p-col-12 p-md-4 filters" >
								<div className="p-inputgroup">
									<Dropdown
										className="activity-dropdown"
										value={state.status}
										options={state.filterSubscribersItems}
										placeholder={t("saved_recipients.status")}
										onChange={onStatusSearch}
									/>
								</div>
							</div>
						</>
						)}
				</div >
			</Card>
			<Toast ref={toast} />
			<div>
				<DataTable
					id="data_table"
					value={data}
					className="p-datatable-responsive-table"
					responsiveLayout="stack" breakpoint="1050px"
					selection={selectedCustomers}
					onSelectionChange={e => setSelectedCustomers(e.value)}
					paginator={false}
					dataKey="id"
					loading={loadingTable}
				>
					<Column selectionMode="multiple"
						style={{ border: "0px", borderBottom: "1px solid #e0e0e0", padding: "0px", alignContent: "start", width: "45px" }}
						bodyClassName="activity-select"
						headerClassName="select-header"
					>
					</Column>
					<Column style={{ border: "0px", borderBottom: "1px solid #e0e0e0", padding: "0px", alignContent: "start", width: "10px" }}
						headerStyle={defaultHeaderStyles}
						field="status"
						body={statusBodyTemplate}
						header={t("subscriber.status")}
						sortable={true}
					>
					</Column>
					<Column style={{ border: "0px", borderBottom: "1px solid #e0e0e0", padding: "8px", alignContent: "start", width: "300px" }}
						headerClassName="subsBodyTemplate-header"
						field="subscriptions"
						body={subscriptionBodyTemplate}
						header={t("subscriber.info")}
					>
					</Column>
					<Column style={{ border: "0px", borderBottom: "1px solid #e0e0e0", padding: "8px", alignContent: "start", width: "156px" }}
						headerStyle={defaultHeaderStyles}
						field="cost"
						sortable={true}
						body={amountBodyTemplate}
						header={t("dashboard.amount")}
					>
					</Column>
					{columns.map(({ field, body, header, sort, style, className }, i) =>
						<Column className={className} key={i} field={field} body={body} header={header}
							alignHeader="center"
							style={{ ...defaultCellStyles, ...style }}
							headerStyle={defaultHeaderStyles} sortable={sort} />)}
				</DataTable>
				<Paginator rows={data?.length === 0 ? 0 : state.size} totalRecords={count}
					first={state.first} onPageChange={onPageChange} >
				</Paginator>
			</div >
		</>
	)
}
export default Activity;