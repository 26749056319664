import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Loading from "../../Components/Loading";
import { Avatar } from "primereact/avatar";
import ProfileActions from "../../Redux/actions/profile-actions";
import "../../Styles/pages/profile.scss"
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

let mounted = true;

const UserProfile = () => {
    const { t } = useTranslation("common");
    const toast = useRef(null);

    //Redux State from Store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const profile = useSelector((state) => state.profile.profile);
    const fetchingProfile = useSelector((state) => state.profile.fetching);
    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const updateProfile = (profile) => { dispatch(ProfileActions.profileUpdateRequest(profile)) }
    const getProfile = () => dispatch(ProfileActions.profileRequest());

    const errorUpdatingProfile = useSelector((state) => state.profile.errorUpdating)
    const updatingProfile = useSelector((state) => state.profile.updating)
    const updated = useSelector((state) => state.profile.updated)

    const [edit, setEdit] = useState(false)
    const [clicked, setClicked] = useState(false)
    const [state, setState] = useState({
        profile: null,
        defMembership: null,
        requesting: false,
    });

    const showSuccess = useCallback(() => {
        toast.current.show({ severity: 'success', summary: t("business.update_success_summary"), detail: t("business.update_success"), life: 1000 });
    }, [t])

    const showError = useCallback(() => {
        toast.current.show({ severity: 'error', summary: t("business.update_error_summary"), detail: t("business.update_error"), life: 3000 });
    }, [t])

    useEffect(() => {

        if (mounted) {
            getProfile();
        }

        if (profile) {
            setState((prev) => {
                return { ...prev, profile: profile };
            });
        }

        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            setState((prev) => {
                return { ...prev, defMembership: defMembership };
            });
        };

        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defMembership, getMembership, profile,]);

    useEffect(() => {
        if (!updatingProfile && state.requesting && updated) {
            showSuccess()
        }
        if (!updatingProfile && state.requesting && errorUpdatingProfile) {
            showError()
        }
    }, [errorUpdatingProfile, showError, showSuccess, state.requesting, updatingProfile, updated])



    const { firstName, imageUrl, lastName, email } = profile
        ? profile
        : defMembership.user
            ? defMembership.user
            : {};

    const { control, formState: { errors }, handleSubmit, reset
    } = useForm({
        defaultValues: {

            lastName: lastName,
            firstName: firstName,
            email: email

        }
    });

    if (!defMembership || !profile || fetchingProfile) {
        return <Loading />;
    }

    const onSubmit = (data) => {
        if (clicked) {
            const user = {
                role: defMembership.role,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                login: profile.login
            };
            updateProfile(user)
            setState((prev) => {
                return { ...prev, requesting: true, };
            });
        }
    }

    const handleCancel = () => {
        reset({
          lastName,
          firstName,
          email,
        });
        setEdit(false)
      };

    return (
        <>
            <Toast ref={toast} onRemove={(message) => {
                if (message.severity === "success") {
                    setEdit(false)
                    setClicked(false)
                    getProfile()
                }
            }} />
            <Card header="" id="main-card">
                <div className="surface-ground " >
                    <div className="surface-section px-6 py-5" >
                        <div className="font-medium text-3xl border-bottom-1 text-900 mb-3">{t('user_profile.profile')}</div>
                        <div className="flex align-items-start flex-column lg:flex-row lg:justify-content-between">

                            <div className="flex align-items-start flex-column md:flex-row">
                                {imageUrl ? (
                                    <Avatar
                                        className="p-mr-2 avatar"
                                        id="avatar"
                                        image={imageUrl}
                                        size="xlarge"
                                        shape="circle"
                                        imageAlt="profile pic"
                                    />
                                ) : (
                                    <Avatar
                                        label={firstName === null ? profile.firstName[0] : firstName[0]}
                                        className="p-mr-2 avatar"
                                        size="xlarge"
                                        shape="circle"
                                        id="avatar"
                                    />
                                )}
                                <div className="ml-3 mt-3 ">
                                    <span className="text-900 font-medium text-3xl display_name" id="display_name">{firstName} {lastName}</span>
                                    <div className="flex align-items-center flex-wrap text-sm">
                                        <div className="mr-5 mt-1">
                                            <span className="font-medium text-400" id="role">{t('user_profile.role')}</span>: <span className="font-medium text-400">{t(`user_profile.${defMembership.role}`)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames("wizard-card-content register active-content")} >

                        <form onSubmit={handleSubmit(onSubmit) }
                            className="wizard-forms-wrapper p-grid p-nogutter settings-edit-form">
                            <div className="card" style={{ marginBottom: "1rem", marginTop: "0" }}>
                                <div className="grid">

                                    <div className="col-12 sm:col-12 md:col-12 lg:col-7 wizard-forms name-text" >
                                        <label
                                            htmlFor="firstName"
                                            className={"label"}
                                            style={{ color: errors.firstName ? "red" : "#000000", fontSize: "15px" }}>
                                            {errors.firstName ? errors.firstName.message : t("common.first_name")}
                                            <span style={{ color: "red" }}>{edit && '*'}</span>
                                        </label>
                                        <Controller
                                            name={"firstName"}
                                            control={control}
                                            rules={{ required: t("user_profile.first_name_error") }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    disabled={!edit}
                                                    id="firstName-input"
                                                    className={classNames("form-control", "all-inputs", { "p-invalid": fieldState.invalid })}
                                                    style={{ height: "3rem" }}
                                                    maxLength={50}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="grid mt-2">

                                    <div className="col-12 sm:col-12 md:col-12 lg:col-7 wizard-forms name-text" >
                                        <label
                                            htmlFor="lastName"
                                            className={"label"}
                                            style={{ color: errors.lastName ? "red" : "#000000", fontSize: "15px" }}>
                                            {errors.lastName ? errors.lastName.message : t("common.last_name")}
                                            <span style={{ color: "red" }}>{edit && '*'}</span>
                                        </label>
                                        <Controller
                                            name={"lastName"}
                                            control={control}
                                            rules={{ required: t("user_profile.last_name_error") }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    disabled={!edit}
                                                    id="lastName-input"
                                                    className={classNames("form-control", "all-inputs", { "p-invalid": fieldState.invalid })}
                                                    style={{ height: "3rem" }}
                                                    maxLength={50}
                                                />
                                            )}
                                        />
                                    </div>

                                </div>
                                <div className="grid mt-2">

                                    <div className="col-12 sm:col-12 md:col-12 lg:col-7 wizard-forms name-text" >
                                        <label
                                            htmlFor="email"
                                            className={"label"}
                                            style={{ color: errors.email ? "red" : "#000000", fontSize: "15px" }}>
                                            {errors.email ? errors.email.message : t("user_profile.email")}
                                            <span style={{ color: "red" }}>{edit && '*'}</span>
                                        </label>
                                        <Controller
                                            name={"email"}
                                            control={control}
                                            rules={{
                                                required: t("user_profile.email_error"),
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: t("business.error_email_invalid")
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText
                                                    {...field}
                                                    disabled={!edit}
                                                    id="email-input"
                                                    className={classNames("form-control", "all-inputs", { "p-invalid": fieldState.invalid })}
                                                    style={{ height: "3rem" }}
                                                    maxLength={50}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>

                            </div>

                            {edit &&
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <Button
                                        style={{ backgroundColor: "#D0021B", width: "5rem", alignItems: "center" }}
                                        label={t("cancel")}
                                        onClick={handleCancel}
                                    />

                                    <Button
                                        type="submit"
                                        label={t("user_profile.form_update")}
                                        loading={updatingProfile}
                                        onClick={() => { setClicked(true) }}
                                    />
                                </div>}
                            {!edit && <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                                <Button
                                    type="button"
                                    label={t("user_profile.edit")}
                                    loading={updatingProfile}
                                    onClick={() => { setEdit(true) }}
                                />
                            </div>}
                        </form>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default UserProfile;
