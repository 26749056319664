/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//Redux imports
import PlanGroupActions from '../../../Redux/transition/plan-group/plan-group.reducer'
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import PayoutChannelActions from "../../../Redux/actions/payoutchannel-actions"
import BankActions from "../../../Redux/actions/bank-actions";
//Component import
import Loading from "../../../Components/Loading";
import { FavouriteSection, AddAccountDialog } from "../../../Components/sendMoney/FavouriteSection";
//Prime react import
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Dropdown } from "primereact/dropdown";
import { Card } from 'primereact/card';
import 'primeicons/primeicons.css';
import * as Yup from "yup";
import validator from 'validator';
//styles import
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";

function SavedRecipients() {

    // redux state from store 
    const toast = useRef(null);
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroups = useSelector((state) => state.planGroups.planGroups);
    const planGroupsCount = useSelector((state) => state.planGroups.count);
    const fetchingPlanGroupsCount = useSelector((state) => state.planGroups.fetchingPendingCount);
    const fetchingPlanGroups = useSelector((state) => state.planGroups.fetchingAll);
    const fetchingWallet = useSelector((state) => state.wallet.fetchingOne);
    const fetchingPendingCount = useSelector(state => state.settlements.fetchingStatusCount)
    const payoutChannelsSaved = useSelector((state) => state.payoutChannels.payoutChannelsSaved);
    const payoutChannelsFavourite = useSelector((state) => state.payoutChannels.payoutChannelsFavourite);
    const errorSettingFavourite = useSelector((state) => state.payoutChannels.errorSettingFavourite);
    const fetchingFavourite = useSelector((state) => state.payoutChannels.fetchingFavourite);
    const settingFavourite = useSelector((state) => state.payoutChannels.settingFavourite);
    const settingUnfavourite = useSelector((state) => state.payoutChannels.settingUnfavourite);
    const errorSettingUnfavourite = useSelector((state) => state.payoutChannels.errorSettingUnfavourite);
    const banks = useSelector((state) => state.banks.banks);
    const filteredChannels = useSelector((state) => state.banks.filteredBanks);
    const fetchingFilteredBanks = useSelector((state) => state.banks.fetchingFilteredBanks);

    const navigate = useNavigate();

    // redux dispatch actions
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const getPlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupAllRequest(businessId)); }, [dispatch]);
    const getPlanGroupsCount = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupAllCountRequest(businessId)); }, [dispatch]);
    const getWallet = useCallback((businessId) => { dispatch(WalletActions.walletRequest(businessId)) }, [dispatch]);
    const getSettlementsStatusCount = useCallback((businessId) => { dispatch(SettlementActions.settlementStatusCountRequest(businessId, "PENDING")); }, [dispatch]);
    const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
    const getFavouriteChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelFavouriteRequest(businessId, options)), [dispatch]);
    const setFavouritePayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSetFavouriteRequest(businessId, options)), [dispatch]);
    const payoutChannelSetUnfavourite = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSetUnfavouriteRequest(businessId, options)), [dispatch]);
    const getAllBanks = useCallback((countryId) => dispatch(BankActions.bankAllRequest(countryId)), [dispatch]);
    const filterChannelsByBanks = useCallback((businessId, query, options) => dispatch(BankActions.bankFilterRequest(businessId, query, options)), [dispatch])

    const [state, setState] = useState({
        planGroups: null,
        planGroupsGraphed: null,
        planGroupsCount: 0,
        size: 10,
        page: 0,
        first: 0,
        sort: 'id,desc',
        isNewChannel: false,
        newChannel: {},
        payoutChannels: [],
        favouritePayoutChannels: [],
        newFav: null,
        addNewFavVisible: false,
        callFav: false,
        setFav: false,
        setUnfav: false,
        fav: null,
        data: null,
        isFiltered: false,
        systemBanks: [],
        filteredPayoutChannels: [],
        selectedBank: null,
        favFiltered: false,
        unFavFiltered: false,
        query: null
    })

    const fetchAllplanGroups = useCallback(page => {
        getPlanGroups(defMembership.business.id, { page, size: state.size, sort: state.sort });
    }, [getPlanGroups, state.size, state.sort])

    useEffect(() => {
        if (!defMembership) {
            getMembership()
        }
        if (defMembership) {
            fetchAllplanGroups(0);
            getPlanGroupsCount(defMembership.business.id);
            getWallet(defMembership.business.id);
            getSettlementsStatusCount(defMembership.business.id);
            getPlanGroups(defMembership.business.id);
            getAllBanks(defMembership.business.country.id);
            getSavedPayoutChannels(defMembership.business.id);
            getFavouriteChannels(defMembership.business.id);
        }
        if (!planGroups && defMembership) {
            getPlanGroups(defMembership.business.id)
        }
    }, [])

    useEffect(() => {
        if (planGroups) {
            setState((state) => ({ ...state, planGroups: planGroups }))
        }
        if (planGroupsCount) {
            setState((state) => {
                return { ...state, planGroupsCount: planGroupsCount };
            });
        }
        if (banks) {
            setState((state) => {
                return { ...state, systemBanks: banks };
            });
        }
        if (filteredChannels) {
            setState((state) => {
                return { ...state, filteredPayoutChannels: filteredChannels };
            });
        }
        if (payoutChannelsSaved) {
            setState((state) => {
                return { ...state, payoutChannels: payoutChannelsSaved, isNewChannel: payoutChannelsSaved.length === 0 };
            });
        }
        if (payoutChannelsFavourite) {
            setState((prev) => { return { ...prev, favouritePayoutChannels: payoutChannelsFavourite, } });
        }
    }, [planGroups, planGroupsCount, payoutChannelsSaved, payoutChannelsFavourite, banks, filteredChannels])

    useEffect(() => {
        if (!settingFavourite && state.callFav) {
            getFavouriteChannels(defMembership.business.id);
            getSavedPayoutChannels(defMembership.business.id);
            if (errorSettingFavourite) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_add_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    summary: t("successful"),
                    detail: `${state.newFav.name} ${t("recipient.save_fav")}`,
                    life: 3000,
                });
            }
            setState((prev) => { return { ...prev, callFav: false, newFav: null } });
        }
        if (!settingUnfavourite && state.loadUnFav) {
            getFavouriteChannels(defMembership.business.id);
            getSavedPayoutChannels(defMembership.business.id);
            if (errorSettingUnfavourite) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_removed_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    summary: t("successful"),
                    detail: t("recipient.removed_fav"),
                    life: 3000,
                });
            }
            setState((prev) => { return { ...prev, loadUnFav: false } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defMembership.business.id, errorSettingFavourite, errorSettingUnfavourite, getFavouriteChannels, settingFavourite, settingUnfavourite, state.callFav, state.loadUnFav]);

    useEffect(() => {
        if (!settingUnfavourite && state.setUnfav) {
            getFavouriteChannels(defMembership.business.id);
            getSavedPayoutChannels(defMembership.business.id);

            if (errorSettingUnfavourite) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_removed_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    summary: t("successful"),
                    detail: `${state.newFav.name} ${t("recipient.remove_success")}`,
                    life: 3000,
                })
            };
            setState((state) => { return { ...state, setUnfav: false, newFav: null, unFavFiltered: false } })
        }
        if (!settingFavourite && state.setFav) {
            getFavouriteChannels(defMembership.business.id);
            getSavedPayoutChannels(defMembership.business.id);
            if (errorSettingFavourite) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.err_add_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    summary: t("successful"),
                    detail: `${state.fav} ${t("recipient.save_fav")}`,
                    life: 3000,
                });
            };
            setState((state) => { return { ...state, setFav: false, fav: null, favFiltered: false } })
        }
        if (!settingUnfavourite && state.selectedBank && state.unFavFiltered) {
            filterChannelsByBanks(defMembership.business.id, state.query);
        }
        if (!settingFavourite && state.selectedBank && state.favFiltered) {
            filterChannelsByBanks(defMembership.business.id, state.query);
        }
    }, [settingUnfavourite, state.setUnfav, errorSettingFavourite, settingFavourite, state.selectedBank,
        state.fav, errorSettingFavourite, state.setFav, state.favFiltered, state.unFavFiltered])

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            const options = `&page=${state.page}&size=${state.size}&sort=${state.sort}`
            let query = ""
            const appendQuery = (param) => {
                query = query ? query + "&" + param : param
            }
            if (state.selectedBank) appendQuery(state.selectedBank.id)
            query = `${query}${options}`
            filterChannelsByBanks(defMembership.business.id, query);
            setState((state) => { return { ...state, query: query } })
        }
    }, [filterChannelsByBanks, defMembership, state.selectedBank, getMembership, state.page, state.size, state.sort]);

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            const options = `&page=${state.page}&size=${state.size}&sort=${state.sort}`
            let query = ""
            const appendQuery = (param) => {
                query = query ? query + "&" + param : param
            }
            if (state.selectedBank) appendQuery(state.selectedBank.id)
            query = `${query}${options}`
            filterChannelsByBanks(defMembership.business.id, query);
        }
    }, [filterChannelsByBanks, defMembership, state.selectedBank, getMembership, state.page, state.size, state.sort]);

    const loading = fetchingPendingCount || fetchingWallet || fetchingPlanGroups || fetchingPlanGroupsCount

    const showErrorFav = () => {
        toast.current.show({ severity: 'error', summary: 'Error Message', detail: t("recipient.select_error") });
    }
    Yup.addMethod(Yup.number, "validatePhoneNumber", function (errorMessage) {
        return this.test(`test-mobile-number-require`, errorMessage, function (value) {
            const { path, createError } = this;
            const valid = validator.isMobilePhone(value ? value.toString() : "") ? "true" : "false";
            return (value && valid === "false") || createError({ path, message: errorMessage });
        });
    });
    Yup.addMethod(Yup.number, "validateBankNumber", function (errorMessage) {
        return this.test(`test-mobile-number-require`, errorMessage, function (value) {
            const { path, createError } = this;
            const valid = validator.isIBAN(value ? value.toString() : "") ? "true" : "false";
            return (value && valid === "false") || createError({ path, message: errorMessage });
        });
    });

    const loadingSpinner = settingUnfavourite || settingFavourite || fetchingFilteredBanks

    const showWarn = () => {
        toast.current.show({ severity: 'warn', summary: t("product_account.warning_summary"), detail: t("product_account.warning_detail"), life: 3000 });
    }

    const routeTo = (data) => {
        if (data?.available < 0.5) {
            showWarn()
        }
        else {
            navigate(`/money/send?payoutChannelId=${data?.id}`, { state: { payoutChannel: data, activeStep: 1, } });
        }
    }

    const route = (data) => {
        navigate({ pathname: "/money/withdrawals", search: "payoutChannelId=" + data.id, state: { payoutChannel: data } })
    }

    const statusTemplate = (rowData) => {
        if (rowData.status) {
            return <span className={`product-badge status-${(rowData.status ? rowData.status.toLowerCase() : '')}`}>{rowData.status}</span>;
        }
    }

    const nameBodyTemplate = (rowData) => {
        if (rowData) {
            return <div className="payoutChannel-name_body">
                {
                    rowData.name ?
                        <span className="payoutChannel-name">{rowData.name !== null ? rowData.name : "--"}</span> : null
                }
            </div>
        }
    }

    const favoriteTemplate = (rowData) => {
        if (rowData) {
            return <div className="car-buttons" >
                {rowData.favourite === true && <Button icon="pi pi-star-fill" className="p-button-rounded " id="wallet-favorite"
                    onClick={
                        () => {
                            payoutChannelSetUnfavourite(rowData.id)
                            setState((state) => { return { ...state, setUnfav: true, unFavFiltered: true, newFav: rowData } })
                        }
                    }
                    tooltip={t("product_account.unfavourite")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
                }

                {!rowData.favourite && <Button
                    onClick={
                        () => {
                            setFavouritePayoutChannels(rowData.id)
                            setState((state) => { return { ...state, setFav: true, favFiltered: true, fav: rowData.name } })
                        }
                    }
                    icon="pi pi-star" className="p-button-rounded p-button-outlined" id="wallet-unfavorite"
                    tooltip={t("product_account.favourite")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
                }
            </div>
        }
    }

    const channelBodyTemplate = (rowData) => {
        if (rowData) {
            if (rowData) {
                return <div className="main-cannel">
                    <div className="channel-image">
                        {
                            rowData.systemBank ?
                                <img alt="system-bank logo" src={rowData.systemBank.logoUrl} width={80} height='50rem' />
                                : null
                        }
                    </div>
                    <div className="channel-info" style={{ justifyContent: "center" }} >
                        <span className="bold-info" id="channel-bold-info">
                            {
                                rowData.systemBank ?
                                    rowData.systemBank.name
                                    : "--"
                            }
                        </span>
                        <span className="normal-info" id="channel-normal-info">
                            {
                                rowData.type ?
                                    rowData.type
                                    : "--"
                            }
                        </span>
                    </div>
                </div >
            }
        }
    }

    const actionTemplate = (rowData) => {
        if (rowData) {
            return <div className="car-buttons">
                <Button icon="pi pi-list" className="p-button p-button-rounded mr-2 wallet-button" id="wallet-button" onClick={() => { route(rowData) }}
                    tooltip={t("saved_recipients.list")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
                <Button icon="pi pi-send" className="p-button p-button-rounded wallet-button" id="wallet-button" onClick={() => { routeTo(rowData) }}
                    tooltip={t("saved_recipients.send")}
                    tooltipOptions={{
                        position: "bottom",
                        mouseTrack: true,
                        mouseTrackTop: 15,
                    }} />
            </div>
        }
    }

    const columns = [
        { field: 'name', body: favoriteTemplate, },
        { field: "status", body: statusTemplate, header: t("saved_account.status"), sort: true },
        { field: 'name', body: nameBodyTemplate, },
        {
            field: "identifier", body: (rowData) => (
                <span className="account-number">
                    {rowData.identifier}
                </span>),
        },
        { field: "accountName", body: channelBodyTemplate },
        { field: "cost", body: actionTemplate, style: { color: "#666678" } },
    ]

    const defaultCellStyles = { border: "0px", borderBottom: "1px solid #e0e0e0", padding: "auto" }
    const defaultHederStyles = {
        display: "none"
    }

    const selectedChannelTemplate = (option, props) => {
        if (option) { return (<div>{option.name} - {option.identifier}</div>); }
        return <span>{props.placeholder}</span>;
    };

    const channelOptionTemplate = (option) => (<>{option.name} - {option.identifier}</>);

    return (loading ? <Loading /> :
        <div>
            <Toast ref={toast} />
            <AddAccountDialog {...{ state,  setState, payoutChannelsFavourite:state.favouritePayoutChannels , setFavouritePayoutChannels, t, channelOptionTemplate, selectedChannelTemplate }} />
            <div className="carousel-demo">
                <FavouriteSection  {...{
                    fetchingFavourite,
                    payoutChannelsFavourite: state.favouritePayoutChannels,
                    setFavouritePayoutChannels,
                    payoutChannelSetUnfavourite,
                    onClickAccountCard: routeTo,
                    setFieldValue: () => { },
                    setState, t, showErrorFav
                }} />
            </div>
            <h6 className="wallet-titles-1" >{t("saved_recipients.title")}</h6>
            <Card  id="dropdown-filters" className="action_bar_cards1">
                <div className="p-grid p-fluid main-div">
                    <>
                        <div className="p-col-12 p-md-3 filters" >
                            <div className="p-inputgroup">
                                <Dropdown
                                    className="activity-dropdown drop"
                                    value={state.selectedBank}
                                    filter
                                    showClear
                                    filterBy='name'
                                    optionLabel="name"
                                    options={state.systemBanks}
                                    placeholder={t("saved_recipients.filter_by_bank")}
                                    onChange={(e) => {
                                        setState({ ...state, selectedBank: e.value, page: 0, first: 0 })
                                    }} />
                            </div>
                        </div>
                    </>
                </div>
            </Card>
            <DataTable
                id="data_table"
                sortMode="name"
                value={state.selectedBank ? filteredChannels : payoutChannelsSaved}
                className="p-datatable-responsive-table wallet-table"
                responsiveLayout="stack" breakpoint="1050px"
                dataKey="id"
                paginator
                rows={15}
                loading={loadingSpinner}
            >
                {columns.map(({ field, body, sort, style, className }, i) =>
                    <Column className={className} key={i} field={field} body={body}
                        alignHeader="center"
                        style={{ ...defaultCellStyles, ...style }}
                        headerStyle={defaultHederStyles} sortable={sort}
                    />)}
            </DataTable>
        </div>
    )
}
export default SavedRecipients;