import { Button } from "primereact/button";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";

const PlanEdit = ({ onHide, tier, setTrigger, trigger }) => {
  const { t } = useTranslation("common");
  const [state, setState] = useState({
    submit: false
  });
  const toastPlan = useRef(null);
  const {
    control,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: tier.name,
      description: tier.description,
      flex: tier.flex,
      policy: tier.policy
    }
  });

  const { updating, errorUpdating } = useSelector((state) => state.plans)
  const defMembership = useSelector((state) => state.defMembership.defMembership);

  const dispatch = useDispatch()
  const updateTier = useCallback((planId, plan) => { dispatch(PlanActions.planUpdateRequest(planId, plan)) }, [dispatch])
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch]);

  useEffect(() => {
    if (state.submit) {
      if (!updating) {
        if (!errorUpdating) {
          showSuccess();
        } else {
          showError();
        }
        setState((state) => ({ ...state, submit: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorUpdating, updating, state.submit]);

  const showSuccess = () => { toastPlan.current.show({ severity: 'success', summary: t("plan.save_success"), detail: "", life: 2000 }); }
  const showError = () => { toastPlan.current.show({ severity: 'error', summary: t("products.generic_error"), detail: t("product.edit_error"), life: 3000 }); }

  const onSubmit = ({ name, description }) => {
    updateTier(tier.id, {
      tier, id: tier.id, name, description,
      flex: tier.flex, graceDays: tier.graceDays, reminderDays: tier.reminderDays, policy: tier.policy
    });
    if (!state.submit) {
      setState({
        ...state,
        submit: true,
      });
    }
  };

  const fetchActive = () => {
    getActivePlanGroups(defMembership.business.id);
  };

  return (
    <>
      <Dialog
        header={t("products.edit_plan_info")}
        visible={!!tier.id}
        style={{ width: "355px" }}
        onHide={onHide}
        className="tier_dialog" id="tier_dialog"
      >
        <Toast
          className="p-toast p-toast-container p-toast-item p-toast-message p-toast-title "
          ref={toastPlan}
          onRemove={({ severity }) => {
            if (severity === "success") {
              reset()
              onHide({})
              setTrigger(!trigger)
              fetchActive()
            }
          }}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid mt-5">
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <div className="flex flex-column gap-2 mb-4" style={{ padding: "0px 10px 0px 10px" }}>
                  <label className="plan_edit_labels" >{t("plan.name")}</label>
                  <InputText style={{ height: "3rem" }} defaultValue={tier.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} maxLength={50} />
                </div>
              </>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <div className="flex flex-column gap-2 mb-4" style={{ padding: "0px 10px 0px 10px" }}>
                  <label className="plan_edit_labels" >{t("plan.description")}</label>
                  <InputText style={{ height: "3rem" }} defaultValue={tier.description} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} maxLength={100} />
                </div>
              </>
            )}
          />
          <div>
            <div className="edit_plan_footer">
              <Button id="footer-btn-cancel"
                type="reset"
                label={t("cancel")}
                icon="pi pi-times"
                onClick={onHide}
              />
              <Button
                type="submit"
                disabled={updating}
                loading={updating}
                label={t("common.save")}
                id="rename-btn"
              />
            </div>
          </div>
        </form>
      </Dialog >
    </>
  );
};
export default PlanEdit;