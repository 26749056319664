/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import SubscriberActions from "../../Redux/transition/subscriber/subscriber.reducer";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import BusinessActions from "../../Redux/actions/business-actions"
import PaySwitchActions from "../../Redux/transition/payswitch/payswitch.reducer";
import DocumentActions from '../../Redux/transition/document-upload/document-upload.reducer';
import Loading from "../../Components/Loading";
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber"
import "../../Styles/pages/products.scss"
import "../../Styles/dataTable.scss"
import CustomDataTable from '../../Components/CustomDataTable';
import PlanEdit from './PlanEdit';
import PlanDetailCards from './planDetailCards';
import { Paginator } from 'primereact/paginator';
import MarkAsPaid from '../../Components/MarkAsPaid';
import OnboardingNotification from '../../Components/OnboardingNotification';
import ShareUrl from './ShareUrl';
import PriceChange from './priceChange';
import MoveSubscriber from './MoveSubscribers';

const PlanDetail = () => {
    const { t } = useTranslation("common");
    const { id: planId } = useParams();
    const [displayBasic, setDisplayBasic] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = useState({
        sort: 'id,desc',
        count: null,
        size: 15,
        first: 0,
        page: 0,
        plan: {},
        subscribers: [],
        unmounted: false,
        fetching: true,
        planArchiving: false,
        performance: {},
        missed: {},
        pending: [],
        missedCount: 0,
        pendingCount: 0,
        collected: [],
        collectedCount: 0,
        id: null,
        groupExternalPayments: {},
        flex: null,
        tabChange: true,
        sharedPlan: {},
        notOwnerVisible: false,
        verifPendingVisible: false,
        priceVisible: false,
        cost: null,
        priceId: null,
        visibleMove: false,
    });
    const [visible, setVisible] = useState(false);
    const [visibleInvite, setVisibleInvite] = useState(false);
    const [nameChangeVisible, setNameChangeVisible] = useState({})
    const [trigger, setTrigger] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [sharePlanDialog, setSharePlanDialog] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const toast = useRef(null);

    const defMembership = useSelector((state) => state.defMembership.defMembership)
    const { plan, archiving, archiveSuccess, errorArchiving, performance, missed,
        fetchingMissed, missedCount, fetchingPaymentMissedCount,
        pending, fetchingPaymentPending, fetchingPending, pendingCount, fetchingPaymentPendingCount, collected, collectedCount, fetchingCollected, fetchingCollectedCount
    } = useSelector((state) => state.plans)
    const { planSubscribers, fetchingPlanSubscribers, fetchingPlan } = useSelector((state) => state.subscribers)
    const planPayments = useSelector((state) => state.paymentTracks.planPayments);
    const fetchingExternalPayments = useSelector((state) => state.paymentTracks.fetchingPlansExternalPayments)
    const business = useSelector((state) => state.businesses.business);
    const paymentCredentials = useSelector((state) => state.payswitch.credentials);
    const fetchingPaymentCredentials = useSelector((state) => state.payswitch.fetchingCredentials);
    const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
    const verificationStatus = useSelector((state) => state.documents.verifStatus);

    const dispatch = useDispatch();
    const getPlan = useCallback((planId, options) => dispatch(PlanActions.planRequest(planId, options)), [dispatch]);
    const getPlanSubscribers = useCallback((planId, options) => dispatch(SubscriberActions.subscriberPlanRequest(planId, options)), [dispatch])
    const archivePlan = useCallback((planId, membershipId) => { dispatch(PlanActions.planArchiveRequest(planId, membershipId)) }, [dispatch])
    const planPerformance = useCallback((tierId) => { dispatch(PlanActions.planPerformanceRequest(tierId)) }, [dispatch])
    const getPlanPaymentMissed = useCallback((planId, options) => dispatch(PlanActions.planPaymentMissedRequest(planId, options)), [dispatch])
    const getPlanPaymentMissedCount = useCallback((planId) => dispatch(PlanActions.planPaymentMissedCountRequest(planId)), [dispatch])
    const getPlanPaymentPending = useCallback((planId, options) => dispatch(PlanActions.planPaymentPendingRequest(planId, options)), [dispatch])
    const getPlanPaymentPendingCount = useCallback((planId,) => dispatch(PlanActions.planPaymentPendingCountRequest(planId,)), [dispatch])
    const getPlanPaymentCollected = useCallback((planId, options) => dispatch(PlanActions.planPaymentCollectedRequest(planId, options)), [dispatch])
    const getPlanPaymentCollectedCount = useCallback((planId,) => dispatch(PlanActions.planPaymentCollectedCountRequest(planId,)), [dispatch])
    const getGroupExternalPayments = useCallback((businessId, groupId, options) => { dispatch(PaymentTrackActions.planExternalPaymentsRequest(businessId, groupId, options)) }, [dispatch])
    const getBusiness = useCallback((businessId) => dispatch(BusinessActions.businessRequest(businessId)), [dispatch]);
    const getCredentials = useCallback((businessId) => { dispatch(PaySwitchActions.paySwitchCredentialRequest(businessId)) }, [dispatch]);
    const getBusinessVerificationStatus = useCallback((businessId) => dispatch(DocumentActions.businessVerificationStatusRequest(businessId)), [dispatch]);

    useEffect(() => {
        getBusiness(defMembership.business.id)
        getCredentials(defMembership.business.id)
        getPlan(planId);
        getPlanSubscribers(planId);
        planPerformance(planId);
        getPlanPaymentMissed(planId, { size: state.size });
        getPlanPaymentMissedCount(planId)
        getPlanPaymentPending(planId, { size: state.size });
        getPlanPaymentPendingCount(planId)
        getPlanPaymentCollected(planId, { size: state.size });
        getPlanPaymentCollectedCount(planId)
        getGroupExternalPayments(defMembership.business.id, planId, { size: state.size })
    }, [trigger, getPlan,]);

    useEffect(() => {
        if (plan) {
            setState(state => {
                return {
                    ...state, plan: plan, flex: plan.flex, priceId: plan.id
                }
            });
        }
        if (planSubscribers) {
            setState(state => { return { ...state, subscribers: planSubscribers } });
        }
        if (performance) {
            setState(state => { return { ...state, performance } });
        }
        if (missed) {
            setState((state) => { return { ...state, missed: missed?.content } })
        }
        if (pending) {
            setState((state) => ({ ...state, pending: pending?.content }))
        }
        if (missedCount) {
            setState((state) => ({ ...state, missedCount: missedCount }))
        }
        if (pendingCount) {
            setState((state) => ({ ...state, pendingCount: pendingCount }))
        }
        if (collected) {
            setState((state) => ({ ...state, collected: collected }))
        }
        if (collectedCount) {
            setState((state) => ({ ...state, collectedCount: collectedCount }))
        }
        if (planPayments) {
            setState((state) => ({ ...state, groupExternalPayments: planPayments?.content }))
        }
        if (!verificationStatus) {
            getBusinessVerificationStatus(defMembership.business.id)
        }
    }, [plan, planSubscribers, getPlan, getPlanSubscribers, performance, collected, collectedCount,
        planPayments, state.groupExternalPayments, missed, verificationStatus])


    useEffect(() => {
        if (!archiving && state.planArchiving && archiveSuccess) {
            showSuccess({ message: t("product.planArchive_successful"), severity: "success", })
            setState((state) => ({ ...state, planArchiving: false }))
            getPlan(planId);
            getPlanSubscribers(planId);
        }
        if (!archiving && state.planArchiving && errorArchiving) {
            showError()
            setState((state) => ({ ...state, planArchiving: false }))
        }
    }, [state.planArchiving, archiving, errorArchiving, archiveSuccess])

    useEffect(() => {
        switch (activeTab) {
            case 1:
                getPlanPaymentCollected(planId, { page: state.page, sort: "receivedAt,desc", size: state.size });
                break;
            case 2:
                getPlanPaymentMissed(planId, { page: state.page, size: state.size });
                break;
            case 3:
                getPlanPaymentPending(planId, { page: state.page, size: state.size });
                break;
            case 4:
                getGroupExternalPayments(defMembership.business.id, planId, { page: state.page, size: state.size })
                break;
            default:
                getPlanSubscribers(planId);
                break;
        }
    }, [activeTab])

    //Toggle not Owner dialogue visibility
    const toggleVerifPendingDialogue = () => { setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible }; }); };
    //Toggle not Owner dialogue visibility
    const toggleNotOwnerDialogue = () => { setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible }; }); };

    const showSuccess = (data) => { toast.current.show({ severity: 'success', summary: t("product.success_summary"), detail: data.message, life: 3000 }); }
    const showError = () => { toast.current.show({ severity: 'error', summary: t("product.error_summary"), detail: t("product.archive_error"), life: 3000 }); }

    if (fetchingPlanSubscribers || fetchingPaymentMissedCount ||
        fetchingPaymentPendingCount ||
        fetchingPaymentPending || fetchingCollectedCount) {
        return <Loading />;
    }
    const accept = () => {
        setState((state) => ({ ...state, planArchiving: true }))
        archivePlan(state.plan.id, defMembership.id)
        toast.current.show({ severity: 'info', summary: t("product.archive"), detail: t("products.archive_plan_info"), life: 3000 });
    }

    const reject = () => { setVisible(false) }

    const toggleShareProduct = () => {
        if (fetchingVerifStatus || fetchingPaymentCredentials) {
            return <Loading />;
        } else {
            if (!business.verified) {
                if (state.verifStatus && !state.fetchingVerifRecord) { return toggleVerifPendingDialogue(); }
                if (!state.verifStatus && !state.fetchingVerifRecord) {
                    switch (defMembership.role) {
                        case "OWNER":
                            setOpenNotification(true);
                            break;
                        default:
                            toggleNotOwnerDialogue();
                    }
                }
            }
        }
    };
    const togglePriceChange = (data) => {
        setState({ ...state, priceVisible: true, cost: data.cost, priceId: data.id });
    };
    const priceOnHide = () => {
        setState({ ...state, priceVisible: false });
    };
    const closeMoveSubscriber = () => { setState((state) => ({ ...state, visibleMove: false })); };
    const toggleMoveSubscriber = () => { setState((state) => ({ ...state, visibleMove: !state.visibleMove })); };
    const planShare = () => {
        if (!business.verified || !paymentCredentials) {
            toggleShareProduct()
        }
        else {
            setSharePlanDialog(true);
            setState((state) => ({
                ...state,
                sharedPlan: {
                    shortUrl: plan.group.shortUrl,
                    plan: plan.name,
                    planGroupId: plan.id,
                    reportable: plan.group.reportable,
                    reportCode: plan.group.reportCode,
                },
            }));
        }
    }

    const dateFormatterBody = (rowData, e) =>
        <div style={{ textAlign: "start" }}>
            <p>{rowData[e.field] ? moment(rowData[e.field]).format("MMMM D, YYYY h:mm A") : "--"}</p>
        </div>

    const dateFormatter = (rowData, e) =>
        <div style={{ textAlign: "start" }}>
            <p style={{ fontWeight: "lighter" }} className="cust-phoneNo">{rowData[e.field] ? moment(rowData[e.field]).format("MMMM D, YYYY") : "--"}</p>
        </div>

    const statusTemplate = (rowData, e) => {
        return rowData[e.field] && <div className='stat-cont'>
            <span className={`product-badge status-${(rowData[e.field] ? rowData[e.field].toLowerCase() : '')}`}>{rowData[e.field]}</span>
            {rowData.status === "CANCELLED" && <div className="status-bottom-text" >
                <div className="space align"> <span className="labelOn">{t("subscriber.occurred_on")}</span></div>
                <div><p className="labelOnDate align">{moment(rowData.cancelledOn).format('MMMM D, YYYY')}</p></div>
            </div>}
            {rowData.status === "COMPLETE" && <div className="status-bottom-text" >
                <div className="space align"> <span className="labelOn">{t("subscriber.occurred_on")}</span></div>
                <div><p className="labelOnDate align">{moment(rowData.completedOn).format('MMMM D, YYYY')}</p></div>
            </div>}
            {rowData.status === "PAUSED" && <div className="status-bottom-text">
                <div className="space align"> <span className="labelOn">{t("subscriber.occurred_on")}</span></div>
                <div><p className="labelOnDate align">{moment(rowData.pausedOn).format('MMMM D, YYYY')}</p></div>
            </div>}
        </div>
    }

    const referenceTemplate = (rowData) =>
        <div style={{ textAlign: "start" }} >
            <span style={{ fontWeight: "bold", color: "#008DD5", fontSize: "14px" }} className='link' onClick={() =>
                navigate(`/subscribers/subscriber/subscription/${rowData.id}`)}
            >{rowData.subscriberReference}</span>
        </div>

    const markActionTemplate = (rowData) => {
        if (rowData) {
            return (rowData.subscriptionStatus === "ACTIVE" ? <span
                style={{ color: "#008dd5" }}
                id='plan-detail-mark-action'
                className="p-button-warning p-button-rounded p-button-text mr-2 customerAction"
                onClick={() => {
                    setDisplayBasic(true)
                    setState((state) => ({
                        ...state,
                        id: rowData.id,
                        scheduledDate: rowData.scheduledDate
                    }));
                }}>{t("subscriber.paid")}</span>
                : "")
        }
    }

    const paymentIdTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span>
                    {rowData.latestDlr.id}
                </span>
            </div>
        }
    }

    const occurredOnTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <span className='tier-date'>
                    {moment(rowData.externallyReceivedOn).format('DD MMM YYYY')}
                </span>
            </div>
        }
    }

    const costTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div>
                    {
                        rowData.subscription.plan?.flex === true ?
                            <div>
                                <span style={{ display: "block", marginBottom: "0.5rem" }}>{t("products.flexible_amount")}</span>
                                {rowData.extReceivedAmount ? <span className="transactions_data"> {defMembership?.business.country.currency} {""} {rowData.extReceivedAmount?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                    : "--"}
                            </div>
                            :
                            <p className="transactions_data">{defMembership?.business.country.currency} {""} {rowData.cost?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    }
                </div>
            </div>
        }
    }

    const notesTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {
                    rowData.extReceivedNotes ?
                        <div>
                            <p> {rowData.extReceivedNotes}</p>
                        </div> : "--"
                }
            </div>
        }
    }

    const paymentSubscriptionBodyTemplate = (rowData) => {
        if (rowData) {
            return <div>
                <div className="subscriptionBodyTemplate" style={{ alignItems: "flex-start" }}>
                    {
                        rowData.subscription.subscriberReference !== null ?
                            <p style={{ marginBottom: "15px" }} className="reference-text detail-navigation" id="planName"
                                onClick={() => {
                                    if (rowData.subscription.id)
                                        navigate({ pathname: `/subscribers/subscriber/subscription/${rowData.subscription.id}`, rowData });
                                }}
                            >
                                {rowData.subscription.subscriberReference}
                            </p>
                            : ""
                    }
                    {
                        rowData.subscription.plan?.name ?
                            <span className=" tier-name-content">
                                {rowData.subscription.plan.name !== null ? rowData.subscription.plan.name : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription?.plan?.billingPeriod ?
                            <span className="plan-group-text">
                                {rowData.subscription.plan.billingPeriod !== null ? t(`product.${rowData.subscription.plan.billingPeriod.toLowerCase()}`) : ""}
                            </span> : null
                    }
                    {
                        rowData.subscription.plan?.groupName &&
                        <span className="plan-text detail-navigation hov "
                            onClick={() => {
                                navigate(`/products/detail/${rowData.subscription.plan.groupId}`)
                            }}>
                            {rowData.subscription.plan.groupName}
                        </span>
                    }
                </div >
            </div >
        }
    }

    const planCustomerTemplate = (tier) => {
        if (tier) {
            return <div>
                {tier.subscriber.businessCustomer?.customerNum === null ? <div className="planCustomerTemplate">
                    <span className="link plan-name-link_bold"
                        onClick={() =>
                            navigate("/subscribers/subscriber/subscription/" + tier.subscriber.id)
                        }>{tier.subscriber.businessCustomer?.name}
                    </span>
                    <span className="plan-phone">{tier.subscriber.businessCustomer?.phoneNo}</span>
                    <span className="plan-email">{tier.subscriber.businessCustomer?.email}</span>
                </div> :
                    <div className="planCustomerTemplate">
                        <span className="plan_customerNum">{tier.subscriber.businessCustomer?.customerNum}</span>
                        {tier.subscriber.businessCustomer?.customerNum === null ? <span className="link plan-name-link_bold"
                            onClick={() =>
                                navigate("/subscribers/subscriber/subscription/" + tier.subscriber.id)}
                        >{tier.subscriber.businessCustomer?.name}
                        </span> : <span className="link plan-name-link"
                            onClick={() =>
                                navigate("/subscribers/subscriber/subscription/" + tier.subscriber.id)}
                        >{tier.subscriber.businessCustomer?.name}
                        </span>}
                        <span className="plan-phone">{tier.subscriber.businessCustomer?.phoneNo}</span>
                        <span className="plan-email">{tier.subscriber.businessCustomer?.email}</span>
                    </div>}
            </div>
        }
    }

    const subsTemplate = (rowData) => {
        if (rowData) {
            return <div>
                {rowData.businessCustomer?.customerNum === null ? <div className="planCustomerTemplate">
                    <span className="link plan-name-link_bold"
                        onClick={() =>
                            navigate("/subscribers/subscriber/subscription/" + rowData.id)}
                    >{rowData.businessCustomer?.name}
                    </span>
                    <span className="plan-phone">{rowData.businessCustomer?.phoneNo}</span>
                    <span className="plan-email">{rowData.businessCustomer?.email}</span>
                </div> :
                    <div className="planCustomerTemplate">
                        <span className="plan_customerNum">{rowData.businessCustomer?.customerNum}</span>
                        {rowData.businessCustomer?.customerNum === null ? <span className="link plan-name-link_bold"
                            onClick={() =>
                                navigate("/subscribers/subscriber/subscription/" + rowData.id)}
                        >{rowData.businessCustomer?.name}
                        </span> : <span className="link plan-name-link"
                            onClick={() =>
                                navigate("/subscribers/subscriber/subscription/" + rowData.id)}
                        >{rowData.businessCustomer?.name}
                        </span>}
                        <span className="plan-phone">{rowData.businessCustomer?.phoneNo}</span>
                        <span className="plan-email">{rowData.businessCustomer?.email}</span>
                    </div>}
            </div>

        }
    }

    const collectedAmountTemplate = (tier) => {
        if (tier) {
            return <div className="col_amounts">
                <p className="collected_amount">{defMembership?.business.country?.currency}{" "}{tier?.amount?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
        }
    }
    const serviceFeeTemplate = (tier) => {
        if (tier) {
            return <div className="col_amounts">
                <p className="plan_revenue">{defMembership?.business.country?.currency}{" "}{tier?.fees?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
        }
    }
    const revenueTemplate = (tier) => {
        if (tier) {
            return <div className="col_amounts">
                <p className="plan_redeemable">{defMembership?.business.country?.currency}{" "}{tier?.redeemable?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
        }
    }

    const formatDate = date => {
        return moment(date).format('DD MMMM, YYYY')
    }

    function getTimeFromDateString(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).replace('am', 'AM').replace('pm', 'PM');
    }

    const receivedAtBodyTemplate = (tier) => {
        if (tier) {
            return <div className='receivedAt-main-div'>
                <span className="receivedAt-lastBilledOn-span">{formatDate(tier?.subscriber.lastBilledOn)}</span>
                <span className="receivedAt-spans">{getTimeFromDateString(tier?.subscriber.lastBilledOn)}</span>
            </div>
        }
    }

    const planScheduledDate = (tier) => {
        if (tier) {
            return <div className="planScheduledDate_div">
                <p className={`failed_scheduleStatus subscription-status status-${tier.scheduleStatus}`}>
                    {tier.scheduleStatus?.toUpperCase()}</p>
                <p className="cust-scheduledFor">{t("subscriber.scheduled_for")}</p>
                <p className="scheduledFor">{formatDate(tier.scheduledDate)}</p>
                <p className="scheduledFor scheduledFor_pb">{getTimeFromDateString(tier.scheduledFor)}</p>
            </div>
        }
    }

    const failedAmountTemplate = (rowData, e) => {
        if (rowData) {
            return <div className="col_amounts">
                <span>{defMembership?.business.country.currency}{" "}{rowData?.amountDue?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>;
        }
    }

    const upcomingPaymentsSchedule = (tier) => {
        if (tier) {
            return <div className='upcoming-payment-date-div'>
                <p style={{ display: "flex", width: "6rem", justifyContent: "center", }}
                    className={`subscription-status status-${tier.scheduleStatus}`}>
                    {tier.scheduleStatus.toUpperCase()}</p>
                <p className="cust-scheduledFor">{t("subscriber.scheduled_for")}</p>
                <p className="scheduledFor">{formatDate(tier.scheduledDate)}</p>
                <p className="scheduledFor scheduledFor_pb">{getTimeFromDateString(tier.scheduledFor)}</p>
            </div>
        }
    }

    const upcomingAmountTemplate = (rowData, e) => {
        if (rowData) {
            return <div className="col_amounts">
                <span className='upcoming-amount'>{defMembership?.business.country.currency}{" "}{rowData?.amountDue?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
            </div>;
        }
    }

    const failedTransactionColumns = [
        { field: "scheduledDate", body: planScheduledDate, header: t("products.payment_schedule"), headerClassName: "failed-transaction-column failed_transaction_status" },
        {
            field: "customer.name", header: t("plan.subscriber"), headerClassName: "failed-transaction-column", body: subsTemplate
        },
        { field: "amountDue", header: t("amount"), body: failedAmountTemplate, headerClassName: "failed-transaction-column" },
        { body: markActionTemplate }
    ]

    const upcomingPaymentColumn = [
        { field: "scheduledDate", body: upcomingPaymentsSchedule, header: t("products.payment_schedule"), headerClassName: "upcoming-payments-column upcoming-schedule-payments" },
        {
            field: "customer.name", header: t("products.customer_info"), body: subsTemplate,
            headerClassName: "upcoming-payments-column subs-body-spacing"
        },
        { field: "amountDue", header: t("amount"), body: upcomingAmountTemplate, headerClassName: "upcoming-payments-column" },
    ]

    const externalPayments = [
        { field: 'id', body: paymentIdTemplate, header: t("products.ref"), headerClassName: "amount-header" },
        { field: 'subscriber', body: paymentSubscriptionBodyTemplate, header: t("subscriber.info"), headerClassName: "subsTemplate-header" },
        { field: 'externallyReceivedOn', body: occurredOnTemplate, header: t("subscriber.occurred_on"), headerClassName: "amount-header" },
        { field: 'extReceivedAmount', body: costTemplate, header: t("dashboard.amount"), headerClassName: "amount-header" },
        { body: notesTemplate, header: t("subscriber.notes"), headerClassName: "amount-header" },
    ]

    const tabs = [{
        label: "subscriber.title", columns: [
            { field: "status", body: statusTemplate, header: t("products.status"), bodyClassName: "status-spacing-content" },
            { field: "subscriberReference", header: t("subscriber.subs_id"), body: referenceTemplate, alignHeader: "left", bodyClassName: "ref-spacing" },
            { header: t("subscriber.info"), body: subsTemplate, alignHeader: "left", bodyClassName: "subs-body-spacing" },
            { field: "joinedOn", body: dateFormatter, header: t("products.joined_on"), alignHeader: "left", bodyClassName: "date-spacing" },
            { field: "nextBillingDate", body: dateFormatterBody, header: t("products.next_billing_date"), alignHeader: "left" },
        ],
        value: state.subscribers, count: state.subscribers.length, index: 0
    }, {
        label: "dashboard.collected_payments", columns: [
            {
                field: "customer.reference", header: t("products.payment_id"), headerClassName: "plan_payment_id",
                body: (tier) => <p className="payment-ref"
                    onClick={() =>
                        navigate("/payments/detail/" + tier.id)}
                >{tier.reference}</p>
            },
            {
                field: "customer.name", header: t("products.customer_info"), body: planCustomerTemplate, headerClassName: "plan_subscriber_info"
            },
            { field: "receivedAt", body: receivedAtBodyTemplate, header: t("products.paid_on"), headerClassName: "plan_paid_on" },
            {
                field: "paymentMode", header: t("recipient.payment_channel_1"), headerClassName: "plan_paymentMode",
                body: (tier) => <div className='plan_paymentMode_div'><p className="plan_paymentMode">{tier.paymentMode}</p></div>
            },
            { field: "amount", header: t("product.collected"), body: collectedAmountTemplate, headerClassName: "plan_collected" },
            { field: "fees", header: t("subscriber.service_fee"), body: serviceFeeTemplate, headerClassName: "plan_fees" },
            { field: "redeemable", header: t("common.revenue"), body: revenueTemplate, headerClassName: "plan_redeemable" },
        ],
        value: state.collected, count: state.collectedCount, paginator: true, index: 1

    },
    {
        label: t('subscriber.failed_transaction'), value: state.missed,
        columns: failedTransactionColumns, count: state.missedCount, index: 2
    },
    {
        label: t('subscriber.upcoming_payments'), value: state.pending,
        columns: upcomingPaymentColumn, count: state.pendingCount, index: 3
    },
    {
        label: t('transaction.external'), value: state.groupExternalPayments, columns: externalPayments, count: state.groupExternalPayments?.length, paginator: true, index: 4
    }
    ]

    const archiveFooter = () => {
        return (
            <div>
                <Button
                    id="footer-btn-cancel"
                    label={t("cancel")}
                    icon="pi pi-times"
                    onClick={reject}
                />
                <Button
                    id="archive-btn"
                    loading={archiving}
                    label={t("share.archive")}
                    icon="pi pi-check"
                    onClick={() => { accept(); }}
                />
            </div>
        );
    };

    const toggleInvite = () => {
        setVisibleInvite(false)
    }

    const totalNumber = tabs[activeTab].index === 0
        ? state.subscribers.length
        : tabs[activeTab].index === 1
            ? state.collectedCount
            : tabs[activeTab].index === 2
                ? state.missedCount
                : tabs[activeTab].index === 3
                    ? state.pendingCount
                    : tabs[activeTab].index === 4
                        ? state.groupExternalPayments?.length
                        : null

    const onPageChange = (e) => {
        if (e.pageCount <= 1) {
            return;
        }
        else if (tabs[activeTab].index === 0) {
            getPlanSubscribers(planId);
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 1) {
            getPlanPaymentCollected(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 2) {
            getPlanPaymentMissed(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 3) {
            getPlanPaymentPending(planId, { page: e.page, size: state.size });
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else if (tabs[activeTab].index === 4) {
            getGroupExternalPayments(defMembership?.business.id, planId, { page: e.page, size: state.size })
            setState((state) => { return { ...state, page: e.page, first: e.first } })
        }
        else {
            return;
        }
    }

    const loading = fetchingCollected || fetchingPlanSubscribers || fetchingMissed || fetchingPending || fetchingExternalPayments || fetchingPlan;

    const renderVerifFooter = (component) => {
        return (
            <div>
                <Button
                    label={t("common.ok")}
                    icon="pi pi-check"
                    style={{ backgroundColor: "#d0021b" }}
                    autoFocus
                    onClick={
                        component === "not_owner"
                            ? toggleNotOwnerDialogue
                            : toggleVerifPendingDialogue
                    }
                />
            </div>
        );
    };
    const toggleSetOpenDialog = () => { setOpenNotification(!openNotification); };

    const tableClassNameFunc = () => {
        if (tabs[activeTab].index === 1) {
            return 'collectPaymentsTab';
        } else if (tabs[activeTab].index === 2) {
            return 'failedTransactionColumn';
        } else if (tabs[activeTab].index === 3) {
            return 'upcomingPaymentsColumn';
        }
        return 'tier_detail_data_table';
    }

    return (
        <div className='planDetailScreen'>
            <Toast
                onRemove={(message) => {
                    if (message.severity === "success") {
                        setVisible(false);
                    }
                }} ref={toast} />
            <PriceChange
                toggle={togglePriceChange}
                toggleState={state.priceVisible}
                oldPrice={state.cost}
                id={state.priceId}
                onHide={priceOnHide}
                defMembership={defMembership}
                callbackFunctions={() => {
                    getBusiness(defMembership?.business.id)
                    getCredentials(defMembership?.business.id)
                    getPlan(planId);
                    getPlanSubscribers(planId);
                    planPerformance(planId);
                    getPlanPaymentMissed(planId, { size: state.size });
                    getPlanPaymentMissedCount(planId)
                    getPlanPaymentPending(planId, { size: state.size });
                    getPlanPaymentPendingCount(planId)
                    getPlanPaymentCollected(planId, { size: state.size });
                    getPlanPaymentCollectedCount(planId)
                    getGroupExternalPayments(defMembership?.business.id, planId, { size: state.size })
                }}
            />
            <ShareUrl
                visible={sharePlanDialog}
                setShareDialog={setSharePlanDialog}
                sharedTier={state.sharedPlan}
                copying={state.copying}
            />
            <MoveSubscriber
                visible={state.visibleMove}
                onHide={closeMoveSubscriber}
                sourceTier={state.sourceTier}
            />
            <MarkAsPaid displayDialog={displayBasic} onHide={() => { setDisplayBasic(false) }}
                scheduleId={state.id}
                isFlexTrue={state.flex}
                scheduledDate={state.scheduledDate}
                callbackFunctions={() => {
                    getPlan(planId);
                    getPlanSubscribers(planId);
                    planPerformance(planId);
                    getPlanPaymentMissed(planId);
                    getPlanPaymentMissedCount(planId)
                    getPlanPaymentPending(planId);
                    getPlanPaymentPendingCount(planId)
                    getPlanPaymentCollected(planId);
                    getPlanPaymentCollectedCount(planId)
                    getGroupExternalPayments(defMembership?.business.id, planId)
                }}
            />
            <InviteSubscriber visible={visibleInvite} toggleVisible={visibleInvite}
                onHide={toggleInvite} initialTier={{ data: state.plan }}
                callbackFunctions={() => {
                    getBusiness(defMembership?.business.id)
                    getCredentials(defMembership?.business.id)
                    getPlan(planId);
                    getPlanSubscribers(planId);
                    planPerformance(planId);
                    getPlanPaymentMissed(planId, { size: state.size });
                    getPlanPaymentMissedCount(planId)
                    getPlanPaymentPending(planId, { size: state.size });
                    getPlanPaymentPendingCount(planId)
                    getPlanPaymentCollected(planId, { size: state.size });
                    getPlanPaymentCollectedCount(planId)
                    getGroupExternalPayments(defMembership?.business.id, planId, { size: state.size })
                }} />
            <PlanEdit tier={nameChangeVisible} onHide={() => setNameChangeVisible({})} setTrigger={setTrigger} trigger={trigger} />
            <PriceChange
                toggle={togglePriceChange}
                toggleState={state.priceVisible}
                oldPrice={state.cost}
                id={state.priceId}
                onHide={priceOnHide}
                defMembership={defMembership}
            />
            <ShareUrl
                visible={sharePlanDialog}
                setShareDialog={setSharePlanDialog}
                sharedTier={state.sharedPlan}
                copying={state.copying}
            />
            <MoveSubscriber
                visible={state.visibleMove}
                onHide={closeMoveSubscriber}
                sourceTier={state.sourceTier}
            />
            <Dialog
                header={t("products.archive_header_plan")}
                visible={visible}
                onHide={() => setVisible(false)}
                footer={archiveFooter()}
                className="tier_dialog"
                id="tier_dialog"
                style={{ width: "300px" }}
            >
                <div className="archive-dialog-content">
                    <p style={{ display: "flex", justifyContent: "center" }}>
                        <i
                            className="pi pi-exclamation-circle"
                            style={{
                                color: "#D0021B",
                                fontSize: "40px",
                                fontWeight: "bolder",
                            }}
                        />
                    </p>
                    <div style={{ padding: "10px" }}>
                        <div style={{ marginBottom: "0.5rem" }}>
                            <span>
                                {t("products.archive_question_plan", { name: state.plan.name })}{" "}
                                <span style={{ fontWeight: "bold", color: "#000" }}>
                                    {state.rowData?.name}
                                </span>{"?"}{" "}
                            </span>
                        </div>
                        <p className="archive-notice">
                            {t("product.archive_notice")}
                        </p>
                    </div>
                    <hr style={{ border: "1px solid #F2F2F2" }} />
                </div></Dialog>

            <PlanDetailCards  {...{
                state, t, defMembership, setVisible, setVisibleInvite,
                setNameChangeVisible, toggleMoveSubscriber, togglePriceChange, planShare
            }} />
            <Dialog
                header={t("idoc.id_verif_pending_header")}
                visible={state.verifPendingVisible}
                onHide={toggleVerifPendingDialogue}
                style={{ width: "360px" }}
                footer={renderVerifFooter("verif_pending")}
            >
                <span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#ffb304", fontWeight: "bolder" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
            </Dialog>
            <Dialog
                header={t("idoc.not_owner_header")}
                visible={state.notOwnerVisible}
                style={{ width: "360px" }}
                onHide={toggleNotOwnerDialogue}
                footer={renderVerifFooter("not_owner")}
            >
                <p>{t("idoc.not_owner_text")}</p>
            </Dialog>
            <OnboardingNotification
                visible={openNotification}
                onHide={toggleSetOpenDialog} />
            <CustomDataTable
                value={tabs[activeTab].value}
                columns={tabs[activeTab].columns}
                tabs={tabs.map(({ label, i }) => ({ label, i }))}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setState={setState}
                tableClassName={tableClassNameFunc()}
                title="products.plan_activity"
                children={null}
                rows={state.size}
                loading={loading}
                tab={state.tabChange}
            />
            <Paginator
                rows={state.size}
                first={state.first}
                totalRecords={totalNumber}
                onPageChange={onPageChange}
            />
        </div >
    )
}
export default PlanDetail;