import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import CustomOverlayPanel from './CustomOverlayComponent';
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../Components/Loading";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PlanGroupActions from "../../Redux/transition/plan-group/plan-group.reducer";
import PlanActions from "../../Redux/transition/plan/plan.reducer";
import PriceChange from "./priceChange";
import InviteSubscriber from "../Subscriptions/Invitation/InviteSubscriber";
import "../../Styles/pages/product.scss";
import "../../Styles/pages/inviteSubscriber.scss";
import moment from "moment";
import PlanInfoEdit from "./PlanEdit";
import ProductInfoEdit from "./ProductEdit"
import MoveSubscriber from "./MoveSubscribers";
import ShareUrl from "./ShareUrl";
import RoleAccess from "../../Components/RoleAccess";
import SharePlan from "../../Components/SharePlan";
import OnboardingNotification from "../../Components/OnboardingNotification";

const ProductDataTable = (props) => {

  const { doc, checked, filters, loadingTable,
    defMembership, loading, setTrigger, trigger, } = props;

  const archivingPlan = useSelector((state) => state.plans.archiving);
  const archivingPlanSuccess = useSelector((state) => state.plans.archiveSuccess);
  const archivingPlanError = useSelector((state) => state.plans.errorArchiving);
  const errorArchiving = useSelector((state) => state.planGroups.errorArchiving);
  const { duplicating, errorDuplicate, duplicate } = useSelector((state) => state.planGroups);
  const archiving = useSelector((state) => state.planGroups.archiving);
  const successArchiving = useSelector((state) => state.planGroups.archiveSuccess);
  const verificationStatus = useSelector((state) => state.documents.verifStatus);
  const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
  const business = useSelector((state) => state.businesses.business);
  const paymentCredentials = useSelector((state) => state.payswitch.credentials);
  const fetchingPaymentCredentials = useSelector((state) => state.payswitch.fetchingCredentials);

  const dispatch = useDispatch();
  const archivePlanGroup = useCallback((planGroupId, username) => { dispatch(PlanGroupActions.planGroupArchiveRequest(planGroupId, username)); }, [dispatch]);
  const archivePlan = useCallback((planId, membershipId) => { dispatch(PlanActions.planArchiveRequest(planId, membershipId)); }, [dispatch]);
  const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)); }, [dispatch]);
  const duplicatePlan = useCallback((id, name) => { dispatch(PlanGroupActions.planGroupDuplicateRequest(id, name)); }, [dispatch]);
  const getArchivedPlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedArchivedRequest(businessId)) }, [dispatch])

  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const [productInfoVisible, setProductInfoVisible] = useState({})
  const [showProductEditVisible, setShowProductEditVisible] = useState(false)
  const [planInfoVisible, setPlanInfoVisible] = useState({});
  const [reportable, setReportable] = useState(false);
  const op = useRef(null);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [tierArchive, setTierArchive] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [sharePlanDialog, setSharePlanDialog] = useState(false);
  const [reportCode, setReportCode] = useState("");
  const [hideAmount, setHideAmount] = useState(false)
  const [expandedRows, setExpandedRows] = useState(null);
  const [dialogClosed, setDialogClosed] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const [state, setState] = useState({
    documentsSelection: null,
    creatingPlanGroups: null,
    success: false,
    status: "ACTIVE",
    archiving: false,
    setDisplayBasic: false,
    tierVisible: false,
    priceVisible: false,
    cost: null,
    id: null,
    tierGroup: null,
    verificationStatus: null,
    business: null,
    visible: false,
    visiblePlan: false,
    hide: true,
    hidePlan: true,
    notOwnerVisible: false,
    verifPendingVisible: false,
    initialTier: null,
    fetchingVerifRecord: true,
    rowId: null,
    rowData: null,
    archived: false,
    planArchiving: false,
    priceChange: false,
    changing: false,
    visibleMove: false,
    sourceTier: null,
    duplicate: null,
    duplicateName: "",
    shareDialog: false,
    shortUrl: null,
    copying: false,
    sharedPlan: {},
    planName: "",
    sharedTier: {},
  });

  useEffect(() => {
    if (!archivingPlan && state.planArchiving && archivingPlanSuccess) {
      showSuccess({
        message: t("product.planArchive_successful"),
        severity: "success",
      }); setState((state) => ({ ...state, planArchiving: false }));
    }
    if (archivingPlan && state.planArchiving && archivingPlanError) { showError(); setState((state) => ({ ...state, planArchiving: false })); }
    if (!archiving && state.archived && successArchiving) { showSuccess({ message: t("product.archive_successful"), severity: "success", }); setState((state) => ({ ...state, archived: false })); }
    if (archiving && state.archived && errorArchiving) { showError(); setState((state) => ({ ...state, archived: false })); }
    if (!duplicating && state.duplicate && errorDuplicate) { showError(); setState((state) => ({ ...state, duplicate: null, duplicateName: "" })); }
    if (!duplicating && state.duplicate && duplicate) {
      showSuccess({ message: t("product.duplicate_success"), severity: "success", });
      setState((state) => ({ ...state, duplicate: null, duplicateName: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiving, successArchiving, errorArchiving, state.archived, state.planArchiving, archivingPlan, archivingPlanSuccess, archivingPlanError, duplicating, errorDuplicate,]);

  useEffect(() => {
    let loading = fetchingVerifStatus;
    setState((state) => { return { ...state, fetchingVerifRecord: loading }; });
    if (business) { setState((state) => ({ ...state, business: business })); }
    if (verificationStatus) { setState((state) => { return { ...state, verifStatus: verificationStatus }; }) }
  }, [fetchingVerifStatus, business, verificationStatus]);

  const togglePriceChange = (data) => { setState({ ...state, priceVisible: true, cost: data.cost, id: data.id }); };
  const priceOnHide = () => { setState({ ...state, priceVisible: false }); };
  const onHide = () => { setState((state) => { return { ...state, visible: false }; }); };
  const toggleSetOpenDialog = () => { setOpenNotification(!openNotification); };

  //   Toggle Invite Subscriber Dialogue Visibility
  const toogleInviteSubscriber = () => {
    if (fetchingVerifStatus) {
      return <Loading />;
    } else {
      if (business.verified) {
        setState((state) => { return { ...state, visible: true }; });
      } else if (!business.verified) {
        if (state.verifStatus && !state.fetchingVerifRecord) { return toggleVerifPendingDialogue(); }
        if (!state.verifStatus && !state.fetchingVerifRecord) {
          switch (defMembership.role) {
            case "OWNER":
              setOpenNotification(true)
              break;
            default:
              toggleNotOwnerDialogue();
          }
        }
      }
    }
  };

  const toogleShareProduct = () => {
    if (fetchingVerifStatus || fetchingPaymentCredentials) {
      return <Loading />;
    } else {
      if (!business.verified) {
        if (state.verifStatus && !state.fetchingVerifRecord) { return toggleVerifPendingDialogue(); }
        if (!state.verifStatus && !state.fetchingVerifRecord) {
          switch (defMembership.role) {
            case "OWNER":
              setOpenNotification(true);
              break;
            default:
              toggleNotOwnerDialogue();
          }
        }
      }
    }
  };

  //Toggle not Owner dialogue visibility
  const toggleVerifPendingDialogue = () => { setState((state) => { return { ...state, verifPendingVisible: !state.verifPendingVisible }; }); };

  //Toggle not Owner dialogue visibility
  const toggleNotOwnerDialogue = () => { setState((state) => { return { ...state, notOwnerVisible: !state.notOwnerVisible }; }); };

  const closeMoveSubscriber = () => { setState((state) => ({ ...state, visibleMove: false })); };
  const toggleMoveSubscriber = () => { setState((state) => ({ ...state, visibleMove: !state.visibleMove })); };

  //Dialog Footer for verif alert**
  const renderVerifFooter = (component) => {
    return (
      <div>
        <Button
          label={t("common.ok")}
          icon="pi pi-check"
          style={{ backgroundColor: "#d0021b" }}
          autoFocus
          onClick={
            component === "not_owner"
              ? toggleNotOwnerDialogue
              : toggleVerifPendingDialogue
          }
        />
      </div>
    );
  };

  const showSuccess = (data) => {
    toast.current.show({
      severity: "success",
      summary: t("product.success_summary"),
      detail: data.message,
      life: 3000,
    });
  };

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: t("product.error_summary"),
      detail: t("product.archive_error"),
      life: 3000,
    });
  };

  const accept = () => {
    if (tierArchive) {
      setState((state) => ({ ...state, planArchiving: true }));
      archivePlan(state.rowData.id, defMembership.id);
      setTierArchive(false);
    } else {
      setState((state) => ({ ...state, archived: true }));
      archivePlanGroup(state.rowData.id, defMembership.user.login);
    }
  };

  const reject = () => {
    setVisible(false);
    setTierArchive(false);
  };

  const archiveFooter = () => {
    return (
      <div>
        <Button
          id="footer-btn-cancel"
          label={t("cancel")}
          icon="pi pi-times"
          onClick={reject}
        />
        <Button
          id="archive-btn"
          loading={archiving}
          label={t("share.archive")}
          icon="pi pi-check"
          onClick={accept}
        />
      </div>
    );
  };

  const duplicateFooter = () => {
    return (
      <div className="duplicate-footer">
        <Button
          id="footer-btn-cancel"
          label={t("cancel")}
          icon="pi pi-times"
          onClick={() => {
            setState((state) => {
              return { ...state, duplicate: null };
            });
          }}
        />
        <Button
          loading={duplicating}
          style={{ backgroundColor: "#D0021B", fontWeight: "bold" }}
          onClick={() => {
            if (state.duplicate && state.duplicateName) {
              duplicatePlan(state.duplicate, state.duplicateName);
            } else {
              setState((state) => ({ ...state, duplicateName: null }));
            }
          }}
        >
          {t("duplicate")}
        </Button>
      </div>
    );
  };

  const subscriptionBodyTemplate = (rowData) => {
    if (rowData) {
      return (
        <div className="subscription-body">
          {rowData.name ? (
            <span
              className="name-body"
              id="productName"
              onClick={() => {
                navigate(`/products/detail/${rowData.id}`)
              }}
            >
              {" "}
              {rowData.name}{" "}
            </span>
          ) : null}
          {rowData.description ? (
            <span className="name-body" id="planDesc">
              {rowData.description}
            </span>
          ) : null}
          {rowData ? (
            <span className="name-body" id="planNam">
              {rowData.exclusive ? (
                <span className="exclusive">{t("product.exclusive")}</span>
              ) : (
                <span className="non-exclusive">
                  {t("product.non_exclusive")}
                </span>
              )}
            </span>
          ) : null}
        </div>
      );
    }
  };


  const createdOnTemplate = (rowData) => {
    const createdOn = rowData ? moment(rowData.createdOn).format("MMMM DD YYYY") : "";
    const lastModifiedDate = rowData ? moment(rowData.lastModifiedDate).format("MMMM DD YYYY") : "";
    if (rowData) {
      return (
        <div className="subscriptionBodyTemplate">
          {createdOn === lastModifiedDate && <div>
            {createdOn ? (
              <div style={{ display: "flex", textAlign: "left" }}>
                <span className="detail-navigatio" id="createdOn create">
                  {createdOn}
                </span>
              </div>
            )
              : null}
          </div>}

          {createdOn !== lastModifiedDate && <div>
            {createdOn ? (
              <div style={{ display: "flex", textAlign: "left" }}>
                <span className="detail-navigatio" id="createdOn create">
                  {createdOn}
                </span>
              </div>
            ) : null}
            {lastModifiedDate ? (
              <div style={{ paddingTop: "0.4rem" }}>
                <span className="product_date">{t("product.updated_on")}{":"}</span>{" "}
                <span className="productModifiedDate">
                  {lastModifiedDate}
                </span>
              </div>
            ) : null
            }
          </div>
          }
        </div>
      );
    }
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData) {
      return (
        <div className="car-buttons table_action_button">
          <Button
            icon="pi pi-share-alt"
            className={`action-button`}
            tooltip={t("product.share_url")}
            id="action-button"
            onClick={() => {
              if (!business.verified || !paymentCredentials) {
                toogleShareProduct()
              }
              else {
                setSharePlanDialog(true);
                setDialogClosed(false);
                setReportable(rowData.reportable);
                setReportCode(rowData.reportCode);
                setHideAmount(rowData.hideAmount);
                setState((state) => ({
                  ...state,
                  sharedPlan: {
                    shortUrl: rowData.shortUrl,
                    plan: rowData.name,
                    planGroupId: rowData.id,
                    reportable: rowData.reportable,
                    reportCode: rowData.reportCode,
                    hideAmount: rowData.hideAmount,
                  },
                }));
              }
            }}
          />
          <RoleAccess roles={["OWNER", "ADMIN"]}>
            <Button
              icon="pi pi-plus-circle"
              disabled={rowData.status === "ARCHIVED"}
              className={`p-button ${rowData.status !== "ACTIVE"
                ? "action-button"
                : "active-action-button"
                }`}
              id="action-button"
              tooltip={t("products.add_product")}
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                navigate(`/products/plan/new/${rowData.id}`, { state: { planGroup: rowData, plan: true } });
                setState((state) => {
                  return {
                    ...state,
                    duplicate: null,
                    tierGroup: {
                      groupName: rowData.name,
                      groupId: rowData.id,
                      business: rowData.business,
                    },
                  };
                });
              }}
            />
            <Button
              icon="pi pi-pencil"
              disabled={rowData.status === "ARCHIVED"}
              className={`p-button ${rowData.status !== "ACTIVE"
                ? "action-button"
                : "active-action-button"
                }`}
              id="action-button"
              tooltip={t("product.edit_product")}
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                setProductInfoVisible(rowData);
                setState((state) => ({ ...state, rowData }));
              }}
            />
            <Button
              icon="pi pi-copy"
              className={`action-button`}
              tooltip={t("product.duplicate")}
              id="action-button"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                setState((state) => ({
                  ...state,
                  duplicate: rowData.id,
                  rowData,
                }));
              }}
            ></Button>
            <Button
              icon="pi pi-lock"
              disabled={rowData.status === "ARCHIVED"}
              className={`p-button ${rowData.status !== "ACTIVE"
                ? "action-button"
                : "active-action-button"
                }`}
              id="action-button"
              tooltip={
                rowData.status === "ARCHIVED" ? t("Unarchive") : t("Archive")
              }
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setVisible(true);
                setState((state) => ({ ...state, rowData }));
              }}
            />
          </RoleAccess>
        </div>
      );
    }
  };
  const archiveTemplate = (rowData) => {
    if (rowData.status === "ARCHIVED") {
      return (
        <div className="car-buttons archive_container">
          <Button
            icon="pi pi-lock"
            style={{ color: "#FDC237", cursor: "default" }}
            className="p-button action-button archive_icon"
            id="action-button"
          />
        </div>
      );
    }
  };

  const tierActionTemplate = (rowData) => {
    const stockClassName = classNames("border", {
      changePriceDisabled:
        rowData.policy !== "TIER" ||
        (rowData.policy === "TIER" && rowData.flex),
      changePriceDisable: rowData.quantity === "TIER" && rowData.flex,
    });
    if (!checked && rowData.status !== "ARCHIVED") {
      return (
        <div
          className={`car-buttons tier_action_button ${stockClassName}`} >
          {rowData.policy === "SUBSCRIPTION" ||
            rowData.policy === "SCHEDULE" ? (
            <Button
              disabled
              icon="pi pi-share-alt"
              className={`p-button action-button
          ${checked && "tier-disabled"} ${rowData.status === "ARCHIVED" && "tier-archive-disabled"
                } ${(rowData.policy === "SUBSCRIPTION" ||
                  rowData.policy === "SCHEDULE") &&
                "tier-archive-disabled"
                }`}
              tooltip={t("product.share_url")}
              id="disabled_icon"
              onClick={() => {
                if (!business.verified || !paymentCredentials) {
                  toogleShareProduct()
                }
                else {
                  setShareDialog(true);
                  setState((state) => ({
                    ...state,
                    shortUrl: rowData.shortUrl,
                    plan: rowData.name,
                  }));
                }
              }
              }
            />
          ) : (
            <Button
              icon="pi pi-share-alt"
              disabled={checked || rowData.status === "ARCHIVED"}
              className={`p-button action-button
           ${checked && "tier-disabled"} ${rowData.status === "ARCHIVED" && "tier-archive-disabled"
                }`}
              id="action-button"
              tooltip={t("product.share_plan")}
              onClick={() => {
                if (!business.verified || !paymentCredentials) {
                  toogleShareProduct()
                }
                else {
                  setShareDialog(true);
                  setState((state) => ({
                    ...state,
                    sharedTier: {
                      shortUrl: rowData.shortUrl,
                      plan: rowData.name,
                    },
                  }));
                }
              }}
            />
          )}
          <>
            {" "}
            <Button
              icon="pi pi-user-plus"
              disabled={checked || rowData.status === "ARCHIVED"}
              className={`p-button action-button ${checked && "tier-disabled"
                } ${rowData.status === "ARCHIVED" && "tier-archive-disabled"}`}
              id="action-button"
              tooltip={t("product.invite")}
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                toogleInviteSubscriber();
                setState((state) => {
                  return { ...state, initialTier: { data: rowData } };
                });
              }}
            />
            <RoleAccess roles={["OWNER", "ADMIN"]}>
              <Button
                icon={checked ? "pi pi-unlock" : "pi pi-lock"}
                disabled={checked || rowData.status === "ARCHIVED"}
                className={`p-button action-button ${checked && "tier-disabled"
                  } ${rowData.status === "ARCHIVED" && "tier-archive-disabled"}`}
                id="action-button pi-lock-disabled"
                tooltip={t("product.archive")}
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                }}
                onClick={() => {
                  setTierArchive(true);
                  setState((state) => ({ ...state, rowData }));
                }}
              />
            </RoleAccess>
          </>
          <RoleAccess roles={["OWNER", "ADMIN"]}>
            <Button
              type="button"
              disabled={checked || rowData.status === "ARCHIVED"}
              className={`p-button action-button ${checked && "tier-disabled"
                } ${rowData.status === "ARCHIVED" && "tier-archive-disabled"}`}
              id="action-button"
              icon="pi pi-ellipsis-v"
              onClick={(e) => {
                setState((state) => ({ ...state, rowData }));
                op.current.toggle(e);
              }}
            />
          </RoleAccess>
        </div>
      );
    } else {
      return null
    }
  };

  const planInfoTemplate = (rowData) => {
    if (rowData) {
      return (
        <div className="plan-info-main">
          <div style={{ margin: "0.3rem 0rem 0.8rem 0rem" }}>
            {rowData.policy ? (
              <div style={{ paddingBottom: "0.2rem" }}>
                <span id="tierPolicy">
                  {" "}
                  {(rowData.flex === true && rowData.policy === "TIER") && <span id="tierPolicy">{t("plan.flexible_billing")}</span>}
                  {(rowData.flex === false && rowData.policy === "TIER") && <span id="tierPolicy">{t("plan.fixed_billing")}</span>}
                  {(rowData.policy === "SUBSCRIPTION" && rowData.flex === false) && <span id="tierPolicy">{t("plan.subscription_billing")}</span>}
                  {(rowData.policy === "SCHEDULE" && rowData.flex === false) && <span id="tierPolicy">{t("plan.schedule_billing")}</span>}
                </span>
              </div>
            ) : null}
            {rowData ? (
              <div>
                {rowData.policy === "SUBSCRIPTION" || rowData.cost === null ?
                  <div></div> :
                  <div className="plan-amt">
                    {rowData.flex === false && rowData.policy === "TIER" ?
                      <div>
                        {rowData.deductions === null ? <div></div> :
                          <div><span style={{ color: "#000", fontSize: "12px", paddingRight: "0.2rem" }}>{rowData.deductions}</span>
                            <span style={{ color: "#000", fontSize: "12px", paddingRight: "0.3rem" }}>{t("plan.payments_of_text")}</span></div>}
                      </div> : <div></div>}
                    <span className="planCost"> {defMembership.business.currency} {rowData.cost?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>
                }
                <span className="planBillingPeriod"
                > {t(`product.${rowData.billingPeriod.toLowerCase()}`)}</span>
              </div>
            ) : null}
          </div>
          {rowData.setup && <div className="setup_fee_div" id="setup-m-b">
            <span className="setup_fee_tag">{t("common.setup_fee_label")}</span>
            <span className="setup_fee_value">{defMembership.business.currency}{" "}{rowData.setup?.toFixed(2)}</span>
          </div>}
          <div>{rowData.graceDays === null || rowData.graceDays === 0 ? "" :
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: "0.2rem" }}>
              <span className="graceDaysLabel">{t("product.graceDaysLabel")}{":"}</span>
              <span className="planGraceDays">{rowData.graceDays} {" "}{rowData.graceDays ? t("products.day") : null}
              </span>
            </div>}
          </div>
          <div>
            {rowData.reminderDays === null || rowData.reminderDays === 0 ? <div></div> :
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span className="reminderDaysLabel">{t("product.reminderDaysLabel")}{":"}</span>
                <span className="planReminderDays">{rowData.reminderDays}{" "}{rowData.reminderDays ? t("products.day") : null}</span>
              </div>}
          </div>
        </div >
      );
    }
  };

  const tierArchiveTemplate = (rowData) => {
    if (props.checked || rowData.status === "ARCHIVED") {
      return (
        <div
          className="tier-archive-button" >
          <Button
            icon="pi pi-lock"
            style={{ color: "#FDC237", cursor: "default" }}
            className="p-button action-button"
            id="archived_icon"
          />
        </div>
      );
    }
  };

  const datesTemplate = (rowData) => {
    const createdOn = rowData?.createdDate ? moment(rowData?.createdDate).format("MMMM DD YYYY") : "";
    const lastModified = rowData?.lastModifiedDate ? moment(rowData?.lastModifiedDate).format("MMMM DD YYYY") : "";
    if (createdOn === lastModified) {
      return (
        <span className="createdDate">
          {createdOn}
        </span>
      );
    } else if (createdOn !== lastModified) {
      return <>
        <span className="createdDate"> {createdOn}</span>
        <div className="datesMod">
          <span style={{ display: "flex", alignItems: "flex-start" }} className="product_date">{t("product.updated_on")}{":"}</span> &nbsp;
          <span className="productModifiedDate">{lastModified}</span>
        </div>
      </>
    }
  };

  const columns = [
    {
      field: "name",
      body: subscriptionBodyTemplate,
      header: t("product.table_name"),
      className: "name-body",
    },
    {
      field: "createdOn",
      body: createdOnTemplate,
      header: t("product.created"),
      sort: true,
    },
    { field: "customer", body: !checked && actionBodyTemplate },
  ];

  const rowClass = (data) => {
    return {
      archivedTier: data.status === "ARCHIVED",
      changePriceDisabled:
        data.policy !== "TIER" || (data.policy === "TIER" && data.flex),
    };
  };

  const tierNameTemplate = (rowData) => {
    if (rowData) {
      return (
        <div className={rowData.description ? "plan-template" : "plan-no-desc"}>
          <div>
            {rowData.name ? (
              <p
                className="name-body"
                id="plan_name"
                onClick={() => {
                  navigate(`/products/plan/detail/${rowData.id}`)
                }}
              >
                {rowData.name}
              </p>
            ) : null}
          </div>
          <p className="plan-desc">{rowData.description}</p>
        </div>
      );
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <Dialog
          header={t("products.archive_header_plan")}
          visible={tierArchive}
          onHide={() => setTierArchive(false)}
          footer={archiveFooter()}
          className="tier_dialog"
          id="tier_dialog"
          style={{ width: "300px" }}
        >
          <div className="archive-dialog-content">
            <p style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="pi pi-exclamation-circle"
                style={{
                  color: "#D0021B",
                  fontSize: "40px",
                  fontWeight: "bolder",
                }}
              />
            </p>
            <div style={{ padding: "10px" }}>
              <div style={{ marginBottom: "0.5rem" }}>
                <span>
                  {t("products.archive_question_plan")}{" "}
                  <span style={{ fontWeight: "bold", color: "#000" }}>
                    {state.rowData?.name}
                  </span>{"?"}{" "}
                </span>
              </div>
              <p className="archive-notice">
                {t("product.archive_notice")}
              </p>
            </div>
            <hr style={{ border: "1px solid #F2F2F2" }} />
          </div></Dialog>

        <h6
          className="group-name"
          style={{ display: "flex", paddingLeft: "2rem" }}
        >
          {t("products.plans")}
        </h6>
        <DataTable
          id="expanded-table"
          className={`p-datatable-responsive-table expanded `}
          rowClassName={rowClass}
          value={data.tiers}
          responsiveLayout="scroll"
        >
          <Column
            alignHeader="left"
            field="createdOn"
            body={(rowData) => tierArchiveTemplate(rowData)}
            className={`datatable-cell-default ${checked && "archive-cell"} ${!checked && "archive-column"
              } `}
            headerClassName="datatable-header-default header-style"
          ></Column>
          <Column
            alignHeader="left"
            field="name"
            body={(rowData) => tierNameTemplate(rowData)}
            style={{ width: "26.5rem" }}
            filterField="name"
            header={t("product.table_name")}
            className={`datatable-cell-default ${checked && "archive-cell"} ${!checked && "archive-column"
              }`}
            headerClassName="datatable-header-default"
          ></Column>
          <Column
            alignHeader="left"
            field="createdDate"
            header={t("product.created")}
            sortable
            style={{ width: "20rem" }}
            body={(rowData) => datesTemplate(rowData)}
            className={`datatable-cell-default ${checked && "archive-cell"} ${!checked && "archive-column"} `}
            headerClassName="datatable-header-default "
          ></Column >
          <Column
            alignHeader="left"
            field="cost"
            header={t("product.plan_info")}
            sortable
            style={{ width: "20rem" }}
            body={(rowData) => planInfoTemplate(rowData)}
            className={`datatable-cell-default ${checked && "archive-cell"} ${!checked && "archive-column"
              } `}
            headerClassName="datatable-header-default"
          ></Column>

          <Column
            field="createdOn"
            body={(rowData) => { return <div> {tierActionTemplate(rowData)}</div> }}
            className={`datatable-cell-default ${checked && "archive-cell"}  ${!checked && "archive-column"
              }`}
            headerClassName="datatable-header-default"
          ></Column>
        </DataTable >
      </div >
    );
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <OnboardingNotification
            visible={openNotification}
            onHide={toggleSetOpenDialog} />
          <ShareUrl
            visible={shareDialog}
            setShareDialog={setShareDialog}
            sharedTier={state.sharedTier}
            copying={state.copying}
          />
          <SharePlan
            visible={sharePlanDialog}
            setSharePlanDialog={setSharePlanDialog}
            sharedPlan={state.sharedPlan}
            reportable={reportable}
            reportCode={reportCode}
            setReportCode={setReportCode}
            hideAmount={hideAmount}
            dialogClosed={dialogClosed}
            setDialogClosed={setDialogClosed}
          />
          <PlanInfoEdit
            tier={planInfoVisible}
            onHide={() => setPlanInfoVisible({})}
            setTrigger={setTrigger}
            trigger={trigger}
          />
          <ProductInfoEdit
            product={productInfoVisible}
            onHide={() => setProductInfoVisible({})}
            showProductEdit={showProductEditVisible}
            setShowProductEdit={setShowProductEditVisible} />

          <CustomOverlayPanel op={op}>
            <div>
              <ul className="user-profile-items">
                <li>
                  <div
                    onClick={() => {
                      setPlanInfoVisible(state.rowData);
                    }}
                    className="p-link list-button"
                  >
                    <span className="list-items">{t("products.edit_plan_info")}</span>
                  </div>
                </li>
              </ul>
              {state.rowData?.policy === "TIER" && !state.rowData?.flex && (
                <ul className="user-profile-items">
                  <li>
                    <div
                      className="p-link list-button"
                      onClick={() => {
                        togglePriceChange(state.rowData);
                      }}
                    >
                      <span className="list-items">
                        {t("product.change_price_1")}
                      </span>
                    </div>
                  </li>
                </ul>
              )}
              <ul className="user-profile-items">
                <li>
                  <div
                    className="p-link list-button"
                    onClick={() => {
                      toggleMoveSubscriber();
                      setState((state) => {
                        return { ...state, sourceTier: { data: state.rowData } };
                      });
                    }}
                  >
                    <span className="list-items">{t("product.migrate")}</span>
                  </div>
                </li>
              </ul>
            </div>
          </CustomOverlayPanel>

          <PriceChange
            toggle={togglePriceChange}
            toggleState={state.priceVisible}
            oldPrice={state.cost}
            id={state.id}
            onHide={priceOnHide}
            defMembership={defMembership}
          />
          <MoveSubscriber
            visible={state.visibleMove}
            onHide={closeMoveSubscriber}
            sourceTier={state.sourceTier}
          />
          <Dialog
            header={t("product.duplicate")}
            className="tier_dialog"
            id="tier_dialog"
            style={{ width: "350px" }}
            footer={duplicateFooter}
            visible={!!state.duplicate}
            onHide={() => setState((state) => ({ ...state, duplicate: null }))}
          >
            <div
              className="flex flex-column gap-2 mb-4"
              style={{ padding: "10px 10px 0px 10px" }}
            >
              <span style={{ color: "#7D8186" }}>
                {t("product.duplicate_header")}{" "}
                <span style={{ fontWeight: "bold", color: "#000" }}>
                  {state.rowData?.name}
                </span>{" "}
                {t("product.dup_header")}
              </span>
              <hr style={{ border: "2px solid #F2F2F2" }} />
              <label
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  color: "#666678",
                }}
              >
                {t("products.new_name")}
              </label>
              <InputText
                style={{ height: "3rem", borderRadius: "4px" }}
                value={state.duplicateName}
                onChange={(e) =>
                  setState((state) => ({
                    ...state,
                    duplicateName: e.target.value,
                  }))
                }
                maxLength={50}
              />
              {state.duplicateName === null && (
                <span className="p-error">{t("products.validate_error")}</span>
              )}
            </div>
          </Dialog>
          <Dialog
            header={t("idoc.id_verif_pending_header")}
            visible={state.verifPendingVisible}
            onHide={toggleVerifPendingDialogue}
            style={{ width: "360px" }}
            footer={renderVerifFooter("verif_pending")}
          >
            <span>{t("idoc.id_verif_pending_text1")}</span> <span style={{ color: "#ffb304", fontWeight: "bolder" }}>{t("idoc.id_verif_pending_text2")}</span> <span>{t("idoc.id_verif_pending_text3")}</span>
          </Dialog>
          <Dialog
            header={t("idoc.not_owner_header")}
            visible={state.notOwnerVisible}
            style={{ width: "360px" }}
            onHide={toggleNotOwnerDialogue}
            footer={renderVerifFooter("not_owner")}
          >
            <p>{t("idoc.not_owner_text")}</p>
          </Dialog>
          <InviteSubscriber
            visible={state.visible}
            onHide={onHide}
            initialTier={state.initialTier}
            toggleVisible={state.visible}
          />

          <Toast
            ref={toast}
            onRemove={(message) => {
              if (message.severity === "success") {
                getActivePlanGroups(defMembership.business.id);
                getArchivedPlanGroups(defMembership.business.id);
                setVisible(false);
              }
            }}
          />
          <Dialog
            header={t("Archive")}
            visible={visible}
            onHide={() => setVisible(false)}
            footer={archiveFooter()}
            className="tier_dialog"
            id="tier_dialog"
            style={{ width: "350px" }}
          >
            <div className="archive-dialog-content">
              <p style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="pi pi-exclamation-circle"
                  style={{
                    color: "#D0021B",
                    fontSize: "40px",
                    fontWeight: "bolder",
                  }}
                />
              </p>
              <div style={{ padding: "10px" }}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <span>
                    {t("product.archive_question")}{" "}
                    <span style={{ fontWeight: "bold", color: "#000" }}>
                      {state.rowData?.name}
                    </span>{" "}{"?"}{" "}
                  </span>
                </div>
                <p className="archive-notice">
                  {t("product.archive_notice")}
                </p>
              </div>
              <hr style={{ border: "1px solid #F2F2F2" }} />
            </div>
          </Dialog>
          <div>
            <DataTable
              id="data_table"
              value={doc}
              filters={filters}
              globalFilterFields={[
                "name",
                "country.name",
                "status",
                "tierObject.tierName",
                "billingObject",
                "planName",
                "billingPeriod",
                "newPlan.billingPeriod",
              ]}
              emptyMessage={t("product.not_found")}
              className="p-datatable-responsive-table transactions-table"
              responsiveLayout="stack"
              breakpoint="1050px"
              paginator
              rows={10}
              dataKey="id"
              loading={loadingTable}
              expandedRows={expandedRows}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
            >
              <Column
                field="archive"
                body={archiveTemplate}
                className={`datatable-cell-default ${checked && "archive-cell"
                  } archive-icon`}
                headerClassName="datatable-header-default header-title "
              />
              <Column
                headerClassName="datatable-header-default header-title"
                expander
                className={`datatable-cell-default ${checked && "archive-cell"
                  } chevron table_dropdown`}
              />
              {columns.map(
                ({ field, body, header, sort, style, className = "" }, i) => (
                  <Column
                    key={i}
                    field={field}
                    body={body}
                    header={header}
                    alignHeader="left"
                    className={`datatable-cell-default ${checked && "archive-cell"
                      } ${className}`}
                    headerClassName={"datatable-header-default header-title "}
                    style={{ ...style }}
                    sortable={sort}
                  />
                )
              )}
            </DataTable>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDataTable;