import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client"
import AppRoot from "./AppRoot";
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './Components/ErrorFallback';
import history from "./Service/history";
import {store} from "./Redux/store"
import "core-js/stable";
import "regenerator-runtime/runtime";
import "prismjs/themes/prism-coy.css";
import "./Config/i18n";
import "primeflex/primeflex.css"

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";                                       
        
        
    



const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.returnTo ? appState.returnTo : window.location.pathname
	);
};

const providerConfig = {
	domain: process.env.REACT_APP_AUTH_DOMAIN,
	clientId: process.env.REACT_APP_AUTH_CLIENTID,
	onRedirectCallback,
	authorizationParams: {
		// redirect_uri: process.env.REACT_APP_AUTH_LOGOUTURL,
		redirect_uri: process.env.REACT_APP_AUTH_REDIRECTURI,
		audience: process.env.REACT_APP_AUTH_AUDIENCE
	},
};

// Get the root DOM element (where the app will be mounted)
const rootElement = document.getElementById('root');

// Create a root and render the application
const root = ReactDOM.createRoot(rootElement);

root.render(
	<Provider store={store}>
	<Auth0Provider {...providerConfig}>
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<AppRoot />
		</ErrorBoundary>
	</Auth0Provider>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
