import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defMembershipCall } from '../../calls';
import SettlementActions from "../../../../Redux/transition/settlement/settlement.reducer";
import WalletActions from "../../../../Redux/transition/wallet/wallet.reducer"
import { Toast } from "primereact/toast";
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';

function Confirmation({ Recipient, handlePrevious, handleCancel, setActiveIndex, settlement }) {
    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);

    const [state, setState] = useState({
        creating: false,
        walletRecord: {},
        settlementStructure: {}
    })

    const wallet = useSelector((state) => state.wallet.wallet);
    const creating = useSelector((state) => state.settlements.creating);
    const errorCreating = useSelector((state) => state.settlements.errorCreating);
    const settlementRequest = useSelector((state) => state.settlements.settlementRequest);
    const structureSettlement = useSelector((state) => state.settlements.structureSettlement);

    const dispatch = useDispatch();
    const createSettlement = useCallback((settlementRequest) => dispatch(SettlementActions.settlementCreateRequest(settlementRequest)), [dispatch]);
    const getWallet = useCallback((businessId) => { dispatch(WalletActions.walletRequest(businessId)) }, [dispatch]);
    const getSettlementStructure = useCallback((countryId) => dispatch(SettlementActions.structureSettlementRequest(countryId)), [dispatch])

    const { defMembership } = defMembershipCall(dispatch, useSelector, useCallback);
    const business = defMembership ? defMembership.business : {};
    const params = new URLSearchParams(location.search);
    const payoutChannelId = params.get('payoutChannelId')

    useEffect(() => {
        if (defMembership) {
            getSettlementStructure(defMembership.business.country.id)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (wallet) {
            setState((state) => { return { ...state, walletRecord: wallet } })
        }
        if (structureSettlement) {
            setState((state) => { return { ...state, structureSettlement } })
        }
        if (!creating && settlementRequest && state.creating) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('confirmed_message'), name: "final", life: 2000 });
            setState((s) => {
                return { ...s, creating: false };
            });
        }
        if (!creating && errorCreating && state.creating) {
            toast.current.show({ severity: 'error', summary: t('Error'), detail: t('rejected_message') });
            setState((s) => {
                return { ...s, creating: false };
            });
        }
    }, [creating, settlementRequest, state.creating, errorCreating, t, wallet, structureSettlement]);

    const accept = (reset = settlement.actions, totalTransfers = settlement.data, totalAmount = parseFloat(settlement.totalAmount)) => {
        let sources = [];
        totalTransfers.forEach(selection => {
            let source = { groupId: selection.planGroup.groupId, deduction: selection.amount };
            sources.push(source);
        });

        const settlementRequestExisting = {
            wallet: state.walletRecord,
            channel: Recipient,
            sources: sources,
            total: totalAmount,
            requestedBy: defMembership.user
        }

        const settlementRequestNew = {
            wallet: state.walletRecord,
            payoutName: Recipient.name,
            payoutIdentifier: Recipient.identifier,
            payoutSysBank: Recipient.systemBank,
            payoutType: Recipient.systemBank.type,
            payoutTemporary: Recipient.temporary,
            addedBy: Recipient.addedBy,
            sources: sources,
            total: totalAmount,
            requestedBy: defMembership.user
        }
        createSettlement(Recipient.id ? settlementRequestExisting : settlementRequestNew);
        setState((s) => {
            return { ...s, creating: true };
        });
        toast.current.show({ severity: 'info', summary: t('creating'), detail: t('creating_message'), life: 2000 });
    }
    const { name = " ", identifier = " ", systemBank, channelName = " " } = Recipient || {};

    return (
        <>
            <Toast ref={toast} onRemove={(message) => {
                if (message?.name === 'create') {
                    getWallet(business.id);
                }
                if (message?.name === 'final') {
                    setState({ ...state, requesting: false });
                    navigate("/money/withdrawals")
                }
            }} >
            </Toast>
            <h2 className="payout_channel-title">{t("totalTransfers.confirm_title")}</h2>
            <div className='confirmation'>
                <div className="recipient">
                    <h2 className="recipient-title">{t("recipient.details")}</h2>
                    <div className="recipient-brand">
                        {systemBank ? <img src={systemBank?.logoUrl} className="icon" alt="" /> : <Skeleton className="icon" shape="circle" size="75px"></Skeleton>}
                        <p className="channel">{channelName}</p>
                    </div>
                    <div className="recipient-info">
                        <p className="num">{identifier}</p>
                        <p className="name">{name}</p>
                    </div>
                </div>
                <div className="transferAmount">
                    <h2 className="recipient-title">{t("totalTransfers.amount_title")}</h2>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span id="transferTotalAmount">{settlement.getTotal}</span>
                        <hr style={{ backgroundColor: "#CED4DA", height: "2px", marginTop: "0rem", marginBottom: "0rem" }} />
                        {(Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && <span className='fee_span'>{structureSettlement.country.currency}{" "}{structureSettlement.bankFee}</span>}
                        {((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && parseInt(settlement.totalAmount) <= structureSettlement.mobileCap) &&
                            <span className='fee_span'>{structureSettlement.country.currency}{" "}{settlement.totalAmount * 0.01}</span>}
                        {((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && parseInt(settlement.totalAmount) > structureSettlement.mobileCap) &&
                            <span className='fee_span'>{structureSettlement.country.currency}{" "}{structureSettlement.mobileFeeCap}</span>}
                    </div>
                </div>

                <div className="payout_channel-buttonsBar">
                    <Button className="payout_channel-buttonsBar-reset" onClick={handlePrevious} >{t("Back")}</Button>
                    <Button
                        className="payout_channel-buttonsBar-cancel-button"
                        type="reset"
                        onClick={() => { payoutChannelId ? navigate(-1) : handleCancel(); }}
                        label={t("cancel")} />
                    <Button
                        className="p-button-info payout_channel-buttonsBar-continue-button"
                        type="button"
                        loading={creating}
                        disabled={creating}
                        onClick={() => { accept() }}
                        label={t("totalTransfers.send_money")} />
                </div>
            </div >
        </>
    )

}
export default Confirmation;