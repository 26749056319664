import { Calendar } from "primereact/calendar";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import "../../Styles/pages/transactions.scss";
import StatusBadge from "../../Components/StatusBadge";

export const StatusTemplate = ({ rowData, t }) => {
    if (rowData.status === 0) {
        return "";
    } else {
        if (rowData.status === "PROCESSED") {
            return <StatusBadge className="mr-2 pills-transactions-completed" status={t("PROCESSED")} ></StatusBadge>
        }
        else if (rowData.status === "PENDING") {
            return <StatusBadge className="mr-2 pills-transactions-pending" status={t("PENDING")} ></StatusBadge>
        }
        else if (rowData.status === "REVERSED") {
            return <div className="status">
                {
                    <span className={`product-badge status-${(rowData.status ? rowData.status.toLowerCase() : '')}`}>{rowData.status}
                    </span>
                }
                {
                    <span style={{ fontSize: "12px" }} className="statusBodyTemplate">
                        {ellipsisString(rowData.reversalReason, 20)}
                    </span>
                }
            </div>
        }
    }
}


function ellipsisString(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.substring(0, maxLength) + '...';
    }
}
export const referenceTemplate = (rowData) => {
    if (rowData !== null) {
        return <div >
            <span className="trans-normal-text">{rowData.reference === null ? "--" : rowData.reference}				</span>
        </div>
    }
    return "--"
}
export const AmountTemplate = ({ rowData, defMembership }) => {
    if (rowData.requested) {
        return <span className="trans-normal-text">{`${defMembership.business.country.currency} ${rowData.requested.toFixed(2)}`}</span>;
    } else {
        return "--"
    }
}
export const channelBodyTemplate = (rowData) => {
    if (rowData) {
        return <div className="main-cannel">
            <div className="channel-image">
                {rowData.channel ?
                    <img alt="channel logo" src={rowData.channel.bankLogoUrl} width='50rem' height='40rem' /> : null
                }
            </div>
            <div className="channel-info" >
                <span className="bold-info" id="channel-bold-info">
                    {rowData.channel ? rowData.channel.bankName : "--"}
                </span>
                <span className="normal-info" id="channel-normal-info">
                    {rowData.channel ? rowData.channel.type : "--"}
                </span>
            </div>
        </div >
    }
}
export const CustomerBodyTemplate = ({ rowData }) => {
    return <div className="customerBodyTemplate">
        {rowData.channel ?
            <span className="bold-info-receive" id="">{rowData.channel !== null ? rowData.channel.channelName : "--"}</span> : null
        }
        {rowData.channel ?
            <span className="normal-info" id="normal-info">{rowData.channel !== null ? rowData.channel.identifier : "--"}</span> : null
        }
    </div>
}
export const requestedByTemplate = (rowData) => {
    return <div className="customerBodyTemplate">
        {rowData.requestedBy ?
            <span className="bold-info" id="">{rowData.requestedBy.name === (null || '') ? rowData.requestedBy.login : rowData.requestedBy.name}</span> : null
        }
        {rowData.requestedBy ?
            <span id="normal-info">{rowData.requestedBy !== null ? rowData.requestedBy.login : "--"}</span> : null
        }
    </div>
}
export const RequestedOnBodyTemplate = ({ rowData, t }) => (
    <div className="customerBodyTemplate">
        {rowData.requestedOn !== null ? <span className="trans-normal-text" id="">
            <strong>{t("transaction.requested_on")} : </strong>
            {moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:mm")}
        </span> : "--"}
        {rowData.processedOn !== null && <span className="trans-normal-text" id="">
            <strong>{t("transaction.processed_on")} : </strong>
            {moment(rowData.processedOn).format("ddd, MMM D, YYYY HH:mm")}
        </span>}
    </div>
)
export const DateFilterTemplate = ({ control, setBodyFun, dateTypes, t, comparisonOperators, dateComparisonOperators, handleSubmit, setAddedActivity, updateKeyValuePair }) => {

    const { fields } = useFieldArray({
        control,
        name: "filters",
    });

    const handleFormSubmit = (data) => {
        if (data.filters[0].dateType !== undefined && data.filters[0].operator !== undefined && data.filters[0].date !== undefined) {
            updateKeyValuePair('activityFilter', true)
            setBodyFun(
                data.filters.reduce(
                    (prv, { dateType, operator, date }) => ({
                        ...prv,
                        [dateType]: { operator, date },
                    }),
                    {}
                )
            );
            setAddedActivity(true)
        }
    };

    return (
        <>
            {fields.map((fieldD, index) => (
                <form className="p-fluid" key={fieldD.id}>
                    <div className="field">
                        <Controller
                            name={`filters.${index}.dateType`}
                            control={control}
                            render={({ field }) => {
                                return <Dropdown
                                    id={field.name} value={field.value}
                                    onChange={(e) => field.onChange(e.value)}
                                    options={dateTypes} placeholder={t("report.type")} />;
                            }} />
                    </div>
                    <div className="field">
                        <Controller
                            name={`filters.${index}.operator`}
                            control={control}
                            render={({ field }) => <Dropdown
                                id={field.name} value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                                options={dateComparisonOperators} placeholder={t("report.mode")} />}
                        />
                    </div>

                    <div className="field">
                        <Controller
                            name={`filters.${index}.date`}
                            control={control}
                            render={({ field }) => <Calendar
                                id={field.name}
                                placeholder={t("date")}
                                value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                                dateFormat="dd/mm/yy" mask="99/99/9999" showIcon
                            />}
                        />
                    </div>
                </form>
            ))}
            <div className="p-column-filter-buttonbar ml-0 mt-2">
                <Button label={t("report.apply")} className="p-button p-component p-button-sm" onClick={handleSubmit(handleFormSubmit)} />
            </div>
        </>
    );

}

export function StatusFilterTemplate({ filterSubscribersItems, t, filter, setFilterFun, setAddedStatus }) {
    return <div className="field">
        <MultiSelect
            placeholder={t("status")}
            value={filter.statuses}
            showClear options={filterSubscribersItems}
            itemTemplate={({ label, value }) => <span className={`product-badge status-${label.toLowerCase()}`}>{value}</span>}
            onChange={(e) => {
                if (e.value) {
                    setAddedStatus(true)
                }
                else {
                    setAddedStatus(false)
                }
                setFilterFun({ statuses: e.value });
            }}
            className="p-column-filter"
        />
    </div>
}
export const BalanceFilterTemplate = ({ defMembership, comparisonOperators, t, filter, setFilterSubFun, setAddedAmount, setAddedAmountOperator }) => {
    return (
        <form className="p-fluid">
            <div className="field">
                <Dropdown
                    value={filter?.requested?.operator || null}
                    onChange={(e) => {
                        setFilterSubFun("requested", { operator: e.value })
                        if (e.value) {
                            setAddedAmount(true)
                        }
                        else {
                            setAddedAmount(false)
                        }
                    }}
                    options={comparisonOperators} placeholder={t("report.mode")} />
            </div>

            <div className="field">
                <label className="report_amount_label">{t("amount")}</label>
                <InputNumber
                    value={filter?.requested?.amount || 0}
                    onChange={(e) => {
                        setFilterSubFun("requested", { amount: e.value })
                        if (e.value) {
                            setAddedAmountOperator(true)
                        }
                        else {
                            setAddedAmountOperator(false)
                        }
                    }}
                    mode="currency" currency={defMembership.business.country.currency}
                    locale="en-US" placeholder={t("amount")} />
            </div>
        </form >
    );
};

export function AccountFilterTemplate({ state, setState, t, filter, setFilterFun, setAddedRecipient, identifiers }) {

    const uniqueIdentifiers = new Set();

    // Extract channel objects from array2 and add them to array1
    const mergedArray = state.payoutChannelAll.concat(state.payoutChannels.filter(item => {
        if (uniqueIdentifiers.has(item.channel?.identifier)) {
            return false; // Skip if identifier is already encountered
        }
        uniqueIdentifiers.add(item.channel?.identifier);
        return (item.channel?.channelName?.trim() === '' || item.channel?.channelName === undefined);
    }).map(item => item.channel));

    const newArray = mergedArray.map((item, index, array) => {
        const words = item.channelName.split(/\s+/).filter(word => word.length >= 3);

        // Create variables for up to four words
        const newName1 = words[0] || ''; // If there's no word at index 0, use an empty string
        const newName2 = words[1] || '';
        const newName3 = words[2] || '';
        const newName4 = words[3] || '';

        // Check for duplicate identifiers
        const duplicateIndex = array.findIndex((el, idx) => idx !== index && el.identifier === item.identifier);
        if (duplicateIndex !== -1 && words.length === 0) {
            // If current item has empty string or whitespace for name, and there's a duplicate identifier, replace name with identifier
            item.channelName = array[duplicateIndex].identifier;
        }

        return {
            name: item.channelName,
            newName1: newName1,
            newName2: newName2,
            newName3: newName3,
            newName4: newName4,
            identifier: item.identifier,
            id: item.id
        };
    });

    const searchName = (event) => {
        let filteredAccountName = [];

        if (!event.query.trim().length) {
            filteredAccountName = newArray;
        } else {
            filteredAccountName = newArray.filter((acc) => {
                return acc.name.toLowerCase().startsWith(event.query.toLowerCase())
                    || `${acc.newName2}`.toLowerCase().startsWith(event.query.toLowerCase())
                    || `${acc.newName3}`.toLowerCase().startsWith(event.query.toLowerCase())
                    || `${acc.newName4}`.toLowerCase().startsWith(event.query.toLowerCase())
                    || `${acc.identifier}`.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }

        const allNamesEmpty = filteredAccountName.every((acc) => acc.name.trim() === '');
        setState({ ...state, filteredAccountName, allNamesEmpty });
    };

    return (
        <>
            <div className="field">
                <label className="report_account_name_label">{t("report.account_name")}</label>
                <AutoComplete
                    style={{ height: "2.7rem" }}
                    multiple
                    field={!state.allNamesEmpty ? "name" : "identifier"}
                    value={filter.channelIds}
                    suggestions={state.filteredAccountName}
                    completeMethod={searchName}
                    onChange={(e) => {
                        if (e.value) {
                            setAddedRecipient(true)
                        }
                        else {
                            setAddedRecipient(false)
                        }
                        setFilterFun({ channelIds: e.value })
                    }
                    } />
            </div>
        </>
    );
}

export function ChannelFilterTemplate({ state, t, filter, setFilterFun, setAddedChannelType, setAddedSystemBank }) {
    return <>
        <div className="field">
            <Dropdown
                value={filter.type}
                onChange={(e) => {
                    if (e.value) {
                        setAddedChannelType(true)
                    }
                    else {
                        setAddedChannelType(false)
                    }
                    setFilterFun({ type: e.value })
                }}
                options={state.channelTypes}
                placeholder={t("report.channel_type")} />
        </div>
        <div className="field">
            <MultiSelect
                showClear
                options={state.channels.filter(({ type }) => type === filter?.type)}
                value={filter.sysBankIds}
                onChange={(e) => {
                    if (e.value) {
                        setAddedSystemBank(true)
                    }
                    else {
                        setAddedSystemBank(false)
                    }

                    setFilterFun({ sysBankIds: e.value })
                }}
                className="p-column-filter"
                placeholder={t("report.channel")} />
        </div>
    </>;
}

export function RequestedByFilterTemplate({ state, setState, t, filter, setFilterFun, setAddedRequested }) {
    const searchName = (event) => {
        let filteredMemberships = [];
        if (event.query.trim().length >= 3) {
            filteredMemberships = state.memberships.filter((user) => {
                return user.name.toLowerCase().startsWith(event.query.toLowerCase())
                    ||
                    user.lastName.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setState({ ...state, filteredMemberships });
    };
    return (
        <div className="field">
            <label className="report_name_label">{t("name")}</label>
            <AutoComplete
                style={{ height: "2.7rem" }}
                multiple
                field="name"
                value={filter.requestedByIds || []}
                suggestions={state.filteredMemberships}
                completeMethod={searchName}
                onChange={(e) => {
                    if (e.value.length >= 1) {
                        setAddedRequested(true)
                    }
                    else {
                        setAddedRequested(false)
                    }
                    setFilterFun({ requestedByIds: e.value });
                }} />
        </div>
    );
}
export function ReferenceFilterTemplate({ t, filter, setFilterFun, setAddedReference }) {
    return (
        <div className="field">
            <InputText
                placeholder={t("transaction.reference")}
                id={"referenceFilter"}
                value={filter.reference}
                onChange={(e) => {
                    if (e.target.value) {
                        setAddedReference(true)

                    } else {
                        setAddedReference(false)

                    }
                    setFilterFun({ reference: e.target.value })
                }}
                maxLength={100} />
        </div>
    );
}

export function RequestedFilterTemplate({ state, setState, t, setQuery, query, setId, Button, selected, setSelected }) {
    const searchName = (event) => {
        let filteredMemberships = [];
        if (event.query.trim().length >= 3) {
            filteredMemberships = state.payoutChannelAll.filter((user) => {
                return user.name.toLowerCase().startsWith(event.query.toLowerCase())
                    ||
                    user.identifier.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setState({ ...state, filteredMemberships });
    };
    const searchSelected = () => {
        setState({ ...state, page: 0, first: 0 })
        setId(selected.id)
    }
    const clearSelected = () => {
        setId([])
        setQuery([])
        setSelected([])
        setState({ ...state, page: 0, first: 0, filteredMemberships: [] })
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="field">
                <span style={{ display: "flex", flexDirection: "row" }}>
                    <AutoComplete field="name"
                        id="customer_search"
                        placeholder={t("transaction.filter_by")}
                        style={{ border: "1px solid grey" }}
                        value={query}
                        suggestions={state.filteredMemberships}
                        completeMethod={searchName}
                        onSelect={(e) => setSelected(e.value)}
                        onChange={(e) => {
                            setQuery(e.value)
                        }
                        }
                    />
                    {
                        ((query.length > 0) || (state.filteredMemberships?.length > 0)) &&
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button id="customersCancelBtn" type="submit" onClick={clearSelected} icon="pi pi-times" className="p-button-danger buttons" />
                            <Button id="customersSearchBtn" type="submit" onClick={searchSelected} icon="pi pi-search" className="p-button-danger buttons" />
                        </div>
                    }
                </span>
            </div>
        </div>
        // </div>
    );
}