import Dashboard from "../Pages/Dashboard";
import Products from "../Pages/Products";
import ProductDetail from "../Pages/Products/ProductDetail";
import ProductPlanDetail from "../Pages/Products/PlanDetail";
import PlanCreate from "../Pages/Products/PlanCreate";
// import { EditRecipient } from "../Pages/Recipients/Edit";
import Colleagues from "../Pages/Colleagues";
import ColleagueDetails from "../Pages/Colleagues/ColleagueDetails";
import Transactions from '../Pages/Money/Transactions';
import TransactionDetailScreen from "../Pages/Money/Transactions/TransactionDetailScreen";
import ProfilePage from "../Pages/User/Profile";
import ProfilePageEdit from "../Pages/User/ProfileEdit";
import Activity from "../Pages/Subscriptions/Activity"
import Invitations from "../Pages/Subscriptions/Invitation"
// import Subscribers from "../Pages/Subscriptions/Subscribers"
import SubscriptionsDetail from "../Pages/Subscriptions/Subscribers/Detail";
import Customers from "../Pages/Customers/index";
import Settings from "../Pages/Settings"
// import Document from "../Pages/IdentityDocs-Upload";
import BusinessCustomerDetail from "../Pages/Customers/detail";
import SavedRecipients from "../Pages/Money/SavedRecipients";
import { SendMoney } from '../Pages/Money/SendMoney';
import ProductAccounts from '../Pages/Money/ProductAccounts';
import Subscription from '../Pages/Reports/subscriptions/subscriptionSummary'
import Withdrawal from '../Pages/Reports/withdrawals/transactionSummary'
import CustomSubscription from '../Pages/Reports/SubscriptionCustom'
import CustomWithdrawal from '../Pages/Reports/WithdrawalsCustom';
import PaymentDetail from "../Pages/Payment/Detail";
import WithdrawalList from '../Pages/Money/ProductAccounts/WithdrawalList';
import Payments from "../Pages/Payment";
import ShareBusiness from "../Pages/Business/ShareBusiness";
import BusinessPosterV1 from '../Pages/Business/PosterV1';


export const componentRoutes = [
	// commented routes might be later used 

	{ path: '/home', component: <Dashboard/>, breadcrumb: 'breadcrumbs.dashboard' },
	// { path: '/verification', component: EmailVerification, breadcrumb: 'Email Verification' },
	{ path: '/user/profile', component: <ProfilePage/>, breadcrumb: 'breadcrumbs.user_profile' },
	{ path: '/user/profile/edit', component: <ProfilePageEdit/>, breadcrumb: 'breadcrumbs.user_edit' },
	{ path: '/products', component: <Products/>, breadcrumb: 'breadcrumbs.products', breadCrumbPath: "breadcrumbs.products" },
	{ path: '/products/detail/:id', component: <ProductDetail/>, breadcrumb: 'breadcrumbs.product_detail', breadCrumbPath: "breadcrumbs.product_detail" },
	{ path: '/products/plan/detail/:id', component: <ProductPlanDetail/>, breadcrumb: 'breadcrumbs.plan_detail' },
	{ path: '/products/plan/new/:id', component: <PlanCreate/>, breadcrumb: 'breadcrumbs.plan_create', breadCrumbPath: "breadcrumbs.plan_create" },
	{ path: '/subscriptions/activity', component: <Activity/>, breadcrumb: 'breadcrumbs.subs_activity' },
	{ path: '/invitations', component: <Invitations/>, breadcrumb: 'breadcrumbs.invitations' },
	{ path: '/subscriptions/invitations', component: <Invitations/>, breadcrumb: 'breadcrumbs.subs_invitations' },
	// { path: '/subscriptions/subscribers', component: Subscribers, breadcrumb: 'Subscriptions/ Subscribers' },
	// { path: '/subscriptions/detail/:id', component: SubscriptionsDetail, breadcrumb: 'breadcrumbs.subs_detail' },
	{ path: '/subscribers/subscriber/subscription/:id', component: <SubscriptionsDetail/>, breadcrumb: 'breadcrumbs.subs_detail' },
	{ path: '/subscriptions/subscribers', component: <Customers/>, breadcrumb: 'breadcrumbs.customers' },
	// { path: '/recipients/create', component: EditRecipient, breadcrumb: 'Create Recipient' },
	// { path: '/recipients/edit', component: EditRecipient, breadcrumb: 'Edit Recipient' },
	{ path: '/reports/subscriptions/snapshot', component: <Subscription/>, breadcrumb: 'breadcrumbs.subs_report' },
	{ path: '/reports/withdrawals/snapshot', component: <Withdrawal/>, breadcrumb: 'breadcrumbs.withdrawal_reports' },
	{ path: '/reports/subscription/custom', component: <CustomSubscription/>, breadcrumb: 'breadcrumbs.subs_report_custom' },
	{ path: '/reports/withdrawals/custom', component: <CustomWithdrawal/>, breadcrumb: 'breadcrumbs.subs_report_withdrawal' },
	{ path: '/money/send', component: <SendMoney/>, breadcrumb: 'breadcrumbs.send_money' },
	{ path: '/money/withdrawals', component: <Transactions/>, breadcrumb: 'breadcrumbs.withdrawals' },
	{ path: '/money/product_accounts', component: <ProductAccounts/>, breadcrumb: 'breadcrumbs.product_accounts' },
	{ path: '/money/saved_recipients', component: <SavedRecipients/>, breadcrumb: 'breadcrumbs.saved_recipients' },
	{ path: '/withdrawals/detail/:id', component: <TransactionDetailScreen/>, breadcrumb: 'breadcrumbs.withdrawal_details' },
	{ path: '/settings', component: <Settings/>, breadcrumb: 'breadcrumbs.settings' },
	// { path: '/settings/verify-business', component: Document, breadcrumb: 'breadcrumbs.business_verification' },
	{ path: '/subscribers', component: <Customers/>, breadcrumb: 'breadcrumbs.customers' },
	{ path: '/subscribers/subscriber/:id', component: <BusinessCustomerDetail/>, breadcrumb: 'breadcrumbs.customers_details' },
	{ path: '/teams', component: <Colleagues/>, breadcrumb: 'breadcrumbs.colleagues' },
	{ path: '/team/:id', component: <ColleagueDetails/>, breadcrumb: 'breadcrumbs.colleague_details' },
	{ path: '/payments/detail/:id', component: <PaymentDetail/>, breadcrumb: 'breadcrumbs.payment_details' },
	{ path: '/payments', component: <Payments/>, breadcrumb: 'breadcrumbs.payments' },
	{ path: '/money/product_accounts/withdrawalsList', component: <WithdrawalList/>, breadcrumb: 'breadcrumbs.money_withdrawals' },
	{ path: '/share/business', component: <ShareBusiness/>, breadcrumb: 'breadcrumbs.share_business' },
	{ path: '/business/qrCode/posterV1', component: <BusinessPosterV1/>, breadcrumb: 'breadcrumbs.posterV1' }
]



export const enterpriseLockedRoutes = [
	{ path: '/money/send', component: <SendMoney/>, breadcrumb: 'breadcrumbs.send_money' },
	{ path: '/money/withdrawals', component: <Transactions/>, breadcrumb: 'breadcrumbs.withdrawals' },
	{ path: '/money/product_accounts', component: <ProductAccounts/>, breadcrumb: 'breadcrumbs.product_accounts' },
	{ path: '/money/saved_recipients', component: <SavedRecipients/>, breadcrumb: 'breadcrumbs.saved_recipients' },
]

export const notAdminEntLockedRoutes = [
	{ path: '/home', component: <Dashboard/>, breadcrumb: 'breadcrumbs.dashboard' },
]

export const lockedRoutes = [
	{ path: '/team', component: <Colleagues/>, breadcrumb: 'breadcrumbs.colleagues' },
	{ path: '/team/:id', component: <ColleagueDetails/>, breadcrumb: 'breadcrumbs.colleague_details' },
	{ path: '/money/send', component: <SendMoney/>, breadcrumb: 'breadcrumbs.send_money' },
	{ path: '/money/withdrawals', component: <Transactions/>, breadcrumb: 'breadcrumbs.withdrawals' },
	{ path: '/money/product_accounts', component: <ProductAccounts/>, breadcrumb: 'breadcrumbs.product_accounts' },
	{ path: '/money/saved_recipients', component: <SavedRecipients/>, breadcrumb: 'breadcrumbs.saved_recipients' },
	{ path: '/reports/subscriptions/snapshot', component: <Subscription/>, breadcrumb: 'breadcrumbs.subs_report' },
	{ path: '/reports/withdrawals/snapshot', component: <Withdrawal/>, breadcrumb: 'breadcrumbs.withdrawal_reports' },
	{ path: '/reports/subscription/custom', component: <CustomSubscription/>, breadcrumb: 'breadcrumbs.subs_report_custom' },
	{ path: '/reports/withdrawals/custom', component: <CustomWithdrawal/>, breadcrumb: 'breadcrumbs.subs_report_withdrawal' },
]
export const permittedRoleList = ['ADMIN', 'OWNER'];