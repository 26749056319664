/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//Redux imports
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
//Component import
import Loading from "../../../Components/Loading";
import Summary from "../index";
//Prime react import
import { Toast } from "primereact/toast";
import "primeicons/primeicons.css";
//other imports
import "../../../Styles/pages/transactions.scss";
import ProductAccountsTable from "./ProductAccountsTable";
import AddProductAccountDialog from "./AddProductAccountDialog";

function ProductAccount() {
    const toast = useRef(null);
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const [favouritedWallets, setFavouritedWallets] = useState(null);

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroups = useSelector((state) => state.planGroups.planGroups);
    const planGroupsCount = useSelector((state) => state.planGroups.count);
    const fetchingPlanGroupsCount = useSelector((state) => state.planGroups.fetchingPendingCount);
    const fetchingPlanGroups = useSelector((state) => state.planGroups.fetchingAll);
    const fetchingWallet = useSelector((state) => state.wallet.fetchingOne);
    const fetchingPendingCount = useSelector((state) => state.settlements.fetchingStatusCount);
    const favouritingWallet = useSelector((state) => state.wallet.favouritingWallet);
    const unfavouritingWallet = useSelector((state) => state.wallet.unfavouritingWallet);
    const favouriteWallets = useSelector((state) => state.wallet.favouriteWallets);
    const unfavouriteWallet = useSelector((state) => state.wallet.unfavouriteWallet);
    const errorFavouriteWallet = useSelector((state) => state.wallet.errorFavouriteWallet);
    const errorUnfavouriteWallet = useSelector((state) => state.wallet.errorUnfavouriteWallet);
    const favouriteWallet = useSelector((state) => state.wallet.favouriteWallet);
    const fetchingFavouriteWallets = useSelector((state) => state.wallet.fetchingFavouriteWallets);
    const fetchingWalletStatistics = useSelector((state) => state.wallet.fetchingWalletStatistics);
    const walletStatistics = useSelector((state) => state.wallet.walletStatistics);
    const filterPlanGroups = useSelector((state) => state.planGroups.filterPlanGroups);
    const filteringPlanGroups = useSelector((state) => state.planGroups.filteringPlanGroups);

    const getMembership = useCallback(() => {
        dispatch(DefMembershipActions.defMembershipRequest());
    }, [dispatch]);
    const getPlanGroups = useCallback(
        (businessId, options) => {
            dispatch(PlanGroupActions.planGroupAllRequest(businessId, options));
        },
        [dispatch]
    );
    const getPlanGroupsFilter = useCallback(
        (businessId, name, options) => {
            dispatch(PlanGroupActions.planGroupsFilterRequest(businessId, name, options));
        },
        [dispatch]
    );
    const getPlanGroupsCount = useCallback(
        (businessId) => {
            dispatch(PlanGroupActions.planGroupAllCountRequest(businessId));
        },
        [dispatch]
    );
    const getWallet = useCallback(
        (businessId) => {
            dispatch(WalletActions.walletRequest(businessId));
        },
        [dispatch]
    );
    const getWalletStatistics = useCallback(
        (businessId, options) => {
            dispatch(WalletActions.walletStatisticsRequest(businessId, options));
        },
        [dispatch]
    );
    const getSettlementsStatusCount = useCallback(
        (businessId) => {
            dispatch(SettlementActions.settlementStatusCountRequest(businessId, "PENDING"));
        },
        [dispatch]
    );
    const getFavouriteWallets = useCallback(
        (businessId) => {
            dispatch(WalletActions.favouriteWalletsRequest(businessId));
        },
        [dispatch]
    );
    const getFavouriteWallet = useCallback(
        (id) => {
            dispatch(WalletActions.favouriteWalletRequest(id));
        },
        [dispatch]
    );
    const getUnfavouriteWallet = useCallback(
        (id) => {
            dispatch(WalletActions.unfavouriteWalletRequest(id));
        },
        [dispatch]
    );

    const [state, setState] = useState({
        planGroups: null,
        filterPlanGroups: null,
        planGroupsGraphed: null,
        planGroupsCount: 0,
        size: 15,
        first: 0,
        sort: "id,desc",
        favouriting: null,
        favouritingId: null,
        unfavouritingId: null,
        unfavouriting: null,
        favouriteWallets: null,
        favouritingWallet: false,
        unfavouritingWallet: false,
        isNewWallet: false,
        newWallet: {},
        success: false,
        requesting: null,
        newFav: null,
        addNewFavVisible: false,
        callFav: false,
        input: "",
        submitInput: false,
        isFiltered: false,
        name: "",
        fetchingWalletStatistics: false,
        walletStatistics: null
    });
    const [walletName, setWalletName] = useState('')

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getPlanGroupsCount(defMembership.business.id);
            getWallet(defMembership.business.id);
            getWalletStatistics(defMembership.business.id, {});
            getSettlementsStatusCount(defMembership.business.id);
            getFavouriteWallets(defMembership.business.id);
            getPlanGroups(defMembership.business.id);
        }
    }, []);

    useEffect(() => {
        if (defMembership) {
            getPlanGroups(defMembership.business.id);
        }
    }, [defMembership]);

    useEffect(() => {
        if (defMembership) {
            getPlanGroups(defMembership.business.id, state.query);
        }
    }, [state.query, defMembership]);

    useEffect(() => {
        if (defMembership) {
            getWalletStatistics(defMembership.business.id);
        }
    }, [defMembership]);

    useEffect(() => {
        if (planGroups) {
            setState((state) => ({ ...state, planGroups: planGroups }));
        }
        if (walletStatistics) {
            setState((state) => ({ ...state, walletStatistics: walletStatistics }));
        }
        if (planGroupsCount) {
            setState((state) => {
                return { ...state, planGroupsCount: planGroupsCount };
            });
        }
        if (filterPlanGroups) {
            setState((state) => ({ ...state, filterPlanGroups: filterPlanGroups }));
        }
        if (favouriteWallets) {
            setState((state) => ({ ...state, favouriteWallets: favouriteWallets }));
        }
        function cleanup() { }
        return cleanup();
    }, [planGroups, planGroupsCount, unfavouriteWallet, errorUnfavouriteWallet, state.favouriting, state.unfavouriting, walletStatistics]);

    useEffect(() => {
        if (!favouritingWallet && state.callFav) {
            if (errorFavouriteWallet) {
                toast.current.show({ severity: "error", summary: "Error Message", detail: t("product_account.err_add_fav") });
            } else {
                toast.current.show({
                    severity: "success",
                    code: "added",
                    summary: t("successful"),
                    detail: `${t("product_account.save_fav")}`,
                    life: 3000,
                });
            }
            setState((prev) => {
                return { ...prev, callFav: false, newFav: null };
            });
        }
        if (!unfavouritingWallet && state.loadUnFav) {
            if (!errorUnfavouriteWallet) {
                showRemoveSuccess()
            } else {
                toast.current.show({ severity: "error", summary: "Error Message", detail: t("product_account.err_removed_fav") });
            }
            setState((prev) => {
                return { ...prev, loadUnFav: false };
            });
        }
    }, [unfavouritingWallet, getFavouriteWallets, errorUnfavouriteWallet, favouritingWallet, errorFavouriteWallet, state.newFav, state.callFav, state.loadUnFav]);

    useEffect(() => {
        if (errorFavouriteWallet && state.favouritingWallet && favouritingWallet) {
            getFavouriteWallets(defMembership.business.id);
            showAddedError();
        }
        if (unfavouritingWallet && state.unfavouritingWallet && errorUnfavouriteWallet) {
            getFavouriteWallets(defMembership.business.id);
            showRemoveError();
        }
    }, [
        errorFavouriteWallet,
        favouritingWallet,
        state.favouritingWallet,
        favouriteWallet,
        unfavouritingWallet,
        state.unfavouritingWallet,
        errorUnfavouriteWallet,
        unfavouriteWallet,
        unfavouriteWallet,
    ]);

    const loading = fetchingPendingCount || fetchingWallet || fetchingPlanGroups || fetchingPlanGroupsCount || fetchingFavouriteWallets || filteringPlanGroups || fetchingWalletStatistics;

    const showWarn = () => {
        toast.current.show({ severity: "warn", summary: t("product_account.warning_summary"), detail: t("product_account.warning_detail"), life: 3000 });
    };
    const showRemoveError = () => {
        toast.current.show({ severity: "error", summary: t("product_account.error_summary"), detail: t("product_account.error_message"), life: 3000 });
    };
    const showRemoveSuccess = () => {
        toast.current.show({ severity: "success", code: "removedFav", summary: t("product_account.success"), detail: t("product_account.delete_detail"), life: 1500 });
    };
    const showAddedSuccess = () => {
        toast.current.show({ severity: "success", code: "added", summary: t("product_account.success"), detail: t("product_account.added_detail"), life: 3000 });
    };
    const showAddedError = () => {
        toast.current.show({ severity: "success", summary: t("product_account.error_summary"), detail: t("product_account.error_added"), life: 3000 });
    };

    const showAddingFav = (name) => {
        toast.current.show({
            severity: "info",
            summary: t("product_account.add"),
            detail: t("product_account.saving_fav", { name }),
            life: 3000,
        });
    };

    const showRemovingFav = (name) => {
        toast.current.show({
            severity: "info",
            summary: t("product_account.remove"),
            detail: t("product_account.removing_fav", { name }),
            life: 3000,
        });
    };

    const walletData = walletStatistics?.content
    const walletsData = () => {
        const data = state.name ? filterPlanGroups : walletData;
        if (data === null) {
            return [];
        }
        else {
            const allWallets = data?.map((walletData, key) => {
                const { name, available, balance, pending } = walletData;
                let arr = {
                    ...walletData,
                    name: name,
                    available: available,
                    balance: balance,
                    pending: pending,
                    key
                };
                return arr;
            });
            return allWallets;
        }
    };

    return loading ? (
        <Loading />
    ) : (
        <div style={{ padding: "1rem" }}>
            <Toast
                ref={toast}
                onRemove={(message) => {
                    if (message.severity === "success") {
                        getFavouriteWallets(defMembership.business.id);
                        getWalletStatistics(defMembership.business.id);
                        getPlanGroups(defMembership.business.id)
                    }
                }}
            />
            <Summary />

            <AddProductAccountDialog {...{ getFavouriteWallet, showAddingFav, setState, state }} />

            <ProductAccountsTable
                {...{
                    getPlanGroupsFilter, planGroups, walletsData, unfavouritingWallet, favouritingWallet,
                    favouritedWallets, loading, showWarn, getUnfavouriteWallet, getFavouriteWallet, showRemovingFav,
                    setFavouritedWallets, getWallet, defMembership, fetchingPendingCount, fetchingWallet,
                    fetchingPlanGroups, fetchingPlanGroupsCount, getFavouriteWallets, state, setState, showRemoveSuccess,
                    showAddedSuccess, walletStatistics, walletName, setWalletName
                }}
            />
        </div>
    );
}
export default ProductAccount;