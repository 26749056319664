import React from 'react'
import { Card } from 'primereact/card';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import { useTranslation } from "react-i18next";
import { AutoComplete } from "primereact/autocomplete";
import { useReactToPrint } from 'react-to-print';

export const ActionBar = ({
    selectedType, setSelectedType, dropdownItems, selectedOption,
    summaryOptions, setSelectedOption, selectedCustomer, filteredCustomer, searchCustomers, periods, period, setDates, setPeriod,
    setSelectedCustomer, dates, state, setState, placeholder, summaryOption, reactToPrintContent, onClick, setCleared }) => {

    const { t } = useTranslation("common");
    const handlePrint = useReactToPrint({
        content: reactToPrintContent
    });

    return (
        <div>
            <Card id="filters-card" style={{ marginBottom: "3rem" }}>
                <div className='grid report-card-content'>
                    <div className='col-10'>
                        <div className="p-grid p-fluid main-div">
                            <>
                                {
                                    summaryOption === true &&
                                    <div className="p-col-12 p-md-3 filters" >
                                        <div className="p-inputgroup ">
                                            <Dropdown
                                                value={selectedType}
                                                onChange={(e) => { setSelectedType(e.value) }}
                                                options={dropdownItems}
                                                optionLabel="name"
                                                className="w-full  activity-dropdown report-plan-customer-dropdown"
                                                id='report-plan-customer-dropdown' />
                                        </div>
                                    </div>
                                }
                                <div className="p-col-12 p-md-3 filters">
                                    {selectedType.code === "plan" ?
                                        <div className="p-inputgroup" >
                                            <Dropdown
                                               value={selectedOption}
                                               options={summaryOptions}
                                               optionLabel="name"
                                               placeholder={placeholder}
                                               filter
                                               onChange={(e) => setSelectedOption(e.value)}
                                               className="w-full custom-dropdown"
                                               valueTemplate={(option) => (
                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
                                                       {option ? (
                                                           <>
                                                               <div style={{ marginRight:'25px', fontSize:'14px' }}>{option.name}</div>
                                                               <div>
                                                                    <button
                                                                        className="p-dropdown-clear-icon p-link"
                                                                        onClick={() => setSelectedOption(null)}
                                                                    >
                                                                        <span className="pi pi-times"></span>
                                                                    </button>
                                                               </div>
                                                           </>
                                                       ) : (
                                                           <div style={{ fontSize: '14px', color: '#495057' }}>
                                                            {selectedOption ? selectedOption.name : t(t("summary_reports.choose_product"))}</div>
                                                       )}
                                                   </div>
                                               )}
                                           /> 
                                                
                                        </div> :
                                        <div className="p-inputgroup" style={{ width: "15rem" }}>
                                            <AutoComplete field="name" placeholder={t("summary_reports.search_by_customer")}
                                                value={selectedCustomer}
                                                onSelect={(e) => { setState(() => ({ ...state, selectedCustomer: e.value })) }}
                                                suggestions={filteredCustomer}
                                                completeMethod={searchCustomers}
                                                onChange={(e) => setSelectedCustomer(e.value)} />
                                        </div>
                                    }
                                </div>
                               <div className="p-col-12 p-md-3 filters" style={{ width: "12.5rem" }}>
                                    <div className="p-inputgroup">
                                        <Dropdown
                                            className="activity-dropdown"
                                            value={period}
                                            id="time"
                                            options={periods}
                                            onChange={(e) => {
                                                setPeriod(e.value)
                                                if (e.value.name === "Lifetime") {
                                                    setDates(null)
                                                }
                                                if (e.value.name !== "Custom") {
                                                    setDates(null)
                                                }
                                            }}
                                            optionLabel="name"
                                        />
                                    </div>
                                </div>
                                {period.code === "CUSTOM" &&  <div className="p-col-12 p-md-4 input-div" style={{ width: "18rem" }}>
                                    <div className='report_calender'>
                                        <Calendar className="p-datepicker-header report-calender datepicker"
                                            id='report-calender'
                                            value={dates}
                                            showIcon
                                            showButtonBar
                                            dateFormat="dd M yy" 
                                            maxDate={new Date()}
                                            onChange={(e) => {
                                                setDates(e.value)
                                            }} selectionMode="range"
                                            readOnlyInput
                                            placeholder={t("Choose start and end dates")}
                                            onClearButtonClick={setCleared(true)}
                                        />
                                    </div>
                                </div>}
                            </>
                        </div >
                    </div>
                    <div className='col-2'>
                        <div className="export-content" >
                            <div>
                                <span className='export-text' onClick={() => { handlePrint(); onClick(); }}>
                                    {t("summary_reports.export")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
