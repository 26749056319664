import React from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
const CustomOverlayPanel = ({ children, op }) => {
      return (
            <>
                  <div>
                        <OverlayPanel style={{ backgroundColor: '#fff' }} ref={op} className="custom-overlay more-actions-overlaypanel">
                              {children}
                        </OverlayPanel>
                  </div>
            </>
      );
};
export default CustomOverlayPanel;