import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCreate from "../Pages/Products/ProductCreate";
import InviteSubscriber from "../Pages/Subscriptions/Invitation/InviteSubscriber";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { AutoComplete } from "primereact/autocomplete";
import BusinessCustomersActions from "../Redux/transition/customers/business-customer.reducer";
import PaymentTrackActions from "../Redux/transition/payment-track/payment-track.reducer";
import { useNavigate  } from "react-router-dom";
import OnboardingNotification from "../Components/OnboardingNotification";

export function ActionButtons(visible, defMembership, toogleInviteSubscriber, onHide, onHidePlan, tooglePlan, visiblePlan, business, paymentCredentials, toogleShareBusiness) {

    const { t } = useTranslation("common");
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [openNotification, setOpenNotification] = useState(false);


    const [visibleInvite, setVisibleInvite] = useState(false);
    const [state, setState] = useState({
        filteredCustomers: [],
        exactFilteredBusinessCustomers: [],
        selectedCustomerId: null,
        isCustomerSelected: false,
        verificationStatus: null,
        fetchingVerifRecord: true
    });

    const dispatch = useDispatch();
    const getExactFilteredBusinessCustomers = useCallback((businessId, query, options) => dispatch(BusinessCustomersActions.exactFilteredBusinessCustomerRequest(businessId, query, options)), [dispatch])
    const getEnterpriseCustomers = useCallback((businessId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessId)), [dispatch]);
    const getBusinessCustomerSubscriptions = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionsRequest(businessCustomerId)), [dispatch]);
    const getBusinessCustomerRecord = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.businessCustomerRecordRequest(businessCustomerId)), [dispatch]);
    const getRecentCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentMissedCustomerPayments = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerMissedPaymentsRequest(businessCustomerId, size)), [dispatch]);
    const getRecentSubscriptionActivity = useCallback((businessCustomerId, size) => dispatch(BusinessCustomersActions.businessCustomerSubscriptionActivityRequest(businessCustomerId, size)), [dispatch]);
    const getCustomerPendingPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerPendingPaymentRequest(businessCustomerId)), [dispatch]);
    const getCustomerMissedPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerMissedPaymentRequest(businessCustomerId)), [dispatch]);
    const getCustomerReceivedPayments = useCallback((businessCustomerId) => dispatch(BusinessCustomersActions.customerReceivedPaymentRequest(businessCustomerId)), [dispatch]);
    const getSuccessfulPayments = useCallback((bcId) => { dispatch(BusinessCustomersActions.successfulPaymentsRequest(bcId)) }, [dispatch])
    const getExpiredPayments = useCallback((bcId) => { dispatch(BusinessCustomersActions.expiredPaymentsRequest(bcId)) }, [dispatch])
    const getBusinessCustomerExternalPayments = useCallback((businessId, bcId) => { dispatch(PaymentTrackActions.businessCustomerExternalPaymentsRequest(businessId, bcId)) }, [dispatch])

    const exactFilteredBusinessCustomers = useSelector((state) => state.businessCustomers.exactFilteredBusinessCustomers);

    //Toggle merchant onboarding onhide
    const toggleSetOpenDialog = () => { setOpenNotification(!openNotification) };

    const searchCustomers = (data) => {
        if (data.query?.length >= 3) {
            getExactFilteredBusinessCustomers(defMembership.business.id, data.query)
        }
    };

    useEffect(() => {
        if (exactFilteredBusinessCustomers) {
            setState((state) => {
                return { ...state, exactFilteredBusinessCustomers: exactFilteredBusinessCustomers };
            });
        }
        if (state.isCustomerSelected) {
            const customerId = state.selectedCustomerId
            navigate(`/subscribers/subscriber${customerId}`)
            getEnterpriseCustomers(state.selectedCustomerId)
            getBusinessCustomerSubscriptions(state.selectedCustomerId)
            getBusinessCustomerRecord(state.selectedCustomerId)
            getRecentCustomerPayments(state.selectedCustomerId, "3")
            getRecentMissedCustomerPayments(state.selectedCustomerId, "3")
            getRecentSubscriptionActivity(state.selectedCustomerId, "3")
            getCustomerPendingPayments(state.selectedCustomerId)
            getCustomerMissedPayments(state.selectedCustomerId)
            getCustomerReceivedPayments(state.selectedCustomerId)
            getSuccessfulPayments(state.selectedCustomerId)
            getExpiredPayments(state.selectedCustomerId)
            getBusinessCustomerExternalPayments(defMembership.business.id, state.selectedCustomerId)
            setState((state) => { return { ...state, isCustomerSelected: false } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exactFilteredBusinessCustomers, state.isCustomerSelected, state.selectedCustomerId, navigate]);

    const onClickCancelBtn = () => {
        setState((state) => {
            return {
                ...state,
                filteredCustomers: false,
            };
        });
        setQuery("");
    }

    const toggleInvite = () => {
        setVisibleInvite(false)
    }

    return <div style={{ backgroundColor: "#FFFFFF" }}>
        <OnboardingNotification visible={openNotification} onHide={toggleSetOpenDialog} />
        <span><ProductCreate visiblePlan={visiblePlan} onHide={() => { onHidePlan(); }} /> </span>
        <InviteSubscriber visible={visible} toggleVisible={visibleInvite} onHide={toggleInvite} initialTier={null} />
        <>
            <div className="main_div_action_btns">
                <div style={{ margin: "0rem 2rem", paddingBottom: "0.3rem" }}>
                    <div className="field">
                        <span style={{ display: "flex", flexDirection: "row" }}>
                            <AutoComplete
                                onSelect={(e) => { setState((state) => { return { ...state, selectedCustomerId: e.value?.id, isCustomerSelected: true } }) }}
                                field="name"
                                id="global_search"
                                value={query}
                                suggestions={state.exactFilteredBusinessCustomers}
                                completeMethod={(e) => { searchCustomers(e) }}
                                placeholder={t("common.global_search")}
                                onChange={(e) => {
                                    setQuery(e.value);
                                }}
                                itemTemplate={(customer, index) => (
                                    <div >
                                        <div style={{ paddingBottom: "0.2rem" }}>
                                            <span className="customerNameAutoComplete">{customer.name}</span>
                                        </div>
                                        <div className="grid">
                                            <div className="col-6 md:col-6 lg:col-6" style={{ display: "flex", flexDirection: "column" }}>
                                                <span className="customerPhoneNoAutoComplete" >{customer.phoneNo}</span>
                                                <span className="customerIdAutoComplete">{customer.reference}</span>
                                            </div>
                                            <div className="col-6 md:col-6 lg:col-6" style={{ display: "flex", flexDirection: "column" }}>
                                                <span className="customerEmailAutoComplete" >{customer.email}</span>
                                                <span className="customerRefAutoComplete" >{customer.customerNum}</span>
                                            </div>
                                        </div>
                                        {index !== state.exactFilteredBusinessCustomers.length - 1 ? <hr className="hr_customers" /> : ""}
                                    </div>
                                )}
                            />
                            {query &&
                                <Button id="customersCancelBtn" type="submit" onClick={onClickCancelBtn} icon="pi pi-times" className="p-button-danger buttons" />
                            }
                        </span>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                        <Button className="actionBar_btns" label={t("business.business_link")}
                            onClick={() => {
                                if (!business.verified || !paymentCredentials.value) {
                                    toogleShareBusiness()
                                }
                                else {
                                    navigate("/share/business")
                                }
                            }} />
                    </div>
                    &nbsp; &nbsp; &nbsp;<div>
                        <Button className="actionBar_btns" label={t("invitation.invite_subscriber")}
                            onClick={() => {
                                if (!business.verified || !paymentCredentials) {
                                    toogleShareBusiness()
                                }
                                else if (!state.verifStatus && !state.fetchingVerifRecord) {
                                    setOpenNotification(true)
                                } else {
                                    setVisibleInvite(true)
                                }
                            }} />
                    </div>
                    &nbsp; &nbsp; &nbsp;{((defMembership?.role === "OWNER") || (defMembership?.role === "ADMIN")) &&
                        <div><Button className="actionBar_btns"
                            id="create_group" label={t("products.create_group")} disabled={!defMembership} onClick={tooglePlan} /></div>}
                    &nbsp; &nbsp;
                </div>
            </div>
        </>
    </div>;
}