import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ProfileActions from "../../Redux/actions/profile-actions";
import AccountActions from "../../Redux/actions/account-actions";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useAuth0 } from "@auth0/auth0-react";
import { removeAuthToken } from "../../Service/api";
import { useNavigate } from "react-router-dom";
import "../../Styles/pages/emailVerification.scss"
import { AppFooter } from '../../Components/AppFooter';
import AppTopbar from '../../Components/Navbar'
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

function EmailVerification() {
  const toast = useRef(null);

  const profile = useSelector((state) => state.profile.profile);
  const fetchingProfile = useSelector((state) => state.profile.fetching);
  const defMembership = useSelector((state) => state.defMembership.defMembership);
  const errorDefMembership = useSelector((state) => state.defMembership.error);
  const resendingEmail = useSelector((state) => state.account.resendEmailVerification);
  const resendingEmailSuccess = useSelector((state) => state.account.resendEmailVerificationSuccess);
  const resendingEmailError = useSelector((state) => state.account.resendEmailVerificationError);

  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(ProfileActions.profileRequest()), [dispatch]);
  const resendEmail = useCallback((user) => dispatch(AccountActions.emailVerificationResend(user)), [dispatch]);
  const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch])

  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [resend, setResend] = useState(false);

  const [state, setState] = useState({
    membership: null
  });

  useEffect(() => {
    if (!defMembership) {
      getMembership()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: t('verification.success_header'), detail: t('verification.success_message'), life: 5000 });
  }

  const showError = () => {
    toast.current.show({ severity: 'error', summary: t('verification.error_header'), detail: t('verification.error_message'), life: 5000 });
  }


  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (defMembership) {
      setState((state) => {
        return { ...state, membership: defMembership };
      });
    }
    if (resend && !resendingEmail && resendingEmailSuccess) {
      showSuccess()
      setResend(false)
    }
    if (resend && !resendingEmail && resendingEmailError) {
      showError()
      setResend(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resend, profile, resendingEmail, resendingEmailSuccess, resendingEmailError, defMembership])
  useEffect(() => {
    if (profile?.emailVerified) {
      if ((!state?.membership || state?.membership.length < 1) && errorDefMembership?.status === 404) {
        navigate('/configure', { replace: true });
      }
      else if ((!state?.membership || state?.membership.length < 1) && errorDefMembership) {
        navigate('/denied', { replace: true });
      }
      else {
        if (state?.membership && !errorDefMembership && (state?.membership?.role === "ADMIN" || state?.membership?.role === "OWNER")) {
          navigate('/home', { replace: true });
        }
        else if (state?.membership && !errorDefMembership && (state?.membership?.role !== "ADMIN" || state?.membership?.role !== "OWNER")) {
          navigate('/subscribers', { replace: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, defMembership, errorDefMembership])

  const logoutFn = async () => {

    removeAuthToken()
    logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH_LOGOUTURL } })
    sessionStorage.setItem('crumbs', [])
  };

  return (
    <>
      <Toast ref={toast} />
      <AppTopbar />
      <div className='body'>

        {fetchingProfile ? <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div> :

          <div>

            {(!profile?.emailVerified && !fetchingProfile) && <div className="verification-container">
              <img src={`${process.env.PUBLIC_URL}/logos/LOGO3blk.png`} width='130px' height='18px' alt="Logo" />
              <h2 className='verification-title'>{t('verification.title')}</h2>
              <span className='verification-desc'>{t('verification.message')}</span>
              <span className='verification-desc' style={{ display: "block", marginBottom: "1.8rem", fontWeight: "bold" }}>{`${profile?.email}`}</span>
              <span className='verification-desc'>{t('verification.instruction')}</span>
              <span className='verification-desc' style={{ display: "block", marginBottom: "1.8rem" }}>{t('verification.spam1')} <span style={{ fontWeight: "bold", fontSize: "18px" }}>{t('verification.spam2')}</span>  {t('verification.spam3')}.</span>
              <span className='verification-desc' style={{ display: "block", marginBottom: '1rem' }}>{t('verification.resend')}</span>

              <Button
                label={t("verification.resend_button")} className="verif-button"
                text
                onClick={() => { resendEmail({ login: profile.login }); setResend(true) }}
                loading={resendingEmail}
              />

              <br />
              <Button label={t("nav.cancel")} className="p-button-raised p-button-rounde verif-button"
                style={{ backgroundColor: '#D0021B', marginTop: "2rem", }} onClick={logoutFn} />
            </div>}
          </div>
        }

      </div>
      <AppFooter />
    </>
  )
}

export default EmailVerification