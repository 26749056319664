import React, { useEffect, useState, useCallback } from 'react';
import "../Styles/components/Notification.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PlanGroupActions from "../Redux/transition/plan-group/plan-group.reducer";
import BusinessActions from "../Redux/actions/business-actions"
import DocumentActions from '../Redux/transition/document-upload/document-upload.reducer';
import PaySwitchActions from "../Redux/transition/payswitch/payswitch.reducer";
import PlanActions from "../Redux/transition/plan/plan.reducer";

const Notification = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const [dialogClosed, setDialogClosed] = useState(false)
    const [showMessage, setShowMessage] = useState(true)
    const [messageClosed, setMessageClosed] = useState(false);

    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroupsGraphedActive = useSelector((state) => state.planGroups.planGroupsGraphedActive)
    const business = useSelector((state) => state.businesses.business)
    const fetchingBusiness = useSelector((state) => state.businesses.fetchingOne)
    const fetchingVerifStatus = useSelector((state) => state.documents.fetchingVerifStatus);
    const verifStatus = useSelector((state) => state.documents.verifStatus);
    const paymentCredentials = useSelector((state) => state.payswitch.credentials);
    const plans = useSelector((state) => state.plans.plans)

    const dispatch = useDispatch()
    const getActivePlanGroups = useCallback((businessId) => { dispatch(PlanGroupActions.planGroupGraphedActiveRequest(businessId)) }, [dispatch])
    const getBusiness = useCallback((businessId) => { dispatch(BusinessActions.businessRequest(businessId)) }, [dispatch])
    const getVerificationRecord = useCallback((businessId) => { dispatch(DocumentActions.businessVerificationStatusRequest(businessId)) }, [dispatch]);
    const getCredentials = useCallback((businessId) => { dispatch(PaySwitchActions.paySwitchCredentialRequest(businessId)) }, [dispatch]);
    const getPlans = useCallback((businessId) => { dispatch(PlanActions.planAllRequest(businessId)) }, [dispatch])

    const [state, setState] = useState({
        businessObject: null,
        status: null,
        verificationItem: null,
        show: false,
        close: false,
        tierVisible: false,
        duplicate: null,
        plans: [],
        verificationRecord: null,
        paymentCredentials: null
    })

    useEffect(() => {
        const showingMessage = localStorage.getItem(`Message_State ${defMembership?.business.id}`)
        setShowMessage(JSON.parse(showingMessage))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defMembership?.business.id, messageClosed])

    const handleCloseNotification = () => {
        setMessageClosed(true)
        localStorage.setItem(`Message_State ${defMembership?.business.id}`, JSON.stringify(false))
    };
    useEffect(() => {
        if (props.defMembership) {
            getBusiness(props.defMembership.business.id)
            getActivePlanGroups(props.defMembership.business.id)
            getVerificationRecord(props.defMembership.business.id)
            getCredentials(props.defMembership.business.id)
            getPlans(defMembership.business.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (business) {
            setState((state) => { return { ...state, businessObject: business } })
        }
        if (planGroupsGraphedActive) {
            setState((state) => { return { ...state, plans: planGroupsGraphedActive?.groups } })
        }
        if (paymentCredentials) {
            setState((state) => { return { ...state, paymentCredentials: paymentCredentials } })
        }
        if (verifStatus) {
            setState((state) => { return { ...state, verificationRecord: verifStatus } })
        }
        if (!verifStatus) {
            setState((state) => { return { ...state, verificationRecord: null } })
        }
        if (props.hide) {
            localStorage.setItem('verificationItem', JSON.stringify(props.hide));
            setState((state) => ({ ...state, close: true }))
        }
        if (state.close) {
            setTimeout(JSON.parse(localStorage.getItem('verificationItem')), 4000)
        }
        const dialogClosed = JSON.parse(localStorage.getItem('dialogClosed'));
        setDialogClosed(dialogClosed);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.hide, business, state.close, dialogClosed, planGroupsGraphedActive, paymentCredentials, verifStatus])

    useEffect(() => {
        const dialogClosed = JSON.parse(localStorage.getItem('dialogClosed'));
        if ((!props.fetchingBusiness || !props.fetchingVerifStatus) && (!dialogClosed && state.businessObject?.verified && plans?.length < 1)) {
            setState((state) => ({ ...state, tierVisible: true }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checks = (data) => {
        if (data && paymentCredentials && plans?.length < 1 && showMessage === null) {
            return <div>
                <div className="verified_notice">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="p-message-summary-verified">
                        <div>
                            {t("idoc.successful_verification")}
                            {(defMembership?.role === "OWNER" || defMembership?.role === "ADMIN") && (
                                <span
                                    onClick={() => {
                                      const  groupId = planGroupsGraphedActive?.groups?.[0].group.id
                                        navigate(`/products/plan/new/${groupId}`)
                                    }}
                                    style={{ textDecoration: "underline", cursor: "pointer" }}>
                                    {t("idoc.create_plan")}
                                </span>)}</div>
                        <div>
                            <i className="pi pi-times-circle" style={{ marginLeft: "auto", fontSize: "1rem", cursor: "pointer" }} onClick={handleCloseNotification} />
                        </div>
                    </div>
                </div>
            </div>
        }
        if (data && paymentCredentials < 1 && showMessage === null) {
            return <div>
                <div className="verified_notice">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="p-message-summary-verified">
                        <div>
                            {t("idoc.successful_verification")}
                            {(defMembership?.role === "OWNER" || defMembership?.role === "ADMIN") && (
                                <span
                                    onClick={() => {
                                        navigate({
                                            pathname: "/settings", state: { 'index': 1 }
                                        })
                                    }}
                                    className='payment_credentials_txt'>
                                    {t("idoc.payment_credentials")}
                                </span>)}
                        </div>
                        <div>
                            <i className="pi pi-times-circle" style={{ marginLeft: "auto", fontSize: "1rem", cursor: "pointer" }} onClick={handleCloseNotification} />
                        </div>
                    </div>
                </div>
            </div>
        }
        if (!data) {
            if (state.businessObject?.verified !== true && state.verificationRecord < 1 && showMessage === null) {
                return <div>
                    <div className="not_verified_notice">
                        <div className="p-message-wrapper p-d-flex">
                            {(defMembership?.role === "OWNER" || defMembership?.role === "ADMIN") ?
                                <>
                                    <span className="p-message-summary"
                                        onClick={() => { window.open("https://form.jotform.com/241795157972571") }}
                                    >{business.name}{" "}{t("idoc.not_verified")}
                                    </span>
                                </> :
                                <>
                                    <p style={{ textDecoration: "none", color: " #23547B" }}>{t("idoc.not_verified_viewer")}</p>
                                </>}
                        </div>
                    </div>
                </div>
            }
        }
        if (!data) {
            if (state.businessObject?.verified !== true && state.verificationRecord > 0 && showMessage === null) {
                return <div>
                    <div className="in_review">
                        <div className="p-message-wrapper p-d-flex">
                            <span className="p-message-summary-in-review">{t("idoc.in_review")}</span>
                        </div>
                    </div>
                </div>
            }
        }
    }

    return (
        <>
            <div>
                {(fetchingBusiness || fetchingVerifStatus || !state.businessObject) ? <div></div> : checks(state.businessObject?.verified)}
            </div >
        </>
    )
}
export default Notification;