import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React from "react";
import "../Styles/pages/products.scss";
import "../Styles/components/dataTable.scss";
import { Button } from "primereact/button";

function CustomDataTable({ value = [], paginator = false, header = "", columns = [], rows = 20, tableClassName = "", tableIdName = "", rowExpansionTemplate = () => { }, onRowToggle = () => { },
    children, title = "", tabs = [], setState = () => { }, setActiveTab, expandedRows, activeTab, loading, resetPage = () => { }, tab = false, ...arg }) {
    const { t } = useTranslation("common");
    const tabHeader = <div className='plans-tabs-btn' >
        {tabs.map(({ label }, i) =>
            <Button className={`p-button-text plans-table-btn ${activeTab === i ? "p-button-raised p-button-info active active-tabs" : "p-button-plain not-active not-active-tabs"}`}
                label={t(label)}
                key={i}
                onClick={() => {
                    setActiveTab(i)
                    setState((state) => ({ ...state, page: 0, sort: 'id,desc', first: 0, tab: true }));
                    resetPage()
                }}
            />
        )}
    </div>
    return (
        <>
            {title && <h6 className="title-tile">{t(title)}</h6>}
            <DataTable
                id={"data_table"}
                value={value}
                paginator={paginator}
                breakpoint="1050px"
                dataKey="id"
                header={tabs.length > 0 ? tabHeader : header}
                rows={rows}
                className={"p-datatable-responsive-table datatable p-card " + tableClassName}
                {...arg}
                rowExpansionTemplate={rowExpansionTemplate}
                onRowToggle={onRowToggle}
                expandedRows={expandedRows}
                loading={loading}
            >
                {columns?.map(({ field = "", body, header = "", sort = false, style = {}, className = "", bodyClassName = "", expander, alignHeader="center", ...arg }, i) => (
                    <Column
                        className={`datatable-cell-default ${className}`}
                        key={i}
                        field={field}
                        body={body}
                        header={header}
                        alignHeader={alignHeader}
                        headerClassName={`datatable-cell-default ${className}`}
                        expander={expander}
                        style={{ ...style }}
                        bodyClassName={bodyClassName}
                        sortable={sort}
                        {...arg}
                    />
                ))}
                {!!children && children}
            </DataTable>
        </>);
}

export default CustomDataTable;