import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Dialog } from 'primereact/dialog';
import { Skeleton } from "primereact/skeleton";
import { ProgressSpinner } from 'primereact/progressspinner';
import "../../Styles/pages/transactions.scss";

export const AddAccountDialog = (props) => {
    const { state, setState, setFavouritePayoutChannels, t, channelOptionTemplate, selectedChannelTemplate, payoutChannelsFavourite } = props;

    const run = () => {
        if (state.newFav) {
            setFavouritePayoutChannels(state.newFav.id);
            setState((prev) => { return { ...prev, callFav: true, addNewFavVisible: false, addingFav: true }; });
        }
    };

    const onHide = () => {
        setState((prev) => { return { ...prev, newFav: null, addNewFavVisible: false }; });
    };
    const footer = (
        <div className="payout_channel-buttonsB">
            <Button label="Cancel" icon="pi pi-times" style={{ color: "#008dd5", backgroundColor: "transparent", border: "none" }} onClick={onHide} />
            <Button label="Add Favorite" icon="pi pi-plus" style={{ color: "white", backgroundColor: "#d0021b" }} onClick={run} />
        </div>
    );

    return (
        <Dialog header={t('saved_recipients.add_fav_recipient')} footer={footer} className="addFacDial defaultDial" visible={state.addNewFavVisible} style={{ width: '360px', height: "250px" }} onHide={onHide}>
            <div className="p-col">
                <h2 className="payout_channel-title accounts-channel-title">{t("saved_recipients.choose_fav_recipient")}</h2>
                <p className="favorite-dropdown-title" style={{ marginLeft: "1.2rem" }}>{t("saved_recipients.saved_accounts")}</p>
                <Dropdown
                    value={state.newFav}
                    options={state.payoutChannels.filter(channel => !payoutChannelsFavourite?.map(favorite => favorite.id).includes(channel.id))}
                    itemTemplate={channelOptionTemplate}
                    onChange={(e) => setState((state) => {
                        return { ...state, newFav: e.value };
                    })}
                    optionLabel="name"
                    id="payoutChannel"
                    valueTemplate={selectedChannelTemplate}
                    className="payout_channel-dropdown favorite-dropdown" />
            </div>
        </Dialog>
    );
};
const AccountCard = ({ name = '', identifier = '', channelName = '', type = '', icon, onClick, remove, }) => {
    const { t } = useTranslation("common");
    const [removeFav, setRemoveFav] = useState(false)
    const confirm = () => {
        setRemoveFav(true);
    };
    const onHide = () => setRemoveFav(false);
    const footer = (
        <div className="payout_channel-buttonsC">
            <Button label="No" icon="pi pi-times" style={{ color: "#008dd5", backgroundColor: "transparent", border: "none" }} onClick={onHide} />
            <Button label="Yes" icon="pi pi-check" style={{ color: "white", backgroundColor: "#d0021b" }} onClick={() => remove()} />
        </div>
    );
    return (
        <div className="grid nested-grid fav">
            <Dialog header={t("recipient.confirmation_header")} visible={removeFav} onHide={onHide} footer={footer}>
                {<span><i className="pi pi-exclamation-triangle" /> {t("recipient.remove_favorite")} </span>} </Dialog>
           
            <div className="col-3" onClick={onClick}>
                {icon ? <img src={icon} className="fav-info-icon " alt={name} />
                    : <Skeleton className="icon" shape="circle" size="75px"></Skeleton>}
                <p className="fav-info-text-channel text-left">{channelName}</p>
            </div>
            <div className="col-9">
                <div className="grid col-12">
                    <p className="fav-info-type">{type}</p>
                    <Button icon="pi pi-minus"
                        onClick={confirm}
                        className="p-button-rounded p-button-danger p-button-outlined fav-button-cancel p-2 ml-auto"
                        aria-label="Cancel"
                    />
                </div >
                <div className="" onClick={onClick}>
                    <p className="fav-name">{name}</p>
                    <p className="fav-info-text-num">{identifier}</p>
                </div>
            </div>
        </div>
    );
};

const AddAccountCard = ({ onClick, t }) => {
    return (
        <div onClick={onClick} >
            <Button className="addFav-btn"> <i className="icon pi-left pi pi-plus-circle "><span className="addFav-btn-text ml-2">{t("recipient.add_fav_recipient")}</span></i></Button>
        </div>
    );
};



export const FavouriteSection = ({ payoutChannelsFavourite, fetchingFavourite, onClickAccountCard, payoutChannelSetUnfavourite, state, setState, isNewChannel = false, showRemovingFav, showErrorFav }) => {
    const { t } = useTranslation("common");
    const addFav = () => {
        setState((prev) => { return { ...prev, addNewFavVisible: true }; });
    };
    if (isNewChannel) { return <></>; }

    return (
        <>
            <h6 className="wallet-titles mt-5">{t("recipient.favorites")}</h6>
            <div className="carousel-demo">
                <div className="flex justify-end mb-2" style={{ justifyContent: "end" }}>
                    <AddAccountCard onClick={addFav} t={t} />
                </div>
                <div className= {payoutChannelsFavourite.length > 0 ? "card card-background":"no-background"}>
                    <div >
                        {fetchingFavourite ? (
                            <div >
                                <ProgressSpinner
                                    style={{ display: "flex", justifyContent: "center", alignItems:"center"}}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-wrap fav-display" >
                                {payoutChannelsFavourite.map((account,i) => (
                                    <div key={i} >
                                        <AccountCard
                                            name={account.name}
                                            type={account.type}
                                            identifier={account.identifier}
                                            channelName={account.systemBank.name || account.channelName}
                                            onClick={() => onClickAccountCard(account)
                                            }
                                            icon={account.systemBank.logoUrl}
                                            remove={() => {
                                                if (!payoutChannelsFavourite.some(({ id }) => account.id === parseInt(id))) {
                                                    showErrorFav();
                                                }
                                                payoutChannelSetUnfavourite(account.id);
                                                setState((prev) => ({ ...prev, loadUnFav: true, removingFav: true }));
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </>);
};
