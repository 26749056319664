import React from "react";
import Logo from "../assets/images/Reveknew_Logo_Colour_and_Black.png";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from "primereact/button";

const NotFound = () => {
	const { t } = useTranslation();

	const navigate = useNavigate()

	const defMembership = useSelector((state) => state.defMembership.defMembership);

	const role = ["VIEWER", "MANAGER"].includes(defMembership?.role)

	const handlePush = () => {
		if (role) {
			navigate('/subscribers')
		} else {
			navigate('/home')
		}
	}

	return (
		<div className="exception-body notfound">
			<div className="exception-panel"></div>

			<div className="exception-content">
				<img src={Logo} alt="Reveknew-Logo" width={150} />
				<h1>
					<span className="exception-name">PAGE</span> NOT FOUND
				</h1>
				<p>Requested resource is not available.</p>
				<Button className='p-button-info' icon='pi pi-chevron-left' onClick={() => handlePush()} label={role === true ? t("common.back_to_subscribers") : t("common.back")} />
			</div>
		</div>
	);
}
export default NotFound