import { Button } from "primereact/button";
import { Tooltip } from "primereact";
import React from "react";
import { useTranslation } from "react-i18next";
import "../../Styles/pages/product.scss"

const SummaryCard = (props) => {

  const { t } = useTranslation("common");

  const cardSections = (title, currency, number, nullValue, desc, className, info, subs, cardStyle) => {
    return (
      <div className="p-col-12 p-lg-6 p-xl-3 plans-card-title-overlay">
        <div className="card-section">
          <span className="card-title">{title}</span>
          <Button type="text" style={{
            fontSize: "18px", background: "none",
            border: "none"
          }}
            icon="pi pi-info-circle"
            className={`p-button-rounded cardSectionsButton ${className}`}
          />
          <Tooltip position="bottom" target={`.${className}`} autoHide={false} className="tooltip">
            <div style={{ margin: "1rem" }}>
              <h5 className="title">{title}</h5>
              <hr className="hr" />
              <span className="desc">{desc}</span>
            </div>
          </Tooltip>
        </div>
        <div className={`${cardStyle}`}>
          <span className="card-value-prefix text-style">{currency} </span>
          <span className="card-value text-style" >{number}</span> <br />
          <span style={{ padding: "0.8rem", fontSize: "12px" }}>{info} {subs}</span>
        </div>
      </div>
    )
  }

  const cardSection = (title, currency, amount, nullValue, desc, className, subDesc) => {
    const checks = title === t("dashboard.pending_payments") || title === t("dashboard.missed_payments")
    return (
      <div className="p-col-12 p-lg-6 p-xl-3 plans-card-title-overlay">
        <div className="card-section">
          <span className="card-title">{title}</span>
          <Button type="text" style={{
            fontSize: "18px", background: "none", border: "none"
          }} icon="pi pi-info-circle"
            className={`p-button-rounded cardSectionsButton ${className}`}
          />
          {checks === false ?
            <Tooltip position="bottom" target={`.${className}`} autoHide={false} className="tooltip">
              <div style={{ margin: "1rem" }}>
                <h5 className="title">{title}</h5>
                <hr className="hr" />
                <span className="desc">{desc}</span>
              </div>
            </Tooltip> :
            <Tooltip position="bottom" target={`.${className}`} autoHide={false} className="tooltip">
              <div style={{ margin: "1rem" }}>
                <h5 className="title">{title}</h5>
                <span className="desc">{desc}</span>
                <hr className="hr" />
                <span className="desc">{subDesc}</span>
              </div>
            </Tooltip>
          }
        </div>
        {!amount ?
          <div style={{ textAlign: "left", marginTop: "20px" }}>
            <span className="card-value-prefix text-style">{currency} </span>
            <span className="card-value text-style">{nullValue}</span>
          </div> :
          <div style={{ textAlign: "left", marginTop: "20px" }}>
            <span className="card-value-prefix text-style">{currency} </span>
            <span className="card-value text-style">{amount}</span>
          </div>}
      </div>
    )
  }

  const numFor = Intl.NumberFormat("en-US")

  const collected_amount = props.businessSummary?.paidSummary?.amount?.toFixed(2)
  const paid = numFor.format(collected_amount);

  const missed_amount = props.businessSummary?.missedSummary?.amount?.toFixed(2)
  const missed = numFor.format(missed_amount);

  const pending_amount = props.businessSummary?.pendingSummary?.amount?.toFixed(2)
  const pending = numFor.format(pending_amount);

  const highestRevenueAmount = props.planGroupPerformance?.highestRevenueTierAmount?.toFixed(2)
  const highest = numFor.format(highestRevenueAmount)
  const highestRevenue = 0

  return (
    <>
      <div>
        <div className="summary-section">
          {props.businessSummary?.activeSubscriptions !== 0 ?
            cardSections(t("dashboard.active_subscriptions"), "", props.businessSummary?.activeSubscriptions || 0,
              "", t("dashboard.active_subscriptions_text"), "active_subscriptions", "",) :
            cardSections(t("dashboard.active_subscriptions"), "", "",
              "0", t("dashboard.active_subscriptions_text"), "active_subscriptions", "")
          }
          {cardSections(t("product.num_active_products"), "", props.planGroupPerformance?.activePlansCount ? props.planGroupPerformance?.activePlansCount : 0,
            t("dashboard.fixed_points"), t("product.active_product_text"), "activePlan", props.planGroupPerformance?.activePlansCount > 1 ? "Products" : "Product")}
          {cardSections(t("product.num_active_plans"), "", props.planGroupPerformance?.activeTierCount ? props.planGroupPerformance?.activeTierCount : 0,
            t("dashboard.fixed_points"), t("product.active_plan_text"), "activeTier", props.planGroupPerformance?.activeTierCount > 1 ? "Plans" : "Plan")}
          {cardSections(t("product.most_subscribers"), "", props.planGroupPerformance?.highestSubscriptionCount ? props.planGroupPerformance?.highestSubscriptionCount : 0,
            t("dashboard.fixed_points"), t("product.most_text"), "mostSubscribers", props.planGroupPerformance?.highestSubscriptionTierName)}
          {cardSection(t("dashboard.collected_payments"), props.defMembership?.business.currency, paid || 0.00,
            t("dashboard.fixed_points"), t("dashboard.collected_text"), "collected")}

          {cardSection(t("dashboard.missed_payments"), props.defMembership?.business.currency, missed || 0.00,
            t("dashboard.fixed_points"), t("dashboard.missed_text"), "missed", t("dashboard.missed_text_desc"))}

          {cardSection(t("dashboard.pending_payments"), props.defMembership?.business.currency, pending || 0.00,
            t("dashboard.fixed_points"), t("dashboard.pending_text"), "pending", t("dashboard.pending_text_desc"))}
          {cardSections(t("product.highest_revenue"), props.defMembership.business.currency, props.planGroupPerformance?.highestRevenueTierAmount ? highest : highestRevenue?.toFixed(2),
            t("dashboard.fixed_points"), t("product.revenue_text"), "highestRevenue", props.planGroupPerformance?.highestRevenueTierName, "", "card-spacing-hrv")}
        </div>
      </div>
    </>
  );
};
export default SummaryCard;
