import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppWrapper from "./Route/AppWrapper";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import Loading from "./Components/Loading";

export default function AppRoot() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router>
          <AppWrapper />
        </Router>
      </PersistGate>
    </Provider>
  );
}