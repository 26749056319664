import React, { useEffect, useState, useCallback, useRef } from "react";
import { Formik, Form, FieldArray, Field } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import "react-phone-number-input/style.css";
import "../../../../Styles/pages/sendMoney.scss"
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WalletActions from "../../../../Redux/transition/wallet/wallet.reducer";
import SettlementActions from "../../../../Redux/transition/settlement/settlement.reducer";
import { defMembershipCall } from "../../calls";
import { Toast } from "primereact/toast";
import { useNavigate, useLocation } from 'react-router-dom';

export const TransferDetails = ({ handleNext, handlePrevious, handleCancel, setSettlement, Recipient }) => {
	const { t } = useTranslation("common");
	const toast = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [totalAmount, setTotalAmount] = useState(false);
	const [errorDisplay, setErrorDisplay] = useState(false);
	const [disableAmtField, setDisableAmtField] = useState(false);

	const params = new URLSearchParams(location.search);
	const walletId = params.get('walletId')
	const payoutChannelId = params.get('payoutChannelId')
	
	//Redux State from Store
	const walletStatistics = useSelector((state) => state.wallet.walletStatistics);
	const structureSettlement = useSelector((state) => state.settlements.structureSettlement);

	//Redux Dispatch Actions
	const dispatch = useDispatch();
	const { defMembership, getMembership } = defMembershipCall(dispatch, useSelector, useCallback);
	const getWalletStatistics = useCallback((businessId, options) => { dispatch(WalletActions.walletStatisticsRequest(businessId, options)); }, [dispatch]);
	const getSettlementStructure = useCallback((countryId) => dispatch(SettlementActions.structureSettlementRequest(countryId)), [dispatch])

	const business = defMembership ? defMembership.business : {};

	const [state, setState] = useState({
		loading: false,
		planGroupsCount: 0,
		sourcesSet: false,
		allPlanGroups: [],
		unmounted: false,
		getTotal: null,
		data: null,
		actions: null,
		totalAmount: null,
		creating: false,
		requesting: false,
		walletStatisticsObject: []
	});

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			getWalletStatistics(defMembership.business.id, {});
			getSettlementStructure(defMembership.business.country.id)
		}
	}, [defMembership, getMembership, getWalletStatistics, getSettlementStructure])

	useEffect(() => {
		if (business.country) {
			setState((state) => {
				return { ...state, country: business.country };
			});
		}
		if (walletStatistics) {
			setState((state) => { return { ...state, walletStatisticsObject: walletStatistics.content } })
		}
	}, [business.country, walletStatistics]);


	const PreSelectPlanGroup = state.walletStatisticsObject.find(({ groupId }) => groupId === parseInt(walletId)) || null

	const transferValid = Yup.object().shape({
		totalTransfers: Yup.array().of(Yup.object().shape({
			planGroup: Yup.object().shape({
				groupId: Yup.string().required("Required"),
			}).required(t("totalTransfers.error_product_accounts_required")),
			amount: Yup.number().required(t("totalTransfers.error_amount_required")),
		})).min(1, t("totalTransfers.error_amount_invalid")),
	})

	const getTotalAmount = (totalTransfers, type = false) => {
		return totalTransfers.reduce((prev, curr, i) => {
			if (i === totalTransfers.length - 1) {
				if (type) {
					return (prev + parseFloat(curr.amount)).toFixed(2)
				} else {
					return `${business?.currency}  ${(prev + parseFloat(curr.amount)).toFixed(2)}`
				}
			}
			return prev + parseFloat(curr.amount)
		}, 0)
	}
	const formik = {
		initialValues: {
			totalTransfers: [],
			currentTransfer: {
				planGroup: walletId ? PreSelectPlanGroup : null,
				amount: null
			}
		},
		validationSchema: transferValid,
		onSubmit: (data, actions) => {
			if (((Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && totalAmount < structureSettlement.bankMinimum) ||
				((Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && totalAmount > structureSettlement.bankLimit) ||
				((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && totalAmount > structureSettlement?.mobileLimit)) {
				setErrorDisplay(true)
			}
			else {
				const totalAmount = getTotalAmount(data.totalTransfers, true);
				setSettlement({
					...state, requesting: true,
					getTotal: getTotalAmount(data.totalTransfers), data: data.totalTransfers,
					actions: actions.resetForm, totalAmount: totalAmount
				});
				handleNext()
			}

		}
	};

	const updatedPlanGroup = (totalTransfer = []) => {
		if (!state.walletStatisticsObject) { return [] }
		let planGroup = [...state.walletStatisticsObject].filter(pg => pg.available > 0.5)
		if (totalTransfer.length !== 0) {
			planGroup = planGroup.filter(pg => {
				return !totalTransfer.find(t => t.planGroup.groupId === pg.groupId)
			})
		}
		return planGroup
	}
	const showError = (detail) => {
		toast.current.show({
			severity: "error",
			summary: t("Error"),
			detail,
			life: 3000,
		});
	};

	const redirectToInitialState = () => payoutChannelId ? navigate(-1) : handleCancel();

	return (
		<>
			<Toast ref={toast} />
			{Recipient?.type === "BANK"|| Recipient?.systemBank?.type === "BANK" ?
				<div className="grid">
					<div className="col-6">
						<h2 className="payout_channel-title">{t("recipient.details_title")}</h2>
					</div>

					<div className="col-6 totalAmountText">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<span className="totalAmount">{t("totalTransfers.totalAmount")}</span>
							<span className="between_text">{t("totalTransfers.between_text")} {structureSettlement?.country.currency}{" "}{structureSettlement?.bankMinimum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
								{" "}-{" "}{structureSettlement?.country.currency}{" "}{structureSettlement?.bankLimit.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
							</span>
						</div>
						<div>
							{(Recipient.type === "BANK"|| Recipient.systemBank.type === "BANK") &&
								<div className="totalAmount_div">
									{errorDisplay && ((Recipient.type === "BANK"|| Recipient.systemBank.type === "BANK") && totalAmount < structureSettlement?.bankMinimum) && (totalAmount) &&
										<div className="bank_minimum_withdrawal_div">
											<div className="icon_exceed_text">
												<i className="pi pi-times-circle i_times_circle" />
												<span className="bank_minimum_withdrawal_text1">{t("totalTransfers.bank_minimum_withdrawal_text1")}</span>
											</div>
											<span className="bank_minimum_withdrawal_text">{t("totalTransfers.bank_minimum_withdrawal_text2")}{" "}{structureSettlement.country?.currency}{" "}
												{structureSettlement?.bankMinimum.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
											</span>
										</div>
									}
								</div>}
						</div>
					</div>
				</div> : <h2 className="payout_channel-title payout_type_mobile">{t("recipient.details_title")}</h2>
			}
			<Formik
				initialValues={formik.initialValues}
				onSubmit={formik.onSubmit}
				validationSchema={formik.validationSchema}>
				{({ values, setFieldValue }) => {
					let isRightAmount;
					if (!walletId) {
						isRightAmount = values.currentTransfer.planGroup?.available - values.currentTransfer.amount;
					} else if (walletId) {
						isRightAmount = PreSelectPlanGroup?.available - values.currentTransfer.amount;
					} else {
						isRightAmount = 0;
					}

					const hasPlanGroup = values.currentTransfer.planGroup || PreSelectPlanGroup;

					const remainingBankBalance = structureSettlement?.bankLimit - totalAmount

					const remainingMobileBalance = structureSettlement?.mobileLimit - totalAmount

					const inputBankErrors = () => {
						if ((((Recipient?.type === "BANK"|| Recipient?.systemBank?.type === "BANK") && (parseInt(values.currentTransfer.amount)) + totalAmount > structureSettlement?.bankLimit)
							&& (parseInt(values.currentTransfer.amount && totalAmount)))) {
							return <div className="limits_main_div">
								<div className="icon_exceed_text">
									<i className="pi pi-times-circle i_times_circle" />
									<span className="exceed_text">{t("totalTransfers.maximum_exceed_text")}</span>
								</div>
								<span className="exceed_text2">{t("totalTransfers.minimum_text")}
									{" "}{structureSettlement.country.currency}{" "}{remainingBankBalance.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
							</div>
						}
						else
							if ((((Recipient?.type === "BANK"|| Recipient?.systemBank?.type === "BANK") && (parseInt(values.currentTransfer.amount)) > structureSettlement?.bankLimit)
								&& (parseInt(values.currentTransfer.amount)))) {
								return <div className="limits_main_div">
									<div className="icon_exceed_text">
										<i className="pi pi-times-circle i_times_circle" />
										<span className="exceed_text">{t("totalTransfers.maximum_exceed_text")}</span>
									</div>
									<span className="exceed_text2"> {t("totalTransfers.minimum_text")}
										{" "}{structureSettlement.country.currency}{" "}{structureSettlement?.bankLimit.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
								</div>
							}
							else if ((((Recipient?.type === "BANK"|| Recipient?.systemBank?.type === "BANK") && (values.currentTransfer.amount) > structureSettlement?.bankLimit)
								&& (values.currentTransfer.amount))) {
								return <div className="limits_main_div">
									<div className="icon_exceed_text">
										<i className="pi pi-times-circle i_times_circle" />
										<span className="exceed_text">{t("totalTransfers.maximum_exceed_text")}</span>
									</div>
									<span className="exceed_text2"> {t("totalTransfers.minimum_text")}
										{" "}{structureSettlement.country.currency}{" "}{structureSettlement?.bankLimit.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
								</div>
							}
					}
					const inputMobileErrors = () => {
						if ((((Recipient?.type === "MOBILE"|| Recipient?.systemBank?.type === "MOBILE") && (parseInt(values.currentTransfer.amount)) + totalAmount > structureSettlement?.mobileLimit)
							&& (parseInt(values.currentTransfer.amount && totalAmount)))) {
							return <div className="limits_main_div">
								<div className="icon_exceed_text">
									<i className="pi pi-times-circle i_times_circle" />
									<span className="exceed_text" >{t("totalTransfers.maximum_exceed_text")}{" "}</span>
								</div>
								<span className="exceed_text2" >{t("totalTransfers.remaining_balance")}{" "}
									{structureSettlement.country.currency}{" "}
									{remainingMobileBalance.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
								</span>
							</div>
						}
						else if ((((Recipient?.type === "MOBILE"|| Recipient?.systemBank?.type === "MOBILE") && (parseInt(values.currentTransfer.amount)) > structureSettlement?.mobileLimit)
							&& (parseInt(values.currentTransfer.amount)))) {
							return <div className="limits_main_div">
								<div className="icon_exceed_text">
									<i className="pi pi-times-circle i_times_circle" />
									<span className="exceed_text" >{t("totalTransfers.maximum_exceed_text")}{" "}</span>
								</div>
								<span className="exceed_text2" > {t("totalTransfers.minimum_text")}{" "}
									{structureSettlement.country.currency}{" "}{structureSettlement?.mobileLimit?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
								</span>
							</div>
						} else if ((((Recipient?.type === "MOBILE"|| Recipient?.systemBank?.type === "MOBILE") && (values.currentTransfer.amount) > structureSettlement?.mobileLimit)
							&& ((values.currentTransfer.amount)))) {
							return <div className="limits_main_div">
								<div className="icon_exceed_text">
									<i className="pi pi-times-circle i_times_circle" />
									<span className="exceed_text" >{t("totalTransfers.maximum_exceed_text")}{" "}</span>
								</div>
								<span className="exceed_text2" > {t("totalTransfers.minimum_text")}{" "}
									{structureSettlement.country.currency}{" "}{structureSettlement?.mobileLimit?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
								</span>
							</div>
						}
					}

					return (
						<Form className="t-form">
							<FieldArray name="totalTransfers">

								{({ remove, push }) => (
									<div className="grid">
										<div className=" totalTransfers total_transfers_main_div" >
											<div className="col-12 sm:col-12 md:col-12 lg:col-6 totalTransfers-inputs"  >
												<div className="totalTransfers-inputs-wallet">
													<div className="totalTransfers-inputs-wallet-chose" >
														{!!walletId? <label  htmlFor="available" className="form-label">{t("totalTransfers.product_account_label_1")}</label> :<label htmlFor="available" className="form-label">
															{t("totalTransfers.product_account_label_2", {value : state.walletStatisticsObject.length})}
														</label>}
														<Dropdown
															value={walletId ? PreSelectPlanGroup : values.currentTransfer.planGroup}
															options={updatedPlanGroup(values.totalTransfers)}
															onChange={(e) => {
																setFieldValue(`currentTransfer.planGroup`, e.value);
															}}
															optionLabel="name"
															disabled={!!walletId}
															placeholder={t("totalTransfers.choose_product_account")}
															className="payout_channel-dropdown"
														/>
													</div>

													<div className="totalTransfers-inputs-wallet-max">
														<label htmlFor="available" className="form-label">
															{t("totalTransfers.max_amount")} ({!hasPlanGroup ? business.currency :
																walletId ? business.currency :
																	business.currency})
														</label>
														<span>
															{!hasPlanGroup ? "0.00" : walletId ?
																PreSelectPlanGroup.available : values.currentTransfer.planGroup.available.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
														</span>
													</div>
												</div>
												<div style={{ display: "flex", flexDirection: "column" }}>
													<div className="totalTransfers-inputs-amount">
														<label htmlFor="amount" className="form-label">
															{t("totalTransfers.enter_amount")}
														</label>
														<div style={{ display: "flex", flexDirection: "row" }}>
															<Field
																name={`amount`}
																placeholder={t("totalTransfers.amount")}
																type="number"
																className='payout_channel-new-input-field'
																min={0}
																value={values.currentTransfer.amount}
																onChange={(e) => {
																	setFieldValue(`currentTransfer.amount`, e.target.value);
																}}
																disabled={((Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && totalAmount >= structureSettlement?.bankLimit) ||
																	((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && totalAmount >= structureSettlement?.mobileLimit) || disableAmtField}
															/>
															<div>
																{inputBankErrors()}
																{inputMobileErrors()}
															</div>
														</div>
														{(hasPlanGroup) && isRightAmount < 0 && <small className='error_balance'>{t("totalTransfers.error_balance")}</small>}
													</div>
												</div>
												<div className="totalTransfers-inputs-button.p-button.p-button-text">
													<div>
														<Button className="p-button-success p-button-text totalTransfers-inputs-button"
															disabled={
																isRightAmount < 0 ||
																(!walletId && values.currentTransfer.planGroup?.available === 0) ||
																(walletId && PreSelectPlanGroup === 0) || values.currentTransfer.amount <= 0 || !hasPlanGroup
																|| values.totalTransfers.length >= 10 || 
																((Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && values.currentTransfer.amount > structureSettlement.bankLimit) ||
																((Recipient?.type === "BANK" || Recipient?.systemBank?.type === "BANK") && (parseInt(values.currentTransfer.amount)) + totalAmount > structureSettlement.bankLimit) ||
																((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && values.currentTransfer.amount > structureSettlement.mobileLimit) ||
																((Recipient?.type === "MOBILE" || Recipient?.systemBank?.type === "MOBILE") && (parseInt(values.currentTransfer.amount)) + totalAmount > structureSettlement.mobileLimit)
															}
															onClick={() => {
																 setDisableAmtField(!!walletId && values.currentTransfer.amount > 0);
																if (values.currentTransfer.amount === 0) {
																	showError(t("totalTransfers.input_amount"));
																}
																else {
																	push({ planGroup: walletId ? PreSelectPlanGroup : values.currentTransfer.planGroup, amount: values.currentTransfer.amount })
																	setFieldValue(`currentTransfer`, {
																		planGroup: walletId ? PreSelectPlanGroup : null,
																		amount: 0
																	});
																}
															}}
															type='button'>{t("totalTransfers.add_amount")}
														</Button>
													</div>

												</div>
											</div>

											<div className='col-12 sm:col-12 md:col-12 lg:col-6 alternate-main'>
												{values.totalTransfers.map(({ planGroup, amount }, index) => {
													const totalAmount = values.totalTransfers.reduce((accumulator, { amount }) => accumulator + parseFloat(amount), 0);
													setTotalAmount(totalAmount)
													return (
														<div className="totalTransfers-totalAmount-card alternate totalAmountCardsMargins" key={index}>
															<span className="totalTransfers-totalAmount-card-i alternate_i">{index + 1}</span>
															<div className="totalTransfers-totalAmount-card-info" >
																<span className="totalTransfers-totalAmount-card-info-name">
																	{planGroup.name}
																</span>
																<span className="totalTransfers-totalAmount-card-info-amount">
																	{`${business?.currency} ${parseFloat(amount).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
																</span>
															</div>
															<Button
																icon="pi pi-times" className="p-button-rounded p-button-outlined p-button-info totalTransfers-totalAmount-card-cancel"
																onClick={() => { remove(index); setErrorDisplay(false); setTotalAmount(false); setDisableAmtField(false) }} type='button'
															/>
														</div>
													)
												})}
											</div>
										</div>
									</div>
								)}

							</FieldArray>
							<div className="payout_channel-buttonsBar">
								<Button
									className="payout_channel-buttonsBar-cancel-button"
									type="reset"
									label={t("cancel")}
									onClick={redirectToInitialState}
								/>
								<Button
									loading={state.creating}
									disabled={
										state.creating || !values.totalTransfers.length
									}
									className=" p-button-info payout_channel-buttonsBar-continue-button"
									type="submit"
									label={t("continue")}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</>
	);
};